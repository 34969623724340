import { AccountWithPoint } from '@/types/account';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FC } from 'react';

export type AccountPointListProps = {
  items: AccountWithPoint[];
  loading: boolean;
};
export const AccountPointList: FC<AccountPointListProps> = ({ items, loading }) => {
  const columns: ColumnProps<AccountWithPoint>[] = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   width: 60,
    // },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
    },
    {
      title: 'Tên tài khoản',
      dataIndex: 'fullName',
    },
    // {
    //   title: 'Trạng thái',
    //   dataIndex: 'status',
    //   sorter: { multiple: 2 },
    //   render: (status: AccountStatus) => (
    //     <Tag
    //       color={
    //         status === AccountStatus.active ? 'success' : status === AccountStatus.unverified ? 'orange' : undefined
    //       }>
    //       {accountStatusNameMapping[status]}
    //     </Tag>
    //   ),
    // },
    {
      title: 'Điểm',
      dataIndex: 'point',
    },
  ];

  return <Table rowKey={(item) => item.id} loading={loading} columns={columns} dataSource={items} />;
};
