import { FILE_UPLOAD_TYPE, validateFileSize } from '@/utils/helpers/validate';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Upload as AntUpload,
  UploadFile as AntUploadFile,
  UploadProps as AntUploadProps,
  Button,
  UploadFile,
} from 'antd';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import { JSXElementConstructor, ReactElement, Ref, forwardRef, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { uploadPropsToState, uploadValidateSize } from './Upload.helpers';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';

export type BaseUploadProps<T> = {
  defaultValue?: T;
  value?: T;
  onChange?: (value: T) => void;
  maxSize?: number; // kB
  disabled?: boolean;
} & Pick<AntUploadProps, 'multiple' | 'accept' | 'itemRender' | 'listType'>;

export type UploadProps = BaseUploadProps<AntUploadFile | string | Array<AntUploadFile | string>>;
const UploadWithoutRef = (props: UploadProps, ref: Ref<HTMLInputElement>) => {
  const {
    disabled,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    maxSize = 100,

    multiple = false,
    accept = FILE_UPLOAD_TYPE.join(','),
    listType = 'picture-card',
  } = props;
  const [fileList, setFileList] = useState<AntUploadFile[]>(() => uploadPropsToState(defaultValue || valueProps || []));

  const hasValue = useMemo(() => Boolean(fileList?.length), [fileList]);

  const onChange = async (fileList: AntUploadFile<any>[]) => {
    const fileListValidated = uploadValidateSize(fileList, maxSize);
    setFileList(fileListValidated);
    onChangeProps?.(multiple ? fileListValidated : fileListValidated[0]);
  };

  useEffect(() => {
    setFileList(uploadPropsToState(valueProps || []));
  }, [valueProps]);

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = fileList.findIndex((i) => i.uid === active.id);
      const overIndex = fileList.findIndex((i) => i.uid === over?.id);
      onChange(arrayMove(fileList, activeIndex, overIndex));
    }
  };

  return (
    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
      <SortableContext items={fileList.map((i) => i.uid)}>
        <AntUpload
          ref={ref}
          disabled={disabled}
          listType={listType}
          accept={accept}
          multiple={multiple}
          beforeUpload={() => false}
          className={clsx({ multiple, hasValue }, styles.upload)}
          fileList={fileList}
          defaultFileList={uploadPropsToState(defaultValue || [])}
          onChange={(info) => onChange(info.fileList)}
          itemRender={(originNode, file) => <DraggableUploadListItem originNode={originNode} file={file} />}>
          {listType === 'text' && (
            <Button disabled={disabled} icon={<UploadOutlined />}>
              Upload
            </Button>
          )}
          {listType !== 'text' && <PlusOutlined />}
        </AntUpload>
      </SortableContext>
    </DndContext>
  );
};
export const Upload = forwardRef(UploadWithoutRef);

type DraggableUploadListItemProps = {
  originNode: ReactElement<any, string | JSXElementConstructor<any>>;
  file: AntUploadFile<any>;
};

const DraggableUploadListItem = ({ originNode, file }: DraggableUploadListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? 'is-dragging' : ''}
      {...attributes}
      {...listeners}>
      {/* hide error tooltip when dragging */}
      {originNode}
      {/* {file.status === 'error' && isDragging ? originNode.props.children : originNode} */}
    </div>
  );
};

export const validateSingleImage =
  (options: { maxSize?: number; required?: boolean }) => (_: any, value: UploadFile<any>) => {
    if (options.required && !value) {
      return Promise.reject('Hình ảnh cần được thêm vào!');
    }

    const errors: string[] = [];

    if (options.maxSize) {
      const validFileSize = value?.originFileObj && !validateFileSize(value.originFileObj, options.maxSize);
      if (validFileSize) {
        errors[0] = `Hình ảnh bị giới hạn dung lượng ${options.maxSize}kB!`;
      }
    }

    if (!errors.length) {
      return Promise.resolve();
    }

    return Promise.reject(errors.join(','));
  };

export const validateMultiImages =
  (options: { maxSize?: number; required?: boolean }) => (_: any, values: UploadFile<any>[]) => {
    if (options.required && !values.length) {
      return Promise.reject('Hình ảnh cần được thêm vào!');
    }

    const errors: string[] = [];

    if (options.maxSize) {
      values.forEach((value) => {
        const validFileSize = value?.originFileObj && !validateFileSize(value.originFileObj, options.maxSize);
        if (validFileSize) {
          errors[0] = `Hình ảnh bị giới hạn dung lượng ${options.maxSize}kB!`;
        }
      });
    }

    if (!errors.length) {
      return Promise.resolve();
    }

    return Promise.reject(errors.join(','));
  };
