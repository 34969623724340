import { serializeSorter } from '@/utils/helpers/serializeSorter';
import { blue } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Table, Tag, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useEffect } from 'react';
import format from 'date-fns/format';
import { useProductStore } from '@/stores/product';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/config/path';
import { ProductItem } from '@/types/product';
import { numberToVNDPrice } from '@/utils/helpers/pricing';
import { productPromotionTypeNameMapping } from '@/types/productPromotion';

export const ProductList = () => {
  const { loading, query, total, items, fetchProductList, deleteProduct } = useProductStore();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProductList().catch(() => message.error('Lỗi không thể tải danh sách thuốc', 3));
  }, []);

  const onDeleteProduct = async (id: number) => {
    Modal.confirm({
      title: 'Xóa thuốc',
      content: 'Bạn có muốn xóa thuốc này không?',
      onOk: async () => {
        try {
          await deleteProduct(id);
          message.success('Xóa thuốc thành công');
        } catch (error: any) {
          message.error('Lỗi không thể xóa thuốc này!');
        }
      },
    });
  };

  const columns: ColumnProps<ProductItem>[] = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   width: 60,
    //   sorter: {
    //     multiple: 1,
    //   },
    // },
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: 80,
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      sorter: {
        multiple: 2,
      },
      render: (_, record) => (
        <>
          <div>{record.name}</div>
          {!record.hidden && (
            <a href={`${process.env.REACT_APP_SITE_DOMAIN}/san-pham/${record.slug}`} target="_blank" rel="noreferrer">
              {record.slug}
            </a>
          )}
          {!!record.hidden && (
            <Space style={{ color: '#999' }}>
              <EyeInvisibleOutlined />
              Đang ẩn
            </Space>
          )}
        </>
      ),
    },
    {
      title: 'Giá thuốc',
      dataIndex: 'price',
      width: 150,
      sorter: {
        multiple: 2,
      },
      render: (_, record) => {
        return (
          <>
            <strong>{numberToVNDPrice(record.discountPrice || record.price)}</strong>
            {!!record.discountPrice && (
              <>
                <br />
                <span>
                  <s>{numberToVNDPrice(record.price)}</s>
                </span>
              </>
            )}
            {!!record.productPromotion?.type && (
              <Tag color="orange">{productPromotionTypeNameMapping[record.productPromotion.type]}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Nhóm thuốc',
      dataIndex: 'productCategories',
      width: 160,
      render: (_, record) => record.productCategories[0]?.name,
    },
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      width: 150,
      sorter: {
        multiple: 4,
      },
      render: (createdAt) => (createdAt ? format(new Date(createdAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      width: 150,
      sorter: {
        multiple: 5,
      },
      render: (updatedAt) => (updatedAt ? format(new Date(updatedAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              shape="circle"
              title="Chỉnh sửa thuốc"
              onClick={() => navigate(`${paths.PRODUCT}/${record.id}`)}>
              <EditOutlined twoToneColor={blue.primary} />
            </Button>
            <Button danger type="link" shape="circle" title="Xóa thuốc" onClick={() => onDeleteProduct(record.id)}>
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  const onChange = useCallback((pagination: any, _: any, sorter: any) => {
    fetchProductList({
      page: pagination.current || query.page,
      limit: pagination.pageSize || query.limit,
      sort: serializeSorter(sorter),
    });
  }, []);

  return (
    <Table
      rowKey={(item) => item.id}
      loading={loading}
      columns={columns}
      dataSource={items}
      onChange={onChange}
      pagination={{
        current: query.page,
        pageSize: query.limit,
        total: total,
      }}
    />
  );
};
