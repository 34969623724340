import { useFormDataStore } from '@/stores/formData';
import { Button, Form, Spin, message } from 'antd';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as formDataApi from '@/api/formData';
import { LayoutContent } from '@/components/Layout';

export type FormDataProps = {
  children: ReactNode;
};
export const FormData: FC<FormDataProps> = ({ children }) => {
  const { id: _id } = useParams();
  const id = parseInt(_id);
  const [loading, setLoading] = useState(true);
  const { submitting, updateFormData } = useFormDataStore();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    formDataApi
      .fetchFormData(id)
      .then((data) => {
        const values = data.formDataItems.reduce((prev, item) => ({ ...prev, [item.key]: item.value }), {});
        form.setFieldsValue(values);
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const onFinish = async (values: Record<string, string>) => {
    try {
      message.loading('Đang cập nhật...');
      await updateFormData(id, { formDataItems: Object.keys(values).map((key) => ({ key, value: values[key] })) });
      message.destroy();
      message.success('Cập nhật thành công');
    } catch (error: any) {
      console.error(error);
      message.destroy();
      message.error('Cập nhật không thành công');
    }
  };

  if (!id) return null;

  return (
    <Spin spinning={loading}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <LayoutContent
          actions={
            <Button type="primary" disabled={submitting} loading={submitting} htmlType="submit">
              Cập nhật
            </Button>
          }>
          {children}
        </LayoutContent>
      </Form>
    </Spin>
  );
};
