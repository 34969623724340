import { fetchDistricts, fetchProvinces, fetchWards } from '@/api/location';
import { District, Province, Ward } from '@/types/location';
import { create } from 'zustand';

export type LocationState = {
  provinces: Province[];
  districts: Record<string, District[]>;
  wards: Record<string, Ward[]>;

  provinceLoading: boolean;
  districtLoading: Record<string, boolean>;
  wardLoading: Record<string, boolean>;

  loadProvinces: () => Promise<void>;
  loadDistricts: (provinceCode: string) => Promise<void>;
  loadWards: (districtCode: string) => Promise<void>;
};

export const useLocationStore = create<LocationState>((set, get) => ({
  provinces: [],
  districts: {},
  wards: {},

  provinceLoading: false,
  districtLoading: {},
  wardLoading: {},

  loadProvinces: async () => {
    if (get().provinces.length) return;
    try {
      set({ provinceLoading: true });
      const provinces = await fetchProvinces();
      set({ provinceLoading: false, provinces });
    } catch (error) {
      set({ provinceLoading: false });
    }
  },
  loadDistricts: async (provinceCode) => {
    if (get().districts[provinceCode]?.length) return;
    try {
      set(({ districtLoading }) => ({
        districtLoading: { ...districtLoading, [provinceCode]: true },
      }));
      const provinceDistricts = await fetchDistricts(provinceCode);
      set(({ districtLoading, districts }) => ({
        districtLoading: { ...districtLoading, [provinceCode]: false },
        districts: { ...districts, [provinceCode]: provinceDistricts },
      }));
    } catch (error) {
      set(({ districtLoading }) => ({
        districtLoading: { ...districtLoading, [provinceCode]: false },
      }));
    }
  },
  loadWards: async (districtCode) => {
    if (get().wards[districtCode]?.length) return;
    try {
      set(({ wardLoading }) => ({
        wardLoading: { ...wardLoading, [districtCode]: true },
      }));
      const districtWards = await fetchWards(districtCode);
      set(({ wardLoading, wards }) => ({
        wardLoading: { ...wardLoading, [districtCode]: false },
        wards: { ...wards, [districtCode]: districtWards },
      }));
    } catch (error) {
      set(({ wardLoading }) => ({
        wardLoading: { ...wardLoading, [districtCode]: false },
      }));
    }
  },
}));
