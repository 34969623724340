import dayjs from 'dayjs';
import { Coupon } from './coupon';

export enum BountyProgramStatus {
  upcoming = 'upcoming',
  processing = 'processing',
  finished = 'finished',
}

export type BountyProgramItem = {
  id: number;
  title: string;
  startDate: Date;
  endDate: Date;
  minPrice: number;
  couponId: number;
  coupon: Coupon;
  createdAt: Date;
  updatedAt: Date;
  status: BountyProgramStatus;
};

export type BountyProgram = BountyProgramItem;

export type BountyProgramCreationForm = {
  title: string;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  minPrice: number;
  couponId: number;
};

export type BountyProgramQuery = {
  search?: string;
  status?: BountyProgramStatus;
  couponId?: number;
  sort?: string;
  page?: number;
  limit?: number;
};

export type BountyProgramResponse = {
  records: BountyProgramItem[];
  metadata: {
    total: number;
  };
};

export type CreateBountyProgramRequest = {
  title: string;
  startDate: string;
  endDate: string;
  minPrice: number;
  couponId: number;
};
export type UpdateBountyProgramRequest = {
  title?: string;
  startDate?: string;
  endDate?: string;
  minPrice?: number;
  couponId?: number;
};
