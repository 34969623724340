import { FC, useEffect } from 'react';
import { useLocationStore } from '@/stores/location';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { Select, SelectProps } from '../Select';

export type WardSelectProps = Omit<SelectProps, 'options'> & {
  districtCode?: string;
};
export const WardSelect: FC<WardSelectProps> = ({ value, onChange, districtCode, ...props }) => {
  const { wardLoading, wards, loadWards } = useLocationStore();
  useEffect(() => {
    if (districtCode) loadWards(districtCode);
  }, [loadWards, districtCode]);
  const items = districtCode ? wards[districtCode] || [] : [];
  return (
    <Select
      showSearch
      filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
      options={items.map((item) => ({ value: item.code, label: item.name }))}
      {...props}
      loading={(!!districtCode && wardLoading[districtCode]) || props.loading}
      value={items.length ? (value as any) : undefined}
      onChange={onChange as any}
    />
  );
};
