import { uploadFile } from '@/api/file';
import { LayoutContent } from '@/components/Layout';
import { ProductBrandSelect } from '@/components/ProductBrandSelect';
import { ProductCategorySelect } from '@/components/ProductCategorySelect';
import { ProductCompositionSelect } from '@/components/ProductCompositionSelect';
import { ProductIndicationSelect } from '@/components/ProductIndicationSelect';
import { ProductManufacturerSelect } from '@/components/ProductManufacturerSelect';
import { ProductUsingSelect } from '@/components/ProductUsingSelect';
import { SwitchWithLabel } from '@/components/SwitchWithLabel';
import { Upload, validateMultiImages } from '@/components/Upload';
import { paths } from '@/config/path';
import { useProductStore } from '@/stores/product';
import { CreateProductRequest, ProductCreationForm, UpdateProductRequest } from '@/types/product';
import { handleNameToSlug } from '@/utils/helpers/nameToSlug';
import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Space, Spin, Tabs, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as productApi from '@/api/product';
import { InputPrice } from '@/components/InputPrice/InputPrice';
import { TextEditor } from '@/components/TextEditor';

const descriptionTabs = [
  { key: 'productCompositionDescription', label: 'Thành phần thuốc' },
  { key: 'productIndicationDescription', label: 'Chỉ Định' },
  { key: 'productDosageDescription', label: 'Liều Lượng - Cách Dùng' },
  { key: 'productContraindicationsDescription', label: 'Chống Chỉ Định' },
  { key: 'productInteractionsDescription', label: 'Tương Tác Thuốc' },
  { key: 'productSpecificationDescription', label: 'Bảo Quản' },
  { key: 'productOverboldDescription', label: 'Quá Liều' },
];

export type ProductCreationPageProps = {};
export const ProductCreationPage: FC<ProductCreationPageProps> = () => {
  const { id: _id } = useParams();
  const id = parseInt(_id);
  const [loading, setLoading] = useState(true);
  const { submitting, updateProduct, createProduct } = useProductStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<ProductCreationForm>();

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    productApi
      .fetchProduct(id)
      .then((data) => {
        form.setFieldsValue({
          slug: data.slug,
          name: data.name,
          imageUrls: data.imageUrls,
          price: data.price,
          productUnit: data.productUnit,
          weight: data.weight,
          description: data.description,
          discountPrice: data.discountPrice,
          featured: data.featured,
          popular: data.popular,
          product24h: data.product24h,
          hidden: data.hidden,
          productUsingIds: data.productUsings.map(({ id }) => id),
          productIndicationIds: data.productIndications.map(({ id }) => id),
          productBrandId: data.productBrandId,
          productManufacturerId: data.productManufacturerId,
          manufacturingCountry: data.manufacturingCountry,
          productCategoryId: data.productCategories[0]?.id,
          productCompositionIds: data.productCompositions.map(({ id }) => id),
          dosageForm: data.dosageForm,
          specification: data.specification,
          productCompositionDescription: data.productCompositionDescription,
          productIndicationDescription: data.productIndicationDescription,
          productDosageDescription: data.productDosageDescription,
          productContraindicationsDescription: data.productContraindicationsDescription,
          productInteractionsDescription: data.productInteractionsDescription,
          productSpecificationDescription: data.productSpecificationDescription,
          productOverboldDescription: data.productOverboldDescription,
          productPromotion: data.productPromotion,
        });
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const onFinish = async (values: ProductCreationForm) => {
    try {
      message.loading(id ? 'Đang cập nhật...' : 'Đang thêm mới...');
      let imageUrls: string[] = [];
      if (values.imageUrls?.length) {
        imageUrls = await Promise.all(
          values.imageUrls.map(async (imageUrl) => {
            if (typeof imageUrl === 'string') {
              return imageUrl;
            }

            if (!imageUrl?.originFileObj) {
              return imageUrl?.thumbUrl || '';
            }

            const imageRes = await uploadFile(imageUrl.originFileObj);
            return imageRes.url;
          })
        );
        form.setFieldValue('imageUrls', imageUrls);
      }

      const newValues: UpdateProductRequest | CreateProductRequest = {
        ...values,
        imageUrls,
        productCategoryIds: [values.productCategoryId],
        discountPrice: values.discountPrice || null,
      };
      if (id) {
        await updateProduct(id, newValues);
      } else {
        await createProduct(newValues);
      }
      message.destroy();
      message.success(id ? 'Cập nhật thuốc thành công' : 'Tạo mới thuốc thành công');
      navigate(paths.PRODUCT, { replace: true });
    } catch (error: any) {
      if (error?.response?.data?.name === 'slug_is_exist') {
        form.setFields([
          {
            name: 'slug',
            errors: ['Đường dẫn đã tồn tại!'],
          },
        ]);
      } else {
        message.error(id ? 'Cập nhật thuốc không thành công' : 'Tạo mới thuốc không thành công');
      }
      message.destroy();
    }
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} scrollToFirstError layout="vertical" onFinish={onFinish}>
        <LayoutContent
          actions={
            <Space>
              <Button
                disabled={submitting}
                onClick={() =>
                  Modal.confirm({
                    title: 'Hủy thay đổi',
                    content: 'Bạn có chắc muốn hủy thay đổi không?',
                    onOk: () => navigate(paths.PRODUCT),
                  })
                }>
                Hủy
              </Button>
              <Button type="primary" disabled={submitting} loading={submitting} htmlType="submit">
                {id ? 'Cập nhật' : 'Thêm mới'}
              </Button>
            </Space>
          }
          sections={[
            {
              key: 'general',
              title: 'Thông tin chung',
              content: (
                <>
                  <Row gutter={32}>
                    <Col span={14}>
                      <Form.Item
                        label="Tên sản phẩm"
                        name="name"
                        rules={[{ required: true, message: 'Tên Sản phẩm bắt buộc' }]}>
                        <Input placeholder="Tên Sản phẩm" onChange={id ? undefined : handleNameToSlug(form, 'slug')} />
                      </Form.Item>
                      <Form.Item
                        label="Liên kết"
                        name="slug"
                        rules={[
                          { required: true, message: 'Liên kết bắt buộc' },
                          { pattern: /^[-a-zA-Z0-9@.+_]+$/g, message: 'Liên kết bắt buộc KHÔNG có khoảng trắng!' },
                        ]}>
                        <Input placeholder="Liên kết" />
                      </Form.Item>
                      <Row gutter={16}>
                        <Col span={14}>
                          <Form.Item
                            label="Giá thuốc"
                            name="price"
                            rules={[{ required: true, message: 'Giá thuốc bắt buộc' }]}>
                            <InputPrice placeholder="Giá thuốc" style={{ width: '100%' }} addonAfter="VNĐ" />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            label="Đơn vị"
                            name="productUnit"
                            rules={[{ required: true, message: 'Đơn vị bắt buộc' }]}>
                            <Input placeholder="Đơn vị" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        label="Giá khuyến mãi"
                        name="discountPrice"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value: number) {
                              const price = getFieldValue('price');
                              if (!price || !value) return Promise.resolve();
                              if (price <= value) {
                                return Promise.reject('Giá khuyến mãi phải nhỏ hơn giá gốc');
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}>
                        <InputPrice placeholder="Giá khuyễn mãi" addonAfter="VNĐ" />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        label="Hình ảnh"
                        name="imageUrls"
                        rules={[{ validator: validateMultiImages({ maxSize: 512, required: true }) }]}>
                        <Upload maxSize={512} multiple={true} listType="picture-card" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Mô tả ngắn"
                    name="description"
                    rules={[{ required: true, message: 'Mô tả ngắn là bắt buộc' }]}>
                    <Input.TextArea placeholder="Mô tả ngắn" rows={3} />
                  </Form.Item>
                  <Row gutter={16}>
                    <Col></Col>
                  </Row>
                </>
              ),
            },
            {
              key: 'parameter',
              title: 'Thông số',
              content: (
                <>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Khối lượng"
                        name="weight"
                        rules={[{ required: true, message: 'Khối lượng là bắt buộc' }]}>
                        <InputNumber placeholder="Khối lượng" style={{ width: '100%' }} addonAfter="Gam" />
                      </Form.Item>
                      <Form.Item label="Dạng bào chế" name="dosageForm">
                        <Input placeholder="Dạng bào chế" />
                      </Form.Item>
                      <Form.Item label="Quy cách" name="specification">
                        <Input placeholder="Quy cách" />
                      </Form.Item>
                      <Form.Item label="Nhà sản xuất" name="productManufacturerId">
                        <ProductManufacturerSelect placeholder="Chọn nhà sản xuất" />
                      </Form.Item>
                      <Form.Item label="Nước sản xuất" name="manufacturingCountry">
                        <Input placeholder="Nước sản xuất" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Nhóm thuốc"
                        name="productCategoryId"
                        rules={[{ required: true, message: 'Nhóm thuốc là bắt buộc' }]}>
                        <ProductCategorySelect placeholder="Chọn nhóm thuốc" />
                      </Form.Item>
                      <Form.Item label="Thành phần" name="productCompositionIds">
                        <ProductCompositionSelect placeholder="Chọn thành phần" mode="multiple" />
                      </Form.Item>
                      <Form.Item label="Thương hiệu" name="productBrandId">
                        <ProductBrandSelect placeholder="Chọn thương hiệu" />
                      </Form.Item>
                      <Form.Item label="Đối tượng sử dụng" name="productUsingIds">
                        <ProductUsingSelect placeholder="Chọn đối tượng sử dụng" mode="multiple" />
                      </Form.Item>
                      <Form.Item label="Chỉ định thuốc" name="productIndicationIds">
                        <ProductIndicationSelect placeholder="Chọn chỉ định thuốc" mode="multiple" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Hiển thị">
                        <Form.Item name="hidden" valuePropName="checked" style={{ marginBottom: 4 }}>
                          <SwitchWithLabel
                            label="Ẩn sản phẩm"
                            onChange={(hidden) => {
                              if (!hidden) return;
                              form.setFieldsValue({ featured: false, popular: false, product24h: false });
                            }}
                          />
                        </Form.Item>
                        <Form.Item noStyle dependencies={['hidden']}>
                          {(form) => {
                            const hidden = form.getFieldValue('hidden');
                            return (
                              <>
                                <Form.Item name="featured" valuePropName="checked" style={{ marginBottom: 4 }}>
                                  <SwitchWithLabel label="Hàng nổi bật" disabled={hidden} />
                                </Form.Item>
                                <Form.Item name="popular" valuePropName="checked" style={{ marginBottom: 4 }}>
                                  <SwitchWithLabel label="Hàng điểm" disabled={hidden} />
                                </Form.Item>
                                <Form.Item name="product24h" valuePropName="checked" style={{ marginBottom: 0 }}>
                                  <SwitchWithLabel label="Giao trong 24h" disabled={hidden} />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ),
            },
            // {
            //   key: 'promotion',
            //   title: 'Khuyến mãi',
            //   content: (
            //     <Row gutter={16}>
            //       <Col span={8}>
            //         <Form.Item name={['productPromotion', 'type']} label="Loại khuyến mãi">
            //           <Select
            //             options={Object.values(ProductPromotionType).map((productPromotionType) => ({
            //               value: productPromotionType,
            //               label: productPromotionTypeNameMapping[productPromotionType],
            //             }))}
            //             allowClear
            //             placeholder="Chọn loại khuyến mãi"
            //           />
            //         </Form.Item>
            //       </Col>
            //       <Col span={16}>
            //         <Form.Item dependencies={[['productPromotion', 'type']]} noStyle>
            //           {(form) => {
            //             const type = form.getFieldValue(['productPromotion', 'type']);
            //             if (type === ProductPromotionType.buyOneGetOne) {
            //               return (
            //                 <Row gutter={16}>
            //                   <Col span={12}>
            //                     <Form.Item
            //                       name={['productPromotion', 'buyOneGetOne', 'productId']}
            //                       label="Sản phẩm khuyến mãi"
            //                       rules={[{ required: true, message: 'Sản phẩm khuyến mãi là bắt buộc' }]}>
            //                       <ProductSelect />
            //                     </Form.Item>
            //                   </Col>
            //                 </Row>
            //               );
            //             }
            //             if (type === ProductPromotionType.buyTwoGetOne) {
            //               return (
            //                 <Row gutter={16}>
            //                   <Col span={12}>
            //                     <Form.Item
            //                       name={['productPromotion', 'buyTwoGetOne', 'productId']}
            //                       label="Sản phẩm khuyến mãi"
            //                       rules={[{ required: true, message: 'Sản phẩm khuyến mãi là bắt buộc' }]}>
            //                       <ProductSelect />
            //                     </Form.Item>
            //                   </Col>
            //                 </Row>
            //               );
            //             }
            //             if (type === ProductPromotionType.buyOneDiscountOne) {
            //               return (
            //                 <Row gutter={16}>
            //                   <Col span={12}>
            //                     <Form.Item
            //                       name={['productPromotion', 'buyOneDiscountOne', 'productId']}
            //                       label="Sản phẩm khuyến mãi"
            //                       rules={[{ required: true, message: 'Sản phẩm khuyến mãi là bắt buộc' }]}>
            //                       <ProductSelect />
            //                     </Form.Item>
            //                   </Col>
            //                   <Col span={12}>
            //                     <Form.Item
            //                       name={['productPromotion', 'buyOneDiscountOne', 'discountPrice']}
            //                       label="Giá khuyến mãi"
            //                       rules={[{ required: true, message: 'Giá khuyến mãi là bắt buộc' }]}>
            //                       <InputPrice placeholder="Nhập giá" addonAfter="VNĐ" />
            //                     </Form.Item>
            //                   </Col>
            //                 </Row>
            //               );
            //             }
            //           }}
            //         </Form.Item>
            //       </Col>
            //     </Row>
            //   ),
            // },
            {
              key: 'description',
              content: (
                <Tabs
                  items={descriptionTabs.map((tab) => ({
                    key: tab.key,
                    label: tab.label,
                    children: (
                      <Form.Item name={tab.key}>
                        <TextEditor />
                      </Form.Item>
                    ),
                  }))}
                />
              ),
            },
          ]}
        />
      </Form>
    </Spin>
  );
};
