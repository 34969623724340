import { useCouponStore } from '@/stores/coupon';
import { BountyProgramStatus } from '@/types/bountyProgram';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { FC } from 'react';

export const Filter: FC = () => {
  const { query, fetchCouponList } = useCouponStore();
  const [form] = Form.useForm();

  const onFinish = (value: any) => {
    fetchCouponList({ ...value, page: 1 });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Row gutter={[16, 0]}>
        <Col span={5}>
          <Form.Item name="status" initialValue={query.status}>
            <Select
              allowClear
              options={[
                { label: 'Sắp diễn ra', value: BountyProgramStatus.upcoming },
                { label: 'Đang diễn ra', value: BountyProgramStatus.processing },
                { label: 'Đã kết thúc', value: BountyProgramStatus.finished },
              ]}
              placeholder="Trạng thái"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="search" initialValue={query.search}>
            <Input placeholder="Tìm kiếm mã giảm..." />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Button htmlType="submit">Tìm kiếm</Button>
        </Col>
      </Row>
    </Form>
  );
};
