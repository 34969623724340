import { ProductCategorySelect } from '@/components/ProductCategorySelect';
import { useProductCategoryStore } from '@/stores/productCategory';
import { Button, Col, Form, Input, Row } from 'antd';
import { FC } from 'react';

export const Filter: FC = () => {
  const { query, fetchProductCategoryList } = useProductCategoryStore();
  const [form] = Form.useForm();

  const onFinish = (value: any) => {
    fetchProductCategoryList({ search: value.search, parentId: value.parentId, page: 1 });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Row gutter={[16, 0]}>
        <Col span={6}>
          <Form.Item name="parentId" initialValue={query.parentId}>
            <ProductCategorySelect placeholder="Nhóm thuốc cha" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="search" initialValue={query.search}>
            <Input placeholder="Tìm kiếm nhóm thuốc..." />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Button htmlType="submit">Tìm kiếm</Button>
        </Col>
      </Row>
    </Form>
  );
};
