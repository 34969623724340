import { District, Province, Ward } from '@/types/location';
import { baseApi } from './common/baseApi';

const ROOT_PATH = '/location';

export const fetchProvinces = async () => {
  const { data } = await baseApi.get<Province[]>(`${ROOT_PATH}/province`);
  return data;
};

export const fetchDistricts = async (provinceCode: string) => {
  const { data } = await baseApi.get<District[]>(`${ROOT_PATH}/district/${provinceCode}`);
  return data;
};

export const fetchWards = async (districtCode: string) => {
  const { data } = await baseApi.get<Ward[]>(`${ROOT_PATH}/ward/${districtCode}`);
  return data;
};
