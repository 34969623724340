import { uploadFile } from '@/api/file';
import { FILE_UPLOAD_TYPE, validateFileSize } from '@/utils/helpers/validate';
import { Spin, message } from 'antd';
import clsx from 'clsx';
import ImageResize from 'quill-image-resize-module-react';
import {
  ChangeEventHandler,
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactQuill, { Quill as BaseQuill, ReactQuillProps } from 'react-quill';
import styles from './styles.module.scss';

BaseQuill.register('modules/ImageResize', ImageResize);
export type QuillProps = ReactQuillProps;
const QuillWithoutRef = (props: ReactQuillProps, ref: Ref<ReactQuill>) => {
  const { className, modules: modulesProps, ...restProps } = props;
  const quillObj = useRef<ReactQuill>(null);
  const [loading, setLoading] = useState(false);

  const onChangeImage: ChangeEventHandler<HTMLInputElement> = useCallback(() => {
    const $inputFile = document.createElement('input');
    $inputFile.type = 'file';
    $inputFile.accept = FILE_UPLOAD_TYPE.join(',');

    $inputFile.addEventListener('change', async (e: any) => {
      try {
        setLoading(true);
        const file = e.target?.files?.item(0);
        if (!file) {
          message.error('Mời bạn chọn hình ảnh');
          return;
        }

        if (!validateFileSize(file, 500)) {
          message.error('Hình ảnh bị giới hạn dung lượng 500kB!');
          return;
        }

        const fileUploaded = await uploadFile(file);
        if (fileUploaded.url) {
          const range = quillObj.current?.getEditorSelection();
          if (typeof range?.index !== 'undefined') {
            quillObj.current?.getEditor().insertEmbed(range.index, 'image', fileUploaded.url);
          }
        }
      } catch (error) {
        console.log('** Error Quill : ', error);
      } finally {
        setLoading(false);
      }
    });

    $inputFile.click();
  }, [quillObj.current]);

  const modules = useMemo(
    () =>
      modulesProps || {
        toolbar: {
          container: [
            [{ header: ['1', '2', '3', '4', '5', '6', false] }],
            ['bold', 'italic', 'underline'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ list: 'bullet' }, { list: 'ordered' }],
            ['blockquote', 'code-block'],
            ['link', 'image'],
            ['clean'],
          ],
          handlers: {
            image: onChangeImage,
          },
        },
        ImageResize: {
          parchment: BaseQuill.import('parchment'),
          modules: ['Resize', 'DisplaySize', 'Toolbar'],
        },
      },
    []
  );

  useImperativeHandle(ref, () => quillObj.current as ReactQuill, [quillObj?.current]);

  return (
    <Spin spinning={loading}>
      <ReactQuill {...restProps} ref={quillObj} modules={modules} className={clsx(styles.quill, className)} />
    </Spin>
  );
};

export const Quill = forwardRef(QuillWithoutRef);
