import clsx from 'clsx';
import { isValidElement, ReactNode, FC, useState } from 'react';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import classNames from 'classnames';

type SectionConfig = { title?: ReactNode; key: string; content: ReactNode; toggle?: boolean };
type HeaderProps = {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
};
type ContentLayoutProps = {
  header?: ReactNode | HeaderProps;
  children?: ReactNode;
  sections?: SectionConfig[];
  actions?: ReactNode;
  footer?: ReactNode;
  transparent?: boolean;
  className?: string;
};

export const LayoutContent: FC<ContentLayoutProps> = ({
  className,
  header,
  children,
  actions,
  sections,
  footer,
  transparent,
}) => {
  return (
    <main
      className={clsx(styles.contentLayout, className, {
        [styles.contentLayoutTransparent]: transparent,
      })}>
      {!!header && isValidElement(header) ? header : null}
      {!!header && !isValidElement(header) && (
        <section className={clsx(styles.contentHeader, styles.contentHeaderWrapper)}>
          <div className={clsx(styles.leftHeader, 'leftHeader')}>{(header as any).leftElement}</div>
          <div className={clsx(styles.rightHeader, 'rightHeader')}>{(header as any).rightElement}</div>
        </section>
      )}
      {!!sections && sections.map((section) => <Section section={section} key={section.key} />)}
      {!!children && <section className={styles.body}>{children}</section>}
      <section className={styles.actions}>{actions}</section>
      <footer className={styles.footer}>{footer}</footer>
    </main>
  );
};

export const Section: FC<{ section: SectionConfig }> = ({ section }) => {
  const { toggle = true } = section;
  const [_open, setOpen] = useState(true);
  const open = !toggle || _open;
  const hasHeader = !!section.title;
  return (
    <section className={classNames(styles.body, { [styles.hasHeader]: hasHeader })}>
      {hasHeader && (
        <div className={styles.bodyHeader} onClick={() => setOpen((open) => !open)}>
          <h2 className={styles.title}>{section.title}</h2>
          {open ? <DownOutlined /> : <UpOutlined />}
        </div>
      )}
      <div className={classNames(styles.bodyContent, { [styles.open]: open })}>
        {hasHeader && <div className={styles.divider}></div>}
        {section.content}
      </div>
    </section>
  );
};
