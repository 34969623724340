import { paths } from '@/config/path';
import { useCouponSelectionStore } from '@/stores/coupon';
import { CouponGroup } from '@/types/coupon';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { blue, cyan } from '@ant-design/colors';
import { EditTwoTone, EyeTwoTone, PlusOutlined } from '@ant-design/icons';
import { Select as AntSelect, SelectProps as AntSelectProps, Button, Col, Row } from 'antd';
import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export type CouponSelectProps = AntSelectProps & {
  ignoreValues?: number[];
  group?: CouponGroup;
  showDetail?: boolean;
  showEdit?: boolean;
  showAdd?: boolean;
};
export const CouponSelect: FC<CouponSelectProps> = (props) => {
  const {
    placeholder = 'Chọn mã giảm',
    loading: loadingProps,
    allowClear = true,
    showSearch = true,
    ignoreValues = [],
    group,
    showDetail,
    showEdit,
    showAdd,
    value,
    style,
    className,
    ...restProps
  } = props;
  const { loading, items, fetchAllCouponList } = useCouponSelectionStore();
  const navigate = useNavigate();

  const options = useMemo(() => {
    return items
      .filter((item) => !ignoreValues.includes(item.id) && (!group || item.group === group))
      .map((item) => ({ label: `${item.code} - ${item.name}`, value: item.id }));
  }, [items, ...ignoreValues]);

  useEffect(() => {
    fetchAllCouponList({ reload: false });
  }, [fetchAllCouponList]);

  const selected = useMemo(() => {
    if (!value) return undefined;
    return items.find((item) => item.id === value);
  }, [items, value]);

  return (
    <Row style={style} className={className} gutter={8} wrap={false}>
      <Col flex={1}>
        <AntSelect
          {...restProps}
          value={value}
          filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
          loading={loadingProps || loading}
          options={options}
          placeholder={placeholder}
          allowClear={allowClear}
          showSearch={showSearch}
        />
      </Col>
      {!!showDetail && !!selected && (
        <Col>
          <Button
            shape="circle"
            onClick={() => navigate(`${paths.COUPON_ALL}/${selected.group}/view/${selected.id}`)}
            icon={<EyeTwoTone twoToneColor={cyan.primary} />}></Button>
        </Col>
      )}
      {!!showEdit && !!selected && (
        <Col>
          <Button
            shape="circle"
            onClick={() => navigate(`${paths.COUPON_ALL}/${selected.group}/${selected.id}`)}
            icon={<EditTwoTone twoToneColor={blue.primary} />}></Button>
        </Col>
      )}
      {!!showAdd && !selected && (
        <Col>
          <Button
            shape="circle"
            onClick={() => navigate(`${paths.COUPON_ALL}/${group || CouponGroup.promotion}/create`)}
            icon={<PlusOutlined style={{ color: blue.primary }} />}></Button>
        </Col>
      )}
    </Row>
  );
};
