import { LayoutContent } from '@/components/Layout';
import { FC, useState } from 'react';
import { UserList } from './components/UserList';
import { Filter } from './components/Filter';
import { ModalCreation } from './components/ModalCreation';
import { Button } from 'antd';
import { UserDetail } from '@/types/user';

export const UserPage: FC = () => {
  const [openModel, setOpenModel] = useState(false);
  const [user, setUser] = useState<UserDetail | undefined>();

  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <Button
            type="primary"
            onClick={() => {
              setUser(undefined);
              setOpenModel(true);
            }}>
            Thêm mới
          </Button>
        ),
      }}>
      <UserList
        onEditClick={(user) => {
          setUser(user);
          setOpenModel(true);
        }}
      />
      <ModalCreation open={openModel} setOpen={setOpenModel} user={user} />
    </LayoutContent>
  );
};
