import {
  CreateProductBrandRequest,
  ProductBrand,
  ProductBrandQuery,
  FetchProductBrandListResponse,
  UpdateProductBrandRequest,
} from '@/types/productBrand';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/product-brand';
export const fetchProductBrandList = async (params: ProductBrandQuery): Promise<FetchProductBrandListResponse> => {
  const { data } = await baseApi.get<FetchProductBrandListResponse>(ROOT_PATH, {
    params,
  });
  return data;
};

export const fetchProductBrand = async (id: number): Promise<ProductBrand> => {
  const { data } = await baseApi.get<ProductBrand>(`${ROOT_PATH}/${id}`);
  return data;
};

export const createProductBrand = async (input: CreateProductBrandRequest): Promise<ProductBrand> => {
  const { data } = await baseApi.post(ROOT_PATH, serializeNullType(input));
  return data;
};

export const updateProductBrand = async (id: number, input: UpdateProductBrandRequest): Promise<ProductBrand> => {
  const { data } = await baseApi.put(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deleteProductBrand = async (id: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${id}`);
  return data;
};
