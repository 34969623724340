import { menuItems } from '@/config/menu';
import { useAuthStore } from '@/stores/auth';
import { useMemo } from 'react';

export const useMenuItems = () => {
  const { userDetail } = useAuthStore();

  return useMemo(
    () =>
      menuItems.filter((item) => {
        return !item.role || item.role === userDetail?.role;
      }),
    [userDetail]
  );
};
