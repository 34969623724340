import { create } from 'zustand';
import * as formDataServices from '@/api/formData';
import { FormDataQuery, FormData, UpdateFormDataRequest, FormDataName } from '@/types/formData';

export type FormDataState = {
  loading: boolean;
  query: FormDataQuery;
  total: number;
  items: FormData[];
  submitting: boolean;
  setFormName: (formName: FormDataName, searchFields: string[]) => void;
  fetchFormDataList: (query?: Partial<FormDataQuery>) => Promise<void>;
  updateFormData: (id: number, input: UpdateFormDataRequest) => Promise<void>;
  deleteFormData: (id: number) => Promise<void>;
};
export const useFormDataStore = create<FormDataState>((set, get) => ({
  loading: true,
  query: {
    formName: undefined,
    search: '',
    searchFields: [],
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  submitting: false,
  setFormName: (formName, searchFields) => {
    if (get().query.formName === formName) return;
    set({
      loading: true,
      query: {
        formName,
        searchFields,
        search: '',
        page: 1,
        limit: 10,
      },
      total: 0,
      items: [],
    });
  },
  fetchFormDataList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      get().setFormName(query.formName, query.searchFields);

      set({ loading: true, query });
      const response = await formDataServices.fetchFormDataList(query);
      set({
        loading: false,
        total: response.metadata.total,
        items: response.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  updateFormData: async (id, update) => {
    try {
      set({ submitting: true });
      await formDataServices.updateFormData(id, update);
      if (get().query.formName) get().fetchFormDataList();
      set({ submitting: false });
    } catch (error) {
      set({ submitting: false });
      return Promise.reject(error);
    }
  },
  deleteFormData: async (id) => {
    try {
      set({ loading: true });
      await formDataServices.deleteFormData(id);
      if (get().query.formName) get().fetchFormDataList();
    } finally {
      set({ loading: false });
    }
  },
}));
