import { Switch, SwitchProps } from 'antd';
import { FC } from 'react';

export type SwitchWithLabelProps = SwitchProps & { label?: string };
export const SwitchWithLabel: FC<SwitchWithLabelProps> = ({ label, ...props }) => {
  return (
    <>
      <Switch {...props} />
      <span style={{ paddingLeft: 8 }}>{label}</span>
    </>
  );
};
