import { useProductBrandCreationModalStore, useProductBrandStore } from '@/stores/productBrand';
import { ProductBrand, ProductBrandQuery } from '@/types/productBrand';
import { serializeSorter } from '@/utils/helpers/serializeSorter';
import { blue } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useEffect } from 'react';
import format from 'date-fns/format';

export const ProductBrandList = () => {
  const { loading, query, total, items, fetchProductBrandList, deleteProductBrand } = useProductBrandStore();
  const { openModal } = useProductBrandCreationModalStore();

  useEffect(() => {
    fetchProductBrandList().catch(() => message.error('Lỗi không thể tải danh sách Thương hiệu', 3));
  }, []);

  const onDeleteProductBrand = async (manufacturerId: number) => {
    Modal.confirm({
      title: 'Xóa Thương hiệu',
      content: 'Bạn có muốn xóa Thương hiệu này không?',
      onOk: async () => {
        try {
          await deleteProductBrand(manufacturerId);
          message.success('Xóa Thương hiệu thành công');
        } catch (error: any) {
          message.error('Lỗi không thể xóa Thương hiệu này!');
        }
      },
    });
  };

  const columns: ColumnProps<ProductBrand>[] = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   width: 60,
    //   sorter: {
    //     multiple: 1,
    //   },
    // },
    {
      title: 'Tên',
      dataIndex: 'name',
      sorter: {
        multiple: 2,
      },
      render: (_, record) => (
        <>
          <div>{record.name}</div>
          <a href={`${process.env.REACT_APP_SITE_DOMAIN}/thuong-hieu/${record.slug}`} target="_blank" rel="noreferrer">
            {record.slug}
          </a>
        </>
      ),
    },
    {
      title: 'Xuất xứ',
      dataIndex: 'origin',
      sorter: {
        multiple: 2,
      },
    },
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      width: 150,
      sorter: {
        multiple: 4,
      },
      render: (createdAt) => (createdAt ? format(new Date(createdAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      width: 150,
      sorter: {
        multiple: 5,
      },
      render: (updatedAt) => (updatedAt ? format(new Date(updatedAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Button type="link" shape="circle" title="Edit" onClick={() => openModal(record)}>
              <EditOutlined twoToneColor={blue.primary} />
            </Button>
            <Button
              danger
              type="link"
              shape="circle"
              title="Delete"
              onClick={() => onDeleteProductBrand(record.id)}>
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  const onChange = useCallback((pagination: any, _: any, sorter: any) => {
    const newParams: ProductBrandQuery = {
      page: pagination.current || query.page,
      limit: pagination.pageSize || query.limit,
      sort: serializeSorter(sorter),
    };
    fetchProductBrandList(newParams);
  }, []);

  return (
    <Table
      rowKey={(item) => item.id}
      loading={loading}
      columns={columns}
      dataSource={items}
      onChange={onChange}
      pagination={{
        current: query.page,
        pageSize: query.limit,
        total: total,
      }}
    />
  );
};
