import {
  CreateProductCompositionRequest,
  ProductComposition,
  ProductCompositionQuery,
  FetchProductCompositionListResponse,
  UpdateProductCompositionRequest,
} from '@/types/productComposition';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/product-composition';
export const fetchProductCompositionList = async (
  params: ProductCompositionQuery
): Promise<FetchProductCompositionListResponse> => {
  const { data } = await baseApi.get<FetchProductCompositionListResponse>(ROOT_PATH, {
    params,
  });
  return data;
};

export const fetchProductComposition = async (id: number): Promise<ProductComposition> => {
  const { data } = await baseApi.get<ProductComposition>(`${ROOT_PATH}/${id}`);
  return data;
};

export const createProductComposition = async (input: CreateProductCompositionRequest): Promise<ProductComposition> => {
  const { data } = await baseApi.post(ROOT_PATH, serializeNullType(input));
  return data;
};

export const updateProductComposition = async (
  id: number,
  input: UpdateProductCompositionRequest
): Promise<ProductComposition> => {
  const { data } = await baseApi.put(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deleteProductComposition = async (id: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${id}`);
  return data;
};
