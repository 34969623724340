import { cyan } from '@ant-design/colors';
import { EyeTwoTone, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFormDataStore } from '@/stores/formData';
import { FormData, FormDataName } from '@/types/formData';

export type FormDataListProps = {
  searchFields: string[];
  formName: FormDataName;
  basePath: string;
  columns: ColumnProps<FormData>[];
};
export const FormDataList: FC<FormDataListProps> = ({ searchFields, formName, basePath, columns: _columns }) => {
  const { loading, query, total, items, deleteFormData, fetchFormDataList } = useFormDataStore();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFormDataList({ formName, searchFields }).catch(() => message.error('Lỗi không thể tải', 3));
  }, []);

  const columns: ColumnProps<FormData>[] = [
    ..._columns,
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      width: 151,
      sorter: {
        multiple: 4,
      },
      render: (_, record) => (record.createdAt ? dayjs(new Date(record.createdAt)).format('DD/MM/YYYY HH:mm') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      width: 151,
      sorter: {
        multiple: 4,
      },
      render: (_, record) => (
        <>{record.updatedAt ? dayjs(new Date(record.updatedAt)).format('DD/MM/YYYY HH:mm') : ''}</>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              danger
              shape="circle"
              title="Xóa"
              onClick={() => {
                Modal.confirm({
                  title: 'Xóa dữ liệu',
                  content: 'Bạn có muốn xóa dữ liệu này không?',
                  okText: 'Xóa',
                  cancelText: 'Hủy bỏ',
                  onOk: () => deleteFormData(record.id),
                });
              }}>
              <DeleteOutlined twoToneColor={cyan.primary} />
            </Button>
            <Button type="link" shape="circle" title="View" onClick={() => navigate(`${basePath}/${record.id}`)}>
              <EyeTwoTone twoToneColor={cyan.primary} />
            </Button>
          </>
        );
      },
    },
  ];

  const onChange = useCallback((pagination: any) => {
    fetchFormDataList({
      page: pagination.current || query.page,
      limit: pagination.pageSize || query.limit,
    });
  }, []);

  return (
    <Table
      rowKey={(item) => item.id}
      loading={loading}
      columns={columns}
      dataSource={items}
      onChange={onChange}
      pagination={{
        current: query.page,
        pageSize: query.limit,
        total: total,
      }}
    />
  );
};
