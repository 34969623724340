import { FormData, FormDataQuery, FormDataResponse, UpdateFormDataRequest } from '@/types/formData';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/form-data';
export const fetchFormDataList = async (params: FormDataQuery): Promise<FormDataResponse> => {
  const { data } = await baseApi.get<FormDataResponse>(ROOT_PATH, {
    params: {
      ...params,
      ...(params.searchFields?.length ? { searchFields: params.searchFields.join('|') } : {}),
    },
  });
  return data;
};

export const fetchFormData = async (id: number): Promise<FormData> => {
  const { data } = await baseApi.get<FormData>(`${ROOT_PATH}/${id}`);
  return data;
};

export const updateFormData = async (id: number, input: UpdateFormDataRequest): Promise<FormData> => {
  const { data } = await baseApi.put(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deleteFormData = async (id: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${id}`);
  return data;
};
