import {
  Account,
  FetchAccountListResponse,
  FetchAccountListQuery,
  UpdateAccountRequest,
  UpdateAccountStatusRequest,
  QueryAllAccountsWithPointRequest,
  AccountWithPoint,
} from '@/types/account';
import { baseApi } from './common/baseApi';
import fileDownload from 'js-file-download';

const ROOT_PATH = 'account';

export const createAccount = async (request: UpdateAccountRequest) => {
  const { data } = await baseApi.post<Account>(`${ROOT_PATH}/admin`, request);
  return data;
};

export const updateAccount = async (id: number, request: UpdateAccountRequest) => {
  const { data } = await baseApi.put<Account>(`${ROOT_PATH}/admin/${id}`, request);
  return data;
};

export const updateAccountStatus = async (id: number, request: UpdateAccountStatusRequest) => {
  const { data } = await baseApi.put<Account>(`${ROOT_PATH}/admin/${id}/status`, request);
  return data;
};

export const deleteAccount = async (id: number) => {
  await baseApi.delete<Account>(`${ROOT_PATH}/admin/${id}`);
};

export const fetchAccount = async (id: number) => {
  const { data } = await baseApi.get<Account>(`${ROOT_PATH}/admin/${id}`);
  return data;
};

export const fetchAccountList = async (params: FetchAccountListQuery) => {
  const { data } = await baseApi.get<FetchAccountListResponse>(`${ROOT_PATH}/admin`, { params });
  return data;
};

export const exportAccounts = async () => {
  const response = await baseApi.get(`${ROOT_PATH}/admin/export`, { responseType: 'blob' });
  fileDownload(response.data, 'accounts.csv');
};

export const fetchAllAccountsWithPoints = async (params: QueryAllAccountsWithPointRequest) => {
  const { data } = await baseApi.get<AccountWithPoint[]>(`${ROOT_PATH}/admin/points`, { params });
  return data;
};
