import { useAccountStore } from '@/stores/account';
import { AccountStatus, accountStatusNameMapping } from '@/types/account';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { FC } from 'react';

export const Filter: FC = () => {
  const { query, fetchAccountList } = useAccountStore();
  const [form] = Form.useForm();

  const onFinish = (value: any) => {
    fetchAccountList({ search: value.search, status: value.status, page: 1 });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Row gutter={[16, 0]}>
        <Col span={6}>
          <Form.Item name="status" initialValue={query.status}>
            <Select
              options={Object.values(AccountStatus).map((accountStatus) => ({
                label: accountStatusNameMapping[accountStatus],
                value: accountStatus,
              }))}
              placeholder="Trạng thái"
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="search" initialValue={query.search}>
            <Input placeholder="Tìm kiếm tài khoản..." />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Button htmlType="submit">Tìm kiếm</Button>
        </Col>
      </Row>
    </Form>
  );
};
