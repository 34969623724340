import { useUserStore } from '@/stores/user'
import { Button, Col, Form, Input, Row } from 'antd'
import { FC, useEffect } from 'react'

export const Filter: FC = () => {
  const { setFilter, filter } = useUserStore()
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue(filter)
    // eslint-disable-next-line
  }, [form])
  return (
    <Form onFinish={setFilter} form={form}>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Form.Item name="username">
            <Input placeholder="Tìm kiếm người dùng..." />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Button htmlType="submit">Tìm kiếm</Button>
        </Col>
      </Row>
    </Form>
  )
}
