import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { Filter } from './components/Filter';
import { ProductCompositionList } from './components/ProductCompositionList';
import { Button } from 'antd';
import { useProductCompositionCreationModalStore } from '@/stores/productComposition';
import { ProductCompositionCreationModal } from './components/ProductCompositionCreationModal';

export const ProductCompositionsPage: FC = () => {
  const { openModal } = useProductCompositionCreationModalStore();
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <Button type="primary" onClick={() => openModal()}>
            Thêm mới
          </Button>
        ),
      }}>
      <ProductCompositionList />
      <ProductCompositionCreationModal />
    </LayoutContent>
  );
};
