import { create } from 'zustand';
import * as ProductCategoryServices from '@/api/productCategory';
import {
  ProductCategoryQuery,
  ProductCategory,
  CreateProductCategoryRequest,
  UpdateProductCategoryRequest,
} from '@/types/productCategory';

export type ProductCategoryState = {
  loading: boolean;
  loaded: boolean;
  query: ProductCategoryQuery;
  total: number;
  dataSource: ProductCategory[];
  fetchProductCategoryList: (query?: Partial<ProductCategoryQuery>) => Promise<void>;
  createProductCategory: (input: CreateProductCategoryRequest) => Promise<void>;
  updateProductCategory: (id: number, input: UpdateProductCategoryRequest) => Promise<void>;
  deleteProductCategory: (ProductCategoryId: number) => Promise<void>;
};
export const useProductCategoryStore = create<ProductCategoryState>((set, get) => ({
  loading: false,
  loaded: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  dataSource: [],
  fetchProductCategoryList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const ProductCategoryRes = await ProductCategoryServices.fetchProductCategoryList(query);
      set({
        loading: false,
        loaded: true,
        total: ProductCategoryRes.metadata.total,
        dataSource: ProductCategoryRes.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  createProductCategory: async (create) => {
    try {
      useProductCategoryCreationModalStore.getState().setLoading(true);
      await ProductCategoryServices.createProductCategory(create);
      if (get().loaded) get().fetchProductCategoryList();
      if (useProductCategorySelectionStore.getState().loaded)
        useProductCategorySelectionStore.getState().fetchAllProductCategoryList({ reload: true });
    } finally {
      useProductCategoryCreationModalStore.getState().setLoading(false);
    }
  },
  updateProductCategory: async (id, update) => {
    try {
      useProductCategoryCreationModalStore.getState().setLoading(true);
      await ProductCategoryServices.updateProductCategory(id, update);
      if (get().loaded) get().fetchProductCategoryList();
      if (useProductCategorySelectionStore.getState().loaded)
        useProductCategorySelectionStore.getState().fetchAllProductCategoryList({ reload: true });
    } finally {
      useProductCategoryCreationModalStore.getState().setLoading(false);
    }
  },
  deleteProductCategory: async (ProductCategoryId) => {
    try {
      set({ loading: true });
      await ProductCategoryServices.deleteProductCategory(ProductCategoryId);
      if (get().loaded) get().fetchProductCategoryList();
      if (useProductCategorySelectionStore.getState().loaded)
        useProductCategorySelectionStore.getState().fetchAllProductCategoryList({ reload: true });
    } finally {
      set({ loading: false });
    }
  },
}));

export type ProductCategoryCreationModalState = {
  loading: boolean;
  open: boolean;
  initial?: ProductCategory;
  setLoading: (loading: boolean) => void;
  openModal: (initial?: ProductCategory) => void;
  closeModal: () => void;
};
export const useProductCategoryCreationModalStore = create<ProductCategoryCreationModalState>((set) => ({
  loading: false,
  open: false,
  initial: undefined,
  setLoading: (loading) => set({ loading }),
  openModal: (initial) => {
    set({ initial, open: true });
  },
  closeModal: () => {
    set({ initial: undefined, open: false });
  },
}));

export type ProductCategorySelectionState = {
  loading: boolean;
  loaded: boolean;
  items: ProductCategory[];
  fetchAllProductCategoryList: (options?: { reload?: boolean }) => Promise<void>;
};
export const useProductCategorySelectionStore = create<ProductCategorySelectionState>((set, get) => ({
  loading: false,
  loaded: false,
  items: [],
  fetchAllProductCategoryList: async ({ reload = true } = {}) => {
    if (!reload && get().loaded) return;
    try {
      set({ loading: true });
      const ProductCategoryRes = await ProductCategoryServices.fetchProductCategoryList({
        page: 1,
        limit: 1000,
      });
      set({ loading: false, loaded: true, items: ProductCategoryRes.records || [] });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
}));
