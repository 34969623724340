import { Order, orderStatusNameMapping } from '@/types/order';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as orderApi from '@/api/order';
import { Button, Col, Divider, Modal, Row, Space, Spin, Table, message } from 'antd';
import { LayoutContent } from '@/components/Layout';
import { paymentMethodNameMapping } from '@/types/orderPayment';
import { shippingMethodNameMapping, shippingPartnerNameMapping } from '@/types/orderShipping';
import { OrderItem } from '@/types/orderItem';
import { numberToVNDPrice } from '@/utils/helpers/pricing';
import dayjs from 'dayjs';
import { Actions } from './Actions';

export type OrderStatusForm = {
  reason?: string;
  reasonDetail?: string;
};
export type OrderDetailPageProps = {};
export const OrderDetailPage: FC<OrderDetailPageProps> = () => {
  const { id: _id } = useParams();
  const id = parseInt(_id);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<Order>();
  const navigate = useNavigate();

  const fetchOrder = useCallback(async () => {
    if (!id) {
      setLoading(false);
    }
    setLoading(true);
    try {
      const order = await orderApi.fetchOrder(id);
      setOrder(order);
    } catch (error: any) {
      if (error?.response?.status === 404) {
        navigate(-1);
        message.error('Trang không tìm thấy');
      } else {
        message.error('Tải trang không thành công');
      }
    } finally {
      setLoading(false);
    }
  }, [id, setLoading]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  return (
    <Spin spinning={loading}>
      {!order && <div style={{ height: 300 }}></div>}
      {!!order && (
        <LayoutContent
          header={{
            leftElement: (
              <Space direction="vertical" size={12}>
                <h3>Order #{order.code || '...'}</h3>
                <p>{order.status ? orderStatusNameMapping[order.status] : ''}</p>
              </Space>
            ),
            rightElement: !!order && (
              <>
                <Button
                  onClick={() =>
                    Modal.confirm({
                      content: 'Bạn có muốn xuất danh sách đơn hàng?',
                      okText: 'Xuất',
                      onOk: () => orderApi.exportOrder(id),
                    })
                  }>
                  Xuất
                </Button>
                <Actions order={order} onChangeSuccess={fetchOrder} />
              </>
            ),
          }}
          sections={[
            {
              key: 'info',
              content: (
                <>
                  <Row gutter={[32, 24]}>
                    <Col span={12}>
                      <h2 style={{ fontSize: 16, fontWeight: 600 }}>Địa chỉ và thông tin người nhận hàng</h2>
                      <Divider />
                      <Row gutter={[32, 24]}>
                        <Col span={8}>Tên người nhận</Col>
                        <Col span={16}>
                          <strong>{order.orderShipping.fullName}</strong>
                        </Col>
                        <Col span={8}>Địa chỉ giao hàng</Col>
                        <Col span={16}>
                          <strong>
                            {order.orderShipping.wardName} - {order.orderShipping.districtName} -{' '}
                            {order.orderShipping.provinceName}
                          </strong>
                        </Col>
                        <Col span={8}>Địa chỉ chi tiết</Col>
                        <Col span={16}>
                          <strong>{order.orderShipping.address}</strong>
                        </Col>
                        <Col span={8}>Số điện thoại</Col>
                        <Col span={16}>
                          <strong>{order.orderShipping.phone}</strong>
                        </Col>
                        <Col span={8}>Email</Col>
                        <Col span={16}>
                          <strong>{order.orderShipping.email}</strong>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <h2 style={{ fontSize: 16, fontWeight: 600 }}>Hình thức thanh toán & vận chuyển</h2>
                      <Divider />
                      <Row gutter={[32, 24]}>
                        <Col span={8}>Hình thức thanh toán</Col>
                        <Col span={16}>
                          <strong>{paymentMethodNameMapping[order.orderPayment.paymentMethod]}</strong>
                        </Col>
                        <Col span={8}>Hình thức vận chuyển</Col>
                        <Col span={16}>
                          <strong>{shippingMethodNameMapping[order.orderShipping.shippingMethod]}</strong>
                        </Col>
                        <Col span={8}>Đơn vị vận chuyển</Col>
                        <Col span={16}>
                          <strong>{shippingPartnerNameMapping[order.orderShipping.partner] || '(Chưa có)'}</strong>
                        </Col>
                        <Col span={8}>Ngày giao dự kiến</Col>
                        <Col span={16}>
                          <strong>
                            {order.orderShipping.expectedDeliveryTime
                              ? dayjs(new Date(order.orderShipping.expectedDeliveryTime)).format('DD/MM/YYYY HH:mm')
                              : '(chưa có)'}
                          </strong>
                        </Col>
                        <Col span={8}>Mã vận đơn</Col>
                        <Col span={16}>
                          <strong>{order.orderShipping.orderShippingGhn?.code || '(Chưa có)'}</strong>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                  <Space direction="vertical" size={12}>
                    <div>
                      <strong>Ghi chú:</strong>
                      {'  '}
                      {order.note || '(chưa có)'}
                    </div>
                  </Space>
                  <div style={{ height: 20 }}></div>
                </>
              ),
            },
            {
              title: 'Sản phẩm',
              key: 'products',
              content: (
                <>
                  <Table<OrderItem>
                    columns={[
                      {
                        title: 'Tên sản phẩm',
                        dataIndex: 'name',
                      },
                      {
                        title: 'Số lượng',
                        dataIndex: 'quantity',
                        width: 140,
                      },
                      {
                        title: 'Đơn giá',
                        dataIndex: 'price',
                        width: 170,
                        align: 'right',
                        render: (_, record) => (
                          <>
                            <strong>{numberToVNDPrice(record.discountPrice || record.price)}</strong>
                            {!!record.discountPrice && (
                              <>
                                <br />
                                <s>{numberToVNDPrice(record.price)}</s>
                              </>
                            )}
                          </>
                        ),
                      },
                      {
                        title: 'Tổng cộng',
                        dataIndex: 'total',
                        width: 180,
                        align: 'right',
                        render: (_, record) => (
                          <strong>{numberToVNDPrice(record.quantity * (record.discountPrice || record.price))}</strong>
                        ),
                      },
                    ]}
                    dataSource={order.orderItems || []}
                    pagination={false}
                    footer={() => (
                      <>
                        <Space direction="vertical" size={16} style={{ width: '100%' }}>
                          <div>
                            <Row gutter={32}>
                              <Col flex={1}>Tạm tính</Col>
                              <Col>
                                <strong>{numberToVNDPrice(order.totalAllItemsPrice)}</strong>
                              </Col>
                            </Row>
                          </div>
                          {!!order.totalPaymentDiscountPrice && (
                            <div>
                              <Row gutter={32}>
                                <Col flex={1}>Phí theo hình thức thanh toán</Col>
                                <Col>
                                  <strong>
                                    {order.totalPaymentDiscountPrice ? '-' : ''}
                                    {numberToVNDPrice(order.totalPaymentDiscountPrice)}
                                  </strong>
                                </Col>
                              </Row>
                            </div>
                          )}
                          <div>
                            <Row gutter={32}>
                              <Col flex={1}>Phí vận chuyển</Col>
                              <Col>
                                <strong>{numberToVNDPrice(order.totalDeliveryPrice)}</strong>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <Row gutter={32}>
                              <Col flex={1}>Phụ phí</Col>
                              <Col>
                                <strong>{numberToVNDPrice(0)}</strong>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <Row gutter={32}>
                              <Col flex={1}>Mã giảm quà tặng</Col>
                              <Col>
                                <strong>
                                  {order.totalCouponDiscountPrice ? '-' : ''}
                                  {numberToVNDPrice(order.totalCouponDiscountPrice)}
                                </strong>
                              </Col>
                            </Row>
                          </div>
                        </Space>

                        <Divider />
                        <div>
                          <Row gutter={32}>
                            <Col flex={1}>
                              <big>Tổng tiền</big>
                            </Col>
                            <Col>
                              <big>
                                <strong style={{ color: 'rgb(22, 119, 255)' }}>
                                  {numberToVNDPrice(order.totalPrice)}
                                </strong>
                              </big>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  />
                </>
              ),
            },
          ]}
        />
      )}
    </Spin>
  );
};
