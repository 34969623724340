import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { Filter } from './components/Filter';
import { Button } from 'antd';
import { BountyProgramList } from './components/BountyProgramList';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/config/path';

export type BountyProgramsPageProps = {};
export const BountyProgramsPage: FC<BountyProgramsPageProps> = () => {
  const navigate = useNavigate();
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <Button type="primary" onClick={() => navigate(paths.BOUNTY_PROGRAM_CREATE)}>
            Thêm mới
          </Button>
        ),
      }}>
      <BountyProgramList />
    </LayoutContent>
  );
};
