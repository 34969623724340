import {
  CreateProductIndicationRequest,
  ProductIndication,
  ProductIndicationQuery,
  UpdateProductIndicationRequest,
} from '@/types/productIndication';
import { create } from 'zustand';
import * as productIndicationApi from '@/api/productIndication';

export type ProductIndicationState = {
  loading: boolean;
  loaded: boolean;
  query: ProductIndicationQuery;
  total: number;
  items: ProductIndication[];
  fetchProductIndicationList: (query?: Partial<ProductIndicationQuery>) => Promise<void>;
  createProductIndication: (input: CreateProductIndicationRequest) => Promise<void>;
  updateProductIndication: (id: number, input: UpdateProductIndicationRequest) => Promise<void>;
  deleteProductIndication: (id: number) => Promise<void>;
};

export const useProductIndicationStore = create<ProductIndicationState>((set, get) => ({
  loading: false,
  loaded: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  fetchProductIndicationList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const res = await productIndicationApi.fetchProductIndicationList(query);
      set({
        loading: false,
        loaded: true,
        total: res.metadata.total,
        items: res.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  createProductIndication: async (create) => {
    try {
      useProductIndicationCreationModalStore.getState().setLoading(true);
      await productIndicationApi.createProductIndication(create);
      if (get().loaded) get().fetchProductIndicationList();
      if (useProductIndicationSelectionStore.getState().loaded)
        useProductIndicationSelectionStore.getState().fetchAllProductIndicationList({ reload: true });
    } finally {
      useProductIndicationCreationModalStore.getState().setLoading(false);
    }
  },
  updateProductIndication: async (id, update) => {
    try {
      useProductIndicationCreationModalStore.getState().setLoading(true);
      await productIndicationApi.updateProductIndication(id, update);
      if (get().loaded) get().fetchProductIndicationList();
      if (useProductIndicationSelectionStore.getState().loaded)
        useProductIndicationSelectionStore.getState().fetchAllProductIndicationList({ reload: true });
    } finally {
      useProductIndicationCreationModalStore.getState().setLoading(false);
    }
  },
  deleteProductIndication: async (id) => {
    try {
      set({ loading: true });
      await productIndicationApi.deleteProductIndication(id);
      if (get().loaded) get().fetchProductIndicationList();
      if (useProductIndicationSelectionStore.getState().loaded)
        useProductIndicationSelectionStore.getState().fetchAllProductIndicationList({ reload: true });
    } finally {
      set({ loading: false });
    }
  },
}));

export type ProductIndicationCreationModalState = {
  loading: boolean;
  open: boolean;
  initial?: ProductIndication;
  setLoading: (loading: boolean) => void;
  openModal: (initial?: ProductIndication) => void;
  closeModal: () => void;
};
export const useProductIndicationCreationModalStore = create<ProductIndicationCreationModalState>((set) => ({
  loading: false,
  open: false,
  initial: undefined,
  setLoading: (loading) => set({ loading }),
  openModal: (initial) => {
    set({ initial, open: true });
  },
  closeModal: () => {
    set({ initial: undefined, open: false });
  },
}));

export type ProductIndicationSelectionState = {
  loading: boolean;
  loaded: boolean;
  items: ProductIndication[];
  fetchAllProductIndicationList: (options?: { reload?: boolean }) => Promise<void>;
};
export const useProductIndicationSelectionStore = create<ProductIndicationSelectionState>((set, get) => ({
  loading: false,
  loaded: false,
  items: [],
  fetchAllProductIndicationList: async ({ reload = true } = {}) => {
    if (!reload && get().loaded) return;
    try {
      set({ loading: true });
      const ProductIndicationRes = await productIndicationApi.fetchProductIndicationList({
        page: 1,
        limit: 1000,
      });
      set({ loading: false, loaded: true, items: ProductIndicationRes.records || [] });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
}));
