import { Button, Modal, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import format from 'date-fns/format';
import compareAsc from 'date-fns/compareAsc';
import { UserDetail, UserRole, userRoleLabels } from '@/types/user';
import { useUserStore } from '@/stores/user';
import { FC, useEffect, useState } from 'react';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { blue, red } from '@ant-design/colors';
import { UnlockTwoTone, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { ModalChangePassword } from './ModalChangePassword';

export type UserListProps = {
  onEditClick: (user: UserDetail) => void;
};
export const UserList: FC<UserListProps> = ({ onEditClick }) => {
  const { fetchAllUsers, filter, loading, users, deleteUser } = useUserStore();
  const [selectedPasswordUserId, setSelectedPasswordUserId] = useState<number>();

  useEffect(() => {
    fetchAllUsers().catch(() => message.error('Fetch Users Failure', 3));
  }, [fetchAllUsers]);

  const onDeleteUser = async (userId: number) => {
    Modal.confirm({
      title: 'Delete User',
      content: 'Do you want to Delete User',
      onOk: async () => {
        try {
          await deleteUser(userId);
          message.success('Delete user Successfully');
        } catch (error: any) {
          if (error.response?.data?.code === 10002) {
            message.error('Can not delete yourself!');
          } else {
            message.error('Delete user Failure!');
          }
        }
      },
    });
  };

  const columns: ColumnProps<UserDetail>[] = [
    {
      title: 'Tên đăng ký',
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortDirections: ['descend', 'ascend', 'descend'],
    },
    {
      title: 'Tên đầy đủ',
      dataIndex: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortDirections: ['descend', 'ascend', 'descend'],
    },
    {
      title: 'Phân quyền',
      dataIndex: 'role',
      render: (_, record) => {
        const label = userRoleLabels[record.role];
        if (record.role === UserRole.admin) return <strong>{label}</strong>;
        if (record.role === UserRole.employee) {
          const provinces = record.roleProvinceNames.length ? record.roleProvinceNames.join(', ') : 'Tất cả tỉnh thành';
          return (
            <>
              <strong>{label}</strong>
              <br />
              <div style={{ maxWidth: 300 }}>{provinces}</div>
            </>
          );
        }
        return '';
      },
    },
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      sorter: (a, b) => compareAsc(new Date(a.createdAt), new Date(b.createdAt)),
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (createdAt: string) => (createdAt ? format(new Date(createdAt), 'dd/MM/yyyy') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      sorter: (a, b) => compareAsc(new Date(a.updatedAt), new Date(b.updatedAt)),
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (updatedAt: string) => (updatedAt ? format(new Date(updatedAt), 'dd/MM/yyyy') : ''),
    },
    {
      title: '',
      dataIndex: 'action',
      render: (_, user) => {
        return (
          <>
            <Button type="link" shape="circle" title="Chỉnh sửa" onClick={() => onEditClick(user)}>
              <EditTwoTone twoToneColor={blue.primary} />
            </Button>
            <Button type="link" shape="circle" title="Đổi mật khẩu" onClick={() => setSelectedPasswordUserId(user.id)}>
              <UnlockTwoTone twoToneColor={blue.primary} />
            </Button>
            {user.username !== 'master' ? (
              <Button type="link" shape="circle" title="Xóa người dùng" onClick={() => onDeleteUser(user.id)}>
                <DeleteTwoTone twoToneColor={red.primary} />
              </Button>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowKey={(item) => item.id}
        columns={columns}
        loading={loading}
        dataSource={users.filter((user) => toLowerNonAccentCaseSearch(user.username, filter.username || ''))}
      />
      <ModalChangePassword userId={selectedPasswordUserId} onCancel={() => setSelectedPasswordUserId(undefined)} />
    </>
  );
};
