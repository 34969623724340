import { UserDetail, CreateUserForm, UpdateUserForm } from '@/types/user';
import { create } from 'zustand';
import * as userApi from '@/api/user';

export type UserFilterState = {
  username?: string;
};
export type UserState = {
  loading: boolean;
  users: UserDetail[];
  filter: UserFilterState;
  fetchAllUsers: () => Promise<void>;
  createUser: (input: CreateUserForm) => Promise<void>;
  updateUser: (id: number, input: UpdateUserForm) => Promise<void>;
  setFilter: (filter: Partial<UserFilterState>) => void;
  changePassword: (userId: number, password: string) => Promise<void>;
  deleteUser: (userId: number) => Promise<void>;
};

export const useUserStore = create<UserState>((set) => ({
  loading: true,
  users: [],
  filter: {},
  fetchAllUsers: async () => {
    try {
      set({ loading: true });
      const users = await userApi.fetchAllUsers();
      set({ users, loading: false });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  setFilter: (filter) => {
    set((state) => ({ ...state, filter: { ...state.filter, ...filter } }));
  },
  createUser: async (input) => {
    try {
      set({ loading: true });
      const user = await userApi.createUser(input);
      set((state) => ({
        ...state,
        loading: false,
        users: [user, ...state.users],
      }));
    } finally {
      set({ loading: false });
    }
  },
  updateUser: async (id, input) => {
    try {
      set({ loading: true });
      const user = await userApi.updateUser(id, input);
      set((state) => ({
        ...state,
        loading: false,
        users: state.users.map((_user) => (_user.id === user.id ? user : _user)),
      }));
    } finally {
      set({ loading: false });
    }
  },
  changePassword: async (userId, password) => {
    try {
      set({ loading: true });
      await userApi.changePassword(userId, { password });
    } finally {
      set({ loading: false });
    }
  },
  deleteUser: async (userId) => {
    try {
      set({ loading: true });
      await userApi.deleteUser(userId);
      set((state) => ({
        ...state,
        users: state.users.filter((user) => user.id !== userId),
      }));
    } finally {
      set({ loading: false });
    }
  },
}));
