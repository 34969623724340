import {
  CreateProductManufacturerRequest,
  ProductManufacturer,
  ProductManufacturerQuery,
  UpdateProductManufacturerRequest,
} from '@/types/productManufacturer';
import { create } from 'zustand';
import * as productManufacturerApi from '@/api/productManufacturer';

export type ProductManufacturerState = {
  loading: boolean;
  loaded: boolean;
  query: ProductManufacturerQuery;
  total: number;
  items: ProductManufacturer[];
  fetchProductManufacturerList: (query?: Partial<ProductManufacturerQuery>) => Promise<void>;
  createProductManufacturer: (input: CreateProductManufacturerRequest) => Promise<void>;
  updateProductManufacturer: (id: number, input: UpdateProductManufacturerRequest) => Promise<void>;
  deleteProductManufacturer: (id: number) => Promise<void>;
};

export const useProductManufacturerStore = create<ProductManufacturerState>((set, get) => ({
  loading: false,
  loaded: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  fetchProductManufacturerList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const res = await productManufacturerApi.fetchProductManufacturerList(query);
      set({
        loading: false,
        loaded: true,
        total: res.metadata.total,
        items: res.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  createProductManufacturer: async (create) => {
    try {
      useProductManufacturerCreationModalStore.getState().setLoading(true);
      await productManufacturerApi.createProductManufacturer(create);
      if (get().loaded) get().fetchProductManufacturerList();
      if (useProductManufacturerSelectionStore.getState().loaded)
        useProductManufacturerSelectionStore.getState().fetchAllProductManufacturerList({ reload: true });
    } finally {
      useProductManufacturerCreationModalStore.getState().setLoading(false);
    }
  },
  updateProductManufacturer: async (id, update) => {
    try {
      useProductManufacturerCreationModalStore.getState().setLoading(true);
      await productManufacturerApi.updateProductManufacturer(id, update);
      if (get().loaded) get().fetchProductManufacturerList();
      if (useProductManufacturerSelectionStore.getState().loaded)
        useProductManufacturerSelectionStore.getState().fetchAllProductManufacturerList({ reload: true });
    } finally {
      useProductManufacturerCreationModalStore.getState().setLoading(false);
    }
  },
  deleteProductManufacturer: async (id) => {
    try {
      set({ loading: true });
      await productManufacturerApi.deleteProductManufacturer(id);
      if (get().loaded) get().fetchProductManufacturerList();
      if (useProductManufacturerSelectionStore.getState().loaded)
        useProductManufacturerSelectionStore.getState().fetchAllProductManufacturerList({ reload: true });
    } finally {
      set({ loading: false });
    }
  },
}));

export type ProductManufacturerCreationModalState = {
  loading: boolean;
  open: boolean;
  initial?: ProductManufacturer;
  setLoading: (loading: boolean) => void;
  openModal: (initial?: ProductManufacturer) => void;
  closeModal: () => void;
};
export const useProductManufacturerCreationModalStore = create<ProductManufacturerCreationModalState>((set) => ({
  loading: false,
  open: false,
  initial: undefined,
  setLoading: (loading) => set({ loading }),
  openModal: (initial) => {
    set({ initial, open: true });
  },
  closeModal: () => {
    set({ initial: undefined, open: false });
  },
}));

export type ProductManufacturerSelectionState = {
  loading: boolean;
  loaded: boolean;
  items: ProductManufacturer[];
  fetchAllProductManufacturerList: (options?: { reload?: boolean }) => Promise<void>;
};
export const useProductManufacturerSelectionStore = create<ProductManufacturerSelectionState>((set, get) => ({
  loading: false,
  loaded: false,
  items: [],
  fetchAllProductManufacturerList: async ({ reload = true } = {}) => {
    if (!reload && get().loaded) return;
    try {
      set({ loading: true });
      const ProductManufacturerRes = await productManufacturerApi.fetchProductManufacturerList({
        page: 1,
        limit: 1000,
      });
      set({ loading: false, loaded: true, items: ProductManufacturerRes.records || [] });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
}));
