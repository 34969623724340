import {
  CreateProductUsingRequest,
  ProductUsing,
  ProductUsingQuery,
  UpdateProductUsingRequest,
} from '@/types/productUsing';
import { create } from 'zustand';
import * as productUsingApi from '@/api/productUsing';

export type ProductUsingState = {
  loading: boolean;
  loaded: boolean;
  query: ProductUsingQuery;
  total: number;
  items: ProductUsing[];
  fetchProductUsingList: (query?: Partial<ProductUsingQuery>) => Promise<void>;
  createProductUsing: (input: CreateProductUsingRequest) => Promise<void>;
  updateProductUsing: (id: number, input: UpdateProductUsingRequest) => Promise<void>;
  deleteProductUsing: (id: number) => Promise<void>;
};

export const useProductUsingStore = create<ProductUsingState>((set, get) => ({
  loading: false,
  loaded: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  fetchProductUsingList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const res = await productUsingApi.fetchProductUsingList(query);
      set({
        loading: false,
        loaded: true,
        total: res.metadata.total,
        items: res.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  createProductUsing: async (create) => {
    try {
      useProductUsingCreationModalStore.getState().setLoading(true);
      await productUsingApi.createProductUsing(create);
      if (get().loaded) get().fetchProductUsingList();
      if (useProductUsingSelectionStore.getState().loaded)
        useProductUsingSelectionStore.getState().fetchAllProductUsingList({ reload: true });
    } finally {
      useProductUsingCreationModalStore.getState().setLoading(false);
    }
  },
  updateProductUsing: async (id, update) => {
    try {
      useProductUsingCreationModalStore.getState().setLoading(true);
      await productUsingApi.updateProductUsing(id, update);
      if (get().loaded) get().fetchProductUsingList();
      if (useProductUsingSelectionStore.getState().loaded)
        useProductUsingSelectionStore.getState().fetchAllProductUsingList({ reload: true });
    } finally {
      useProductUsingCreationModalStore.getState().setLoading(false);
    }
  },
  deleteProductUsing: async (id) => {
    try {
      set({ loading: true });
      await productUsingApi.deleteProductUsing(id);
      if (get().loaded) get().fetchProductUsingList();
      if (useProductUsingSelectionStore.getState().loaded)
        useProductUsingSelectionStore.getState().fetchAllProductUsingList({ reload: true });
    } finally {
      set({ loading: false });
    }
  },
}));

export type ProductUsingCreationModalState = {
  loading: boolean;
  open: boolean;
  initial?: ProductUsing;
  setLoading: (loading: boolean) => void;
  openModal: (initial?: ProductUsing) => void;
  closeModal: () => void;
};
export const useProductUsingCreationModalStore = create<ProductUsingCreationModalState>((set) => ({
  loading: false,
  open: false,
  initial: undefined,
  setLoading: (loading) => set({ loading }),
  openModal: (initial) => {
    set({ initial, open: true });
  },
  closeModal: () => {
    set({ initial: undefined, open: false });
  },
}));

export type ProductUsingSelectionState = {
  loading: boolean;
  loaded: boolean;
  items: ProductUsing[];
  fetchAllProductUsingList: (options?: { reload?: boolean }) => Promise<void>;
};
export const useProductUsingSelectionStore = create<ProductUsingSelectionState>((set, get) => ({
  loading: false,
  loaded: false,
  items: [],
  fetchAllProductUsingList: async ({ reload = true } = {}) => {
    if (!reload && get().loaded) return;
    try {
      set({ loading: true });
      const ProductUsingRes = await productUsingApi.fetchProductUsingList({
        page: 1,
        limit: 1000,
      });
      set({ loading: false, loaded: true, items: ProductUsingRes.records || [] });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
}));
