import { useProductCategorySelectionStore } from '@/stores/productCategory';
import { ProductCategory } from '@/types/productCategory';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { FC, useEffect, useMemo } from 'react';

type ProductCategoryWithChildren = { item: ProductCategory; children: Map<number, ProductCategoryWithChildren> };
export type ProductCategorySelectProps = AntSelectProps & {
  ignoreValues?: number[];
};
export const ProductCategorySelect: FC<ProductCategorySelectProps> = (props) => {
  const {
    placeholder = 'Chọn nhóm thuốc',
    loading: loadingProps,
    allowClear = true,
    showSearch = true,
    ignoreValues = [],
    ...restProps
  } = props;
  const { loading, items: productCategories, fetchAllProductCategoryList } = useProductCategorySelectionStore();

  const options = useMemo(() => {
    const productCategoryMap = new Map<number, ProductCategoryWithChildren>();
    productCategories.forEach((productCategory) => {
      productCategoryMap.set(productCategory.id, {
        item: productCategory,
        children: new Map<number, ProductCategoryWithChildren>(),
      });
    });
    productCategoryMap.forEach((productCategory) => {
      if (!productCategory.item.parentId) return;
      productCategoryMap.get(productCategory.item.parentId)?.children.set(productCategory.item.id, productCategory);
    });

    const getProductOptions = (
      productCategoryMap: Map<number, ProductCategoryWithChildren>,
      level = 0
    ): DefaultOptionType[] => {
      const options: DefaultOptionType[] = [];
      const items = Array.from(productCategoryMap.values());
      items.sort((item1, item2) => (item1.item.name > item2.item.name ? 1 : -1));
      items.forEach((item) => {
        if (ignoreValues.includes(item.item.id)) return;
        if (!level && item.item.parentId) return;
        options.push({ label: `${'-'.repeat(level)} ${item.item.name}`, value: item.item.id });
        options.push(...getProductOptions(item.children, level + 1));
      });
      return options;
    };

    return getProductOptions(productCategoryMap);
  }, [productCategories, ...ignoreValues]);

  useEffect(() => {
    fetchAllProductCategoryList({ reload: false });
  }, [fetchAllProductCategoryList]);

  return (
    <AntSelect
      {...restProps}
      filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
      loading={loadingProps || loading}
      options={options}
      placeholder={placeholder}
      allowClear={allowClear}
      showSearch={showSearch}
    />
  );
};
