import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { Filter } from './components/Filter';
import { Button } from 'antd';
import { CouponList } from './components/CouponList';
import { useNavigate, useParams } from 'react-router-dom';
import { CouponGroup } from '@/types/coupon';
import { paths } from '@/config/path';

export type CouponsPageProps = {};
export const CouponsPage: FC<CouponsPageProps> = () => {
  const navigate = useNavigate();
  const { group } = useParams<{ group: CouponGroup }>();
  return (
    <LayoutContent
      header={{
        leftElement: <Filter group={group} />,
        rightElement: (
          <Button
            type="primary"
            onClick={() =>
              navigate(`${paths.COUPON_ALL}/${group}/create`, {
                state: { prevPath: `${paths.COUPON_ALL}/${group}` },
              })
            }>
            Thêm mới
          </Button>
        ),
      }}>
      <CouponList group={group} />
    </LayoutContent>
  );
};
