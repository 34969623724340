import { Select } from '@/components/Select';
import { UploadAuto } from '@/components/Upload/UploadAuto';
import { AccountStatus, accountStatusNameMapping } from '@/types/account';
import { Col, Form, Input, Row } from 'antd';
import { FC } from 'react';

export type GeneralFormProps = {
  type: 'view' | 'new' | 'edit';
};
export const GeneralForm: FC<GeneralFormProps> = ({ type }) => {
  return (
    <Row gutter={40} wrap={false}>
      <Col style={{ width: 200 }}>
        <Form.Item name="avatarUrl" label="Avatar">
          <UploadAuto maxSize={2048} multiple={false} listType="picture-card" disabled={type === 'view'} />
        </Form.Item>
      </Col>
      <Col flex={1}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Số điện thoại"
              name="phone"
              rules={[
                { required: true, message: 'Số điện thoại bắt buộc' },
                { type: 'regexp', pattern: /^0\d{9}$/ },
              ]}>
              <Input placeholder="Số điện thoại" readOnly={type === 'view'} disabled={type === 'edit'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Trạng thái"
              name="status"
              initialValue={AccountStatus.active}
              rules={[{ required: true, message: 'Vui lòng chọn trạng thái' }]}>
              <Select
                readOnly={type === 'view'}
                options={Object.values(AccountStatus).map((accountStatus) => ({
                  label: accountStatusNameMapping[accountStatus],
                  value: accountStatus,
                }))}
                placeholder="Trạng thái"
              />
            </Form.Item>
          </Col>
          {type !== 'view' && (
            <Col span={12}>
              <Form.Item
                label="Mật khẩu"
                name="password"
                rules={
                  type === 'new'
                    ? [
                        { required: true, message: 'Mật khẩu bắt buộc' },
                        { min: 6, message: 'Mật khẩu quá ngắn' },
                      ]
                    : [{ min: 6, message: 'Mật khẩu quá ngắn' }]
                }>
                <Input.Password placeholder="Mật khẩu" />
              </Form.Item>
            </Col>
          )}
          {type !== 'view' && (
            <Col span={12}>
              <Form.Item
                label="Xác nhận mật khẩu"
                name="confirmPassword"
                rules={[
                  ...(type === 'new' ? [{ required: true, message: 'Mật khẩu bắt buộc' }] : []),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Mật khẩu không khớp!'));
                    },
                  }),
                ]}>
                <Input.Password placeholder="Mật khẩu" />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Không đúng định dạng email' }]}>
              <Input placeholder="Email" readOnly={type === 'view'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Họ và tên" name="fullName" rules={[{ required: true, message: 'Họ và tên bắt buộc' }]}>
              <Input placeholder="Họ và tên" readOnly={type === 'view'} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
