import { DatePicker } from '@/components/DatePicker';
import { DistrictSelect } from '@/components/Location/DistrictSelectComponent';
import { ProvinceSelect } from '@/components/Location/ProvinceSelectComponent';
import { WardSelect } from '@/components/Location/WardSelectComponent';
import { Select } from '@/components/Select';
import { UploadAuto } from '@/components/Upload/UploadAuto';
import { AccountType, accountTypenameMapping } from '@/types/account';
import { FILE_UPLOAD_TYPE } from '@/utils/helpers/validate';
import { Col, Divider, Form, Input, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FC } from 'react';

const MAIN_KEY = 'accountBusiness';

type BusinessFormProps = {
  type: 'view' | 'new' | 'edit';
};
export const BusinessForm: FC<BusinessFormProps> = ({ type }) => {
  return (
    <>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Loại nhà thuốc" name="accountType" initialValue={AccountType.Pharmacy}>
            <Select
              readOnly={type === 'view'}
              placeholder="Chọn loại nhà thuốc..."
              options={Object.values(AccountType).map((accountType) => ({
                value: accountType,
                label: accountTypenameMapping[accountType],
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={16}></Col>
        <Col span={8}>
          <Form.Item label="Tên nhà thuốc/phòng khám" name={[MAIN_KEY, 'name']}>
            <Input placeholder="Tên nhà thuốc/phòng khám" readOnly={type === 'view'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Tên người đại diện pháp luật" name={[MAIN_KEY, 'legalRepresentative']}>
            <Input placeholder="Tên người đại diện pháp luật" readOnly={type === 'view'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Mã số thuế" name={[MAIN_KEY, 'tax']}>
            <Input placeholder="Mã số thuế" readOnly={type === 'view'} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Địa chỉ nhà thuốc/phòng khám" name={[MAIN_KEY, 'street']}>
            <Input placeholder="Địa chỉ nhà thuốc/phòng khám" readOnly={type === 'view'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item noStyle dependencies={[[MAIN_KEY, 'provinceCode']]}>
            {(form) => {
              return (
                <Form.Item label="Tỉnh / Thành Phố" name={[MAIN_KEY, 'provinceCode']}>
                  <ProvinceSelect
                    placeholder="Tỉnh / Thành Phố"
                    allowClear
                    readOnly={type === 'view'}
                    onChange={() =>
                      form.setFieldsValue({ [MAIN_KEY]: { districtCode: undefined, wardCode: undefined } })
                    }
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            noStyle
            dependencies={[
              [MAIN_KEY, 'provinceCode'],
              [MAIN_KEY, 'districtCode'],
            ]}>
            {(form) => {
              const provinceCode = form.getFieldValue([MAIN_KEY, 'provinceCode']);
              return (
                <Form.Item label="Quận / Huyện" name={[MAIN_KEY, 'districtCode']}>
                  <DistrictSelect
                    placeholder="Quận / Huyện"
                    provinceCode={provinceCode}
                    disabled={!provinceCode}
                    allowClear
                    readOnly={type === 'view'}
                    onChange={() => form.setFieldsValue({ [MAIN_KEY]: { wardCode: undefined } })}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item noStyle dependencies={[[MAIN_KEY, 'districtCode']]}>
            {(form) => {
              const districtCode = form.getFieldValue([MAIN_KEY, 'districtCode']);
              return (
                <Form.Item label="Phường / Xã" name={[MAIN_KEY, 'wardCode']}>
                  <WardSelect
                    placeholder="Phường / Xã"
                    districtCode={districtCode}
                    disabled={!districtCode}
                    allowClear
                    readOnly={type === 'view'}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Divider>Giấy phép doanh nghiệp</Divider>
      <LicenseInput prefixName="businessLicense" readOnly={type === 'view'} />
      <Divider>Hồ sơ GDP/GPP/GSP</Divider>
      <LicenseInput prefixName="gdp" readOnly={type === 'view'} />
      <Divider>Giấy phép hoạt động khám, chữa bệnh</Divider>
      <LicenseInput prefixName="medicalLicense" readOnly={type === 'view'} />
    </>
  );
};

export type LicenseInputProps = {
  prefixName: string;
  readOnly?: boolean;
};
export const LicenseInput: FC<LicenseInputProps> = ({ readOnly, prefixName }) => {
  return (
    <Row gutter={40}>
      <Col style={{ width: 200 }}>
        <Form.Item name={[MAIN_KEY, `${prefixName}AttachmentUrls`]} label="File đính kèm">
          <UploadAuto
            disabled={readOnly}
            maxSize={10240}
            multiple
            listType="text"
            accept={[...FILE_UPLOAD_TYPE, 'application/pdf', 'application/vnd.ms-excel'].join(',')}
          />
        </Form.Item>
      </Col>
      <Col style={{ width: 'calc(100% - 200px)' }}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Số giấy phép" name={[MAIN_KEY, `${prefixName}Number`]}>
              <Input placeholder="Số giấy phép" readOnly={readOnly} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Nơi cấp" name={[MAIN_KEY, `${prefixName}PlaceOfIssue`]}>
              <ProvinceSelect placeholder="Nơi cấp" readOnly={readOnly} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Ngày cấp"
              name={[MAIN_KEY, `${prefixName}Date`]}
              getValueFromEvent={(value: Dayjs) => value?.format('YYYY-MM-DD')}
              getValueProps={(value) => ({ value: value && dayjs(value) })}>
              <DatePicker placeholder="Ngày cấp" readOnly={readOnly} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="Ngày hết hạn" name={[MAIN_KEY, `${prefixName}Date`]}>
              <DatePicker placeholder="Ngày hết hạn" />
            </Form.Item>
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
};
