import { LayoutContent } from '@/components/Layout';
import { FC, useEffect, useState } from 'react';
import { GeneralForm } from './components/GeneralForm';
import { Button, Form, Spin, message } from 'antd';
import { BusinessForm } from './components/BusinessForm';
import { BankForm } from './components/BankForm';
import { BillingForm } from './components/BillingForm';
import { ShippingForm } from './components/ShippingForm';
import { useNavigate, useParams } from 'react-router-dom';
import * as accountApi from '@/api/account';
import { AccountForm } from '@/types/account';
import { useAccountStore } from '@/stores/account';
import { paths } from '@/config/path';

type AccountCreationPageProps = {
  type: 'view' | 'new' | 'edit';
};
export const AccountCreationPage: FC<AccountCreationPageProps> = ({ type }) => {
  const params = useParams<{ id?: string }>();
  const id = type === 'new' ? undefined : parseInt(params.id);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const { createAccount, updateAccount } = useAccountStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    accountApi
      .fetchAccount(id)
      .then((data) => {
        form.setFieldsValue(data);
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const onFinish = async (values: AccountForm) => {
    if (type === 'new') message.loading('Đang thêm mới...');
    if (type === 'edit') message.loading('Đang cập nhật...');
    try {
      values.avatarUrl = typeof values.avatarUrl === 'string' ? values.avatarUrl : null;

      values.accountBusiness.businessLicenseAttachmentUrls = (
        values.accountBusiness.businessLicenseAttachmentUrls || []
      ).filter((image) => typeof image === 'string');

      values.accountBusiness.gdpAttachmentUrls = (values.accountBusiness.gdpAttachmentUrls || []).filter(
        (image) => typeof image === 'string'
      );

      values.accountBusiness.medicalLicenseAttachmentUrls = (
        values.accountBusiness.medicalLicenseAttachmentUrls || []
      ).filter((image) => typeof image === 'string');

      if (type === 'edit') {
        if (!values.password) delete values.password;
        delete values.confirmPassword;
      }
      if (type === 'new') await createAccount(values);
      else if (type === 'edit') await updateAccount(id, values);
      message.destroy();
      if (type === 'new') message.success('Tạo mới thành công');
      if (type === 'edit') message.success('Cập nhật thành công');
      navigate(paths.ACCOUNT, { replace: true });
    } catch (error: any) {
      message.destroy();
      if (error?.response?.data?.name === 'phone_is_exist') {
        form.setFields([
          {
            name: 'phone',
            errors: ['Số điện thoại đã tồn tại!'],
          },
        ]);
        message.error('Số điện thoại đã tồn tại!');
      } else if (error?.response?.data?.name === 'email_is_exist') {
        form.setFields([
          {
            name: 'email',
            errors: ['Email đã tồn tại!'],
          },
        ]);
        message.error('Email đã tồn tại!');
      } else {
        if (type === 'new') message.error('Tạo mới không thành công');
        if (type === 'edit') message.error('Cập nhật không thành công');
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <Form labelCol={{ span: 24 }} form={form} onFinish={onFinish}>
        <LayoutContent
          actions={
            <>
              {type === 'edit' && (
                <Button type="primary" htmlType="submit">
                  Cập nhật
                </Button>
              )}
              {type === 'new' && (
                <Button type="primary" htmlType="submit">
                  Thêm mới
                </Button>
              )}
            </>
          }
          sections={[
            {
              key: 'general',
              title: 'Thông tin cơ bản',
              content: <GeneralForm type={type} />,
            },
            {
              key: 'business',
              title: 'Thông tin doanh nghiệp',
              content: <BusinessForm type={type} />,
            },
            {
              key: 'bank',
              title: 'Thông tin tài khoản ngân hàng',
              content: <BankForm type={type} />,
            },
            {
              key: 'billing',
              title: 'Thông tin xuất hóa đơn',
              content: <BillingForm type={type} />,
            },
            {
              key: 'shipping',
              title: 'Thông tin giao hàng',
              content: <ShippingForm type={type} />,
            },
          ]}
        />
      </Form>
    </Spin>
  );
};
