import { useProductManufacturerCreationModalStore, useProductManufacturerStore } from '@/stores/productManufacturer';
import { ProductManufacturerModalForm } from '@/types/productManufacturer';
import { Button, Form, Input, Modal, message } from 'antd';
import { FC, useEffect } from 'react';

export const ProductManufacturerCreationModal: FC = () => {
  const [form] = Form.useForm();
  const { createProductManufacturer, updateProductManufacturer } = useProductManufacturerStore();
  const { loading, initial, open, closeModal } = useProductManufacturerCreationModalStore();

  useEffect(() => {
    if (initial) form.setFieldsValue(initial);
  }, [initial]);

  const onFinish = async (value: ProductManufacturerModalForm) => {
    try {
      // Upload Record
      if (initial) {
        await updateProductManufacturer(initial.id, value);
      } else {
        await createProductManufacturer(value);
      }
      message.success(initial ? 'Cập nhật Nhà sản xuất thành công' : 'Tạo mới Nhà sản xuất thành công');
      closeModal();
      form.resetFields();
    } catch (error: any) {
      if (error?.response?.data?.name === 'name_is_exist') {
        form.setFields([
          {
            name: 'name',
            errors: ['Nhà sản xuất đã tồn tại!'],
          },
        ]);
      } else {
        message.error(initial ? 'Cập nhật Nhà sản xuất không thành công' : 'Tạo mới Nhà sản xuất không thành công');
      }
    }
  };

  const onClose = () => {
    closeModal();
    form.resetFields();
  };

  return (
    <Modal
      title={initial ? 'Cập nhật Nhà sản xuất' : 'Tạo mới Nhà sản xuất'}
      open={open}
      cancelText="Hủy"
      okText={initial ? 'Cập nhật' : 'Tạo mới'}
      onCancel={onClose}
      onOk={form.submit}
      maskClosable={false}
      okButtonProps={{ loading }}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <Form.Item
          label="Tên Nhà sản xuất"
          name="name"
          rules={[{ required: true, message: 'Tên Nhà sản xuất bắt buộc' }]}>
          <Input placeholder="Tên Nhà sản xuất" />
        </Form.Item>
        <div style={{ display: 'none' }}>
          <Button htmlType="submit" hidden></Button>
        </div>
      </Form>
    </Modal>
  );
};
