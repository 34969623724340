import { LayoutContent } from '@/components/Layout';
import { Button, Col, Divider, Form, Input, Row, Spin, UploadFile, message } from 'antd';
import { Upload, validateMultiImages, validateSingleImage } from '@/components/Upload';
import { FC, useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { TextEditor } from '@/components/TextEditor';
import { appConfigValueSelector, useAppConfigStore } from '@/stores/appConfig';
import { AppConfigKey } from '@/types/appConfig';
import { uploadFile } from '@/api/file';

export const HomePage: FC = () => {
  const [form] = Form.useForm();

  const configString = useAppConfigStore(appConfigValueSelector(AppConfigKey.HOME_PAGE));
  const { loading, submitting, fetchManyAppConfigs, updateManyAppConfigs } = useAppConfigStore();

  useEffect(() => {
    if (!configString) return;
    try {
      const config = JSON.parse(configString);
      form.setFieldsValue(config);
    } catch (error) {
      console.log(error);
    }
  }, [configString, form]);

  useEffect(() => {
    fetchManyAppConfigs({ keys: [AppConfigKey.HOME_PAGE] });
  }, [fetchManyAppConfigs]);

  const uploadImages = async (urls: (UploadFile | string)[]) => {
    let imageUrls: string[] = [];
    if (urls?.length) {
      imageUrls = await Promise.all(
        urls.map(async (imageUrl) => {
          if (typeof imageUrl === 'string') {
            return imageUrl;
          }

          if (!imageUrl?.originFileObj) {
            return imageUrl?.thumbUrl || '';
          }

          const imageRes = await uploadFile(imageUrl.originFileObj);
          return imageRes.url;
        })
      );
    }
    return imageUrls;
  };

  const onFinish = async (values: any) => {
    try {
      message.loading('Đang cập nhật...');

      values.banner.imageUrls = await uploadImages(values.banner.imageUrls);
      form.setFieldValue(['banner', 'imageUrls'], values.banner.imageUrls);

      values.ads.items = values.ads.items || [];
      const adsImageUrls = await uploadImages(values.ads.items.map((item: any) => item.imageUrl));
      values.ads.items = values.ads.items.map((item: any, index: number) => ({
        ...item,
        imageUrl: adsImageUrls[index],
      }));
      form.setFieldValue(['ads', 'items'], values.ads.items);

      values.about.imageUrl1 = await uploadImages([values.about.imageUrl1]).then((items) => items?.[0]);
      values.about.imageUrl2 = await uploadImages([values.about.imageUrl2]).then((items) => items?.[0]);
      values.about.imageUrl3 = await uploadImages([values.about.imageUrl3]).then((items) => items?.[0]);

      values.contact.info1.items = await Promise.all(
        (values.contact.info1.items || []).map(async (item: any) => {
          if (!item.imageUrl || typeof item.imageUrl === 'string') return item;
          const imageRes = await uploadFile(item.imageUrl.originFileObj);
          item.imageUrl = imageRes.url;
          return item;
        })
      );
      values.contact.info2.items = await Promise.all(
        (values.contact.info2.items || []).map(async (item: any) => {
          if (!item.imageUrl || typeof item.imageUrl === 'string') return item;
          const imageRes = await uploadFile(item.imageUrl.originFileObj);
          item.imageUrl = imageRes.url;
          return item;
        })
      );

      values.partner.imageUrls = await uploadImages(values.partner.imageUrls);

      await updateManyAppConfigs({ appConfigs: [{ key: AppConfigKey.HOME_PAGE, value: JSON.stringify(values) }] });
      message.destroy();
      message.success('Cập nhật thành công');
    } catch (error) {
      console.log(error);
      message.destroy();
      message.error('Đã có lỗi xảy ra');
    }
  };

  return (
    <Spin spinning={false}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <LayoutContent
          actions={
            <Button type="primary" htmlType="submit" disabled={submitting || loading} loading={submitting}>
              Cập nhật
            </Button>
          }
          sections={[
            {
              key: 'banner',
              title: 'Banner',
              content: (
                <>
                  <Form.Item
                    label="Hình ảnh"
                    name={['banner', 'imageUrls']}
                    rules={[{ validator: validateMultiImages({ maxSize: 512, required: true }) }]}>
                    <Upload maxSize={512} multiple={true} listType="picture-card" />
                  </Form.Item>
                </>
              ),
            },
            {
              key: 'ads',
              title: 'Khuyến mãi & Trả thưởng',
              content: (
                <Form.Item label="Hình ảnh">
                  <Form.List name={['ads', 'items']}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Row gutter={32} key={key}>
                            <Col span={1}>
                              <MinusCircleOutlined style={{ paddingTop: 8 }} onClick={() => remove(name)} />
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...restField}
                                name={[name, 'imageUrl']}
                                rules={[{ validator: validateSingleImage({ maxSize: 512, required: true }) }]}>
                                <Upload maxSize={512} multiple={false} listType="picture-card" />
                              </Form.Item>
                            </Col>
                            <Col span={15}>
                              <Form.Item {...restField} name={[name, 'link']}>
                                <Input placeholder="Đường dẫn" />
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                        {fields.length <= 4 && (
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Add field
                            </Button>
                          </Form.Item>
                        )}
                      </>
                    )}
                  </Form.List>
                </Form.Item>
              ),
            },
            {
              key: 'contact',
              title: 'Hướng dẫn',
              content: (
                <>
                  <h3>4 bước đăng ký tài khoản</h3>
                  <Form.Item label="Tiêu đề" name={['contact', 'info1', 'title']}>
                    <Input placeholder="Tiêu đề" />
                  </Form.Item>
                  <ContactSlider name={['contact', 'info1', 'items']} />
                  <Divider />
                  <h3>4 bước xử lý đơn hàng</h3>{' '}
                  <Form.Item label="Tiêu đề" name={['contact', 'info2', 'title']}>
                    <Input placeholder="Tiêu đề" />
                  </Form.Item>
                  <ContactSlider name={['contact', 'info2', 'items']} />
                </>
              ),
            },
            {
              key: 'partner',
              title: 'Đối tác',
              content: (
                <>
                  <Form.Item
                    name={['partner', 'title']}
                    label="Tiêu đề"
                    rules={[{ required: true, message: 'Nội dung bắt buộc' }]}>
                    <Input placeholder="Tiêu đề" />
                  </Form.Item>
                  <Form.Item label="Hình ảnh">
                    <Form.List name={['partner', 'items']}>
                      {(fields, { add, remove }) => (
                        <>
                          <Row gutter={16}>
                            {fields.map(({ key, name, ...restField }) => (
                              <Col span={12} key={key}>
                                <Row gutter={8} wrap={false}>
                                  <Col>
                                    <Button type="link" danger shape="circle" onClick={() => remove(name)}>
                                      <MinusCircleOutlined />
                                    </Button>
                                  </Col>
                                  <Col flex={1}>
                                    <Row gutter={24} wrap={false}>
                                      <Col>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'imageUrl']}
                                          rules={[
                                            { validator: validateSingleImage({ maxSize: 512, required: true }) },
                                          ]}>
                                          <Upload maxSize={512} multiple={false} listType="picture-card" />
                                        </Form.Item>
                                      </Col>
                                      <Col style={{ flex: 7 }}>
                                        <Form.Item {...restField} name={[name, 'url']}>
                                          <Input placeholder="URL" />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          </Row>
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Thêm hình
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                </>
              ),
            },
            {
              key: 'about',
              title: 'Giới thiệu về công ty',
              content: (
                <>
                  <Form.Item
                    name={['about', 'imageUrl1']}
                    label="Hình ảnh mạng lưới"
                    rules={[{ validator: validateSingleImage({ maxSize: 512, required: true }) }]}>
                    <Upload maxSize={512} multiple={false} listType="picture-card" />
                  </Form.Item>
                  <Form.Item
                    name={['about', 'content1']}
                    label="Mạng lưới"
                    rules={[{ required: true, message: 'Nội dung bắt buộc' }]}>
                    <TextEditor placeholder="Nội dung" type="basic" />
                  </Form.Item>
                  <Form.Item
                    name={['about', 'imageUrl2']}
                    label="Hình ảnh tầm nhìn"
                    rules={[{ validator: validateSingleImage({ maxSize: 512, required: true }) }]}>
                    <Upload maxSize={512} multiple={false} listType="picture-card" />
                  </Form.Item>
                  <Form.Item
                    name={['about', 'content2']}
                    label="Tầm nhìn"
                    rules={[{ required: true, message: 'Nội dung bắt buộc' }]}>
                    <TextEditor placeholder="Nội dung" type="basic" />
                  </Form.Item>
                  <Form.Item
                    name={['about', 'imageUrl3']}
                    label="Hình ảnh sứ mệnh"
                    rules={[{ validator: validateSingleImage({ maxSize: 512, required: true }) }]}>
                    <Upload maxSize={512} multiple={false} listType="picture-card" />
                  </Form.Item>
                  <Form.Item
                    name={['about', 'content3']}
                    label="Sứ mệnh"
                    rules={[{ required: true, message: 'Nội dung bắt buộc' }]}>
                    <TextEditor placeholder="Nội dung" type="basic" />
                  </Form.Item>
                </>
              ),
            },
            {
              key: 'qa',
              title: 'Câu hỏi thường gặp',
              content: (
                <Form.List name={['qa', 'items']}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row gutter={32} key={key}>
                          <Col span={1}>
                            <MinusCircleOutlined style={{ paddingTop: 8 }} onClick={() => remove(name)} />
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'title']}
                              rules={[{ required: true, message: 'Tiêu đề bắt buộc' }]}>
                              <Input placeholder="Tiêu đề" />
                            </Form.Item>
                          </Col>
                          <Col span={15}>
                            <Form.Item
                              {...restField}
                              name={[name, 'content']}
                              rules={[{ required: true, message: 'Nội dung bắt buộc' }]}>
                              <TextEditor placeholder="Nội dung" type="basic" />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ),
            },
          ]}
        />
      </Form>
    </Spin>
  );
};

const ContactSlider: FC<{ name: string[] }> = ({ name }) => {
  return (
    <Form.Item label="Slider">
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row gutter={8} key={key} wrap={false}>
                <Col>
                  <Button type="link" danger shape="circle" onClick={() => remove(name)}>
                    <MinusCircleOutlined />
                  </Button>
                </Col>
                <Col flex={1}>
                  <Row gutter={24} wrap={false}>
                    <Col style={{ flex: 4 }}>
                      <Form.Item
                        {...restField}
                        name={[name, 'title']}
                        rules={[{ required: true, message: 'Tiêu đề bắt buộc' }]}>
                        <Input placeholder="Tiêu đề" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        {...restField}
                        name={[name, 'imageUrl']}
                        rules={[{ validator: validateSingleImage({ maxSize: 512, required: true }) }]}>
                        <Upload maxSize={512} multiple={false} listType="picture-card" />
                      </Form.Item>
                    </Col>
                    <Col style={{ flex: 7 }}>
                      <Form.Item
                        {...restField}
                        name={[name, 'content']}
                        rules={[{ required: true, message: 'Nội dung bắt buộc' }]}>
                        <TextEditor placeholder="Nội dung" type="basic" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Thêm slide
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};
