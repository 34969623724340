import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { AccountList } from './components/AccountList';
import { Filter } from './components/Filter';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/config/path';
import { exportAccounts } from '@/api/account';

export const AccountsPage: FC = () => {
  const navigate = useNavigate();
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <>
            <Button
              onClick={() =>
                Modal.confirm({
                  content: 'Bạn có muốn xuất danh sách người dùng?',
                  okText: 'Xuất',
                  onOk: exportAccounts,
                })
              }>
              Xuất
            </Button>
            <Button type="primary" onClick={() => navigate(paths.ACCOUNT_CREATE)}>
              Thêm mới
            </Button>
          </>
        ),
      }}>
      <AccountList />
    </LayoutContent>
  );
};
