import { FC, useEffect } from 'react';
import { useLocationStore } from '@/stores/location';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { Select, SelectProps } from '../Select';

export type ProvinceSelectProps = Omit<SelectProps, 'options'>;
export const ProvinceSelect: FC<ProvinceSelectProps> = ({ value, onChange, ...props }) => {
  const { provinceLoading, provinces, loadProvinces } = useLocationStore();
  useEffect(() => {
    loadProvinces();
  }, [loadProvinces]);
  return (
    <Select
      showSearch
      filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
      options={provinces.map((item) => ({ value: item.code, label: item.name }))}
      {...props}
      loading={provinceLoading || props.loading}
      value={provinces.length ? (value as any) : undefined}
      onChange={onChange}
    />
  );
};
