import { ProductItem } from './product';

export enum ProductPromotionType {
  buyOneGetOne = 'buy_one_get_one',
  buyTwoGetOne = 'buy_two_get_one',
  buyOneDiscountOne = 'buy_one_discount_one',
}

export const productPromotionTypeNameMapping: Record<ProductPromotionType, string> = {
  [ProductPromotionType.buyOneGetOne]: 'Mua 1 tặng 1',
  [ProductPromotionType.buyTwoGetOne]: 'Mua 2 tặng 1',
  [ProductPromotionType.buyOneDiscountOne]: 'Mua 1 giảm giá 1',
};

export type ProductPromotionBuyOneGetOne = {
  id: number;
  productId: number;
  product: ProductItem;
};
export type ProductPromotionBuyTwoGetOne = {
  id: number;
  productId: number;
  product: ProductItem;
};
export type ProductPromotionBuyOneDiscountOne = {
  id: number;
  productId: number;
  product: ProductItem;
  discountPrice: number;
};
export type ProductPromotion = {
  id: number;
  productId: number;
  product: ProductItem;
  type: ProductPromotionType;
  buyOneGetOne?: ProductPromotionBuyOneGetOne;
  buyTwoGetOne?: ProductPromotionBuyTwoGetOne;
  buyOneDiscountOne?: ProductPromotionBuyOneDiscountOne;
  createdAt: Date;
  updatedAt: Date;
};

export type UpsertProductPromotionBuyOneGetOneInput = {
  productId: number;
};
export type UpsertProductPromotionBuyTwoGetOneInput = {
  productId: number;
};
export type UpsertProductPromotionBuyOneDiscountOneInput = {
  productId: number;
  discountPrice: number;
};
export type UpsertProductPromotionInput = {
  type: ProductPromotionType;
  buyOneGetOne?: UpsertProductPromotionBuyOneGetOneInput;
  buyTwoGetOne?: UpsertProductPromotionBuyTwoGetOneInput;
  buyOneDiscountOne?: UpsertProductPromotionBuyOneDiscountOneInput;
};
