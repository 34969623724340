import axios from 'axios'
import { useAuthStore } from '@/stores/auth'
import { paths } from '@/config/path'

const baseApi = axios.create()

baseApi.defaults.baseURL = process.env.REACT_APP_BASE_API
baseApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error.response
    if (response.status !== 401) return Promise.reject(error)
    useAuthStore.getState().logout()
    window.location.replace(paths.LOGIN)
    return Promise.reject(error)
  }
)

const setDefaultToken = (token: string) => {
  baseApi.defaults.headers.Authorization = `Bearer ${token}`
}

const removeDefaultToken = () => {
  delete baseApi.defaults.headers.Authorization
}

export { baseApi, setDefaultToken, removeDefaultToken }
