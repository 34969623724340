import { appConfigValuesSelector, useAppConfigStore } from '@/stores/appConfig';
import { AppConfig, AppConfigKey } from '@/types/appConfig';
import { FormInstance, message } from 'antd';
import { useEffect, useMemo } from 'react';

type Type = 'json' | 'number' | 'string' | 'boolean';
export type AppConfigType = {
  key: AppConfigKey;
  type: Type;
  default: any;
};

type Options = {
  form: FormInstance<any>;
  types: AppConfigType[];
};

export const useThemeOption = ({ form, types }: Options) => {
  const keys = useMemo(() => types.map((type) => type.key), [types]);
  const initialValues: any = useAppConfigStore(appConfigValuesSelector(keys));
  const { loading, submitting, fetchManyAppConfigs, updateManyAppConfigs } = useAppConfigStore();

  useEffect(() => {
    if (!initialValues) return;
    form.setFieldsValue(parseValues(initialValues, types));
  }, [initialValues, form]);

  useEffect(() => {
    fetchManyAppConfigs({
      keys,
    });
  }, [fetchManyAppConfigs, keys]);

  const onFinish = async (values: any) => {
    try {
      message.loading('Đang cập nhật...');
      const appConfigsObject = reverseValues(values, types);
      console.log(values, types, appConfigsObject)
      const appConfigs = Object.keys(appConfigsObject).map((key) => ({
        key,
        value: appConfigsObject[key],
      })) as Array<AppConfig>;
      await updateManyAppConfigs({
        appConfigs,
      });
      message.destroy();
      message.success('Cập nhật thành công');
    } catch (error) {
      message.destroy();
      message.error('Đã có lỗi xảy ra');
    }
  };

  return { loading, submitting, onFinish };
};

const parseValues = (values: Record<string, any>, appConfigTypes: AppConfigType[]) => {
  const newValues = { ...values };
  appConfigTypes.forEach((appConfigType) => {
    newValues[appConfigType.key] = parseValue(newValues[appConfigType.key], appConfigType.type, appConfigType.default);
  });
  return newValues;
};

const parseValue = (value: string, type: Type, defaultValue: any) => {
  if (type === 'string') {
    return value || defaultValue;
  }
  if (type === 'number') {
    try {
      if (!value) return defaultValue;
      return parseInt(value);
    } catch (error) {
      return defaultValue;
    }
  }
  if (type === 'boolean') {
    if (!value) return defaultValue;
    return value === 'true' || value === '1';
  }
  if (type === 'json') {
    try {
      if (!value) return defaultValue;
      return JSON.parse(value);
    } catch (error) {
      return defaultValue;
    }
  }
};

const reverseValues = (values: Record<string, any>, appConfigTypes: AppConfigType[]) => {
  const newValues = { ...values };
  appConfigTypes.forEach((appConfigType) => {
    newValues[appConfigType.key] = reverseValue(newValues[appConfigType.key], appConfigType.type);
  });
  return newValues;
};

const reverseValue = (value: any, type: Type): string => {
  if (type === 'string') {
    return value || '';
  }
  if (type === 'number') {
    if (!value) return '';
    return value + '';
  }
  if (type === 'boolean') {
    return value ? '1' : '0';
  }
  if (type === 'json') {
    return JSON.stringify(value);
  }
  return '';
};
