import { FC } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const MAIN_KEY = 'accountBills';

type BillingFormProps = {
  type: 'view' | 'new' | 'edit';
};
export const BillingForm: FC<BillingFormProps> = ({ type }) => {
  return (
    <Form.List name={MAIN_KEY}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} gutter={24}>
              <Col flex={1}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      name={[name, 'companyName']}
                      label={!index ? 'Tên công ty' : undefined}
                      rules={[{ required: true, message: 'Tên công ty là bắt buộc' }]}>
                      <Input placeholder="Tên công ty" readOnly={type === 'view'} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      name={[name, 'tax']}
                      label={!index ? 'Mã số thuế' : undefined}
                      rules={[{ required: true, message: 'Mã số thuế là bắt buộc' }]}>
                      <Input placeholder="Mã số thuế" readOnly={type === 'view'} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      name={[name, 'address']}
                      label={!index ? 'Địa chỉ' : undefined}
                      rules={[{ required: true, message: 'Địa chỉ là bắt buộc' }]}>
                      <Input placeholder="Địa chỉ" readOnly={type === 'view'} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {type !== 'view' && (
                <Col>
                  <div style={{ height: !index ? 40 : 0 }}></div>
                  <Button type="link" danger shape="circle" onClick={() => remove(name)}>
                    <MinusCircleOutlined />
                  </Button>
                </Col>
              )}
            </Row>
          ))}
          {type !== 'view' && (
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Thêm mới
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );
};
