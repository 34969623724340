import { FC, useEffect, useMemo } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { validateFileSize } from '@/utils/helpers/validate';
import { message } from 'antd';
import { uploadFile } from '@/api/file';

const apiKeys = [
  'xzkvgxyh36riagjcahyl2bvwomxwibhyymjy3jcdfk2bd863', // vanhuy.hcmus@gmail.com
  'z8ljj5utlv66fu06zsu9hhb6sf8pzp3wwdaqj6chtlgtmxji', // alohuynguyen@gmail.com
  '9ie1k00g9j3d6ibmqo6t7pvvnbzbad0vq8568m269vosqtgx', // vanhuy12toan2@gmail.com
  // '1pycs5nurbvbh606t5i13hdpfmkchf16zy5mmdljm8habxxu', // Github
];
const apiKey = apiKeys[Math.floor(Math.random() * apiKeys.length)];

export type TextEditorProps = {
  value?: string;
  onChange?: (value: string, editor: TinyMCEEditor) => void;
  placeholder?: string;
  type?: 'normal' | 'basic';
};
export const TextEditor: FC<TextEditorProps> = ({ value, onChange, placeholder, type = 'normal' }) => {
  const init = useMemo(() => {
    if (type === 'basic') {
      return {
        menubar: false,
        toolbar:
          'undo redo | blocks | bold italic underline strikethrough | link | align lineheight | tinycomments | checklist numlist bullist indent outdent | fullscreen removeformat',
      };
    }
    return {
      toolbar:
        'undo redo | blocks | bold italic underline strikethrough | link image table | align lineheight | tinycomments | checklist numlist bullist indent outdent | fullscreen removeformat',
    };
  }, [type]);

  useEffect(() => {
    if ('MutationObserver' in window) {
      const trigger = () => {
        document.querySelectorAll('.tox-notifications-container').forEach((element) => element.remove());
      };
      const observer = new MutationObserver(trigger);
      observer.observe(document.body, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <Editor
      apiKey={apiKey}
      init={{
        plugins: 'anchor autoresize fullscreen autolink image link lists table',
        table_toolbar:
          'tableprops tabledelete | tablecellprops tablemergecells tablesplitcells | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        content_css: false,
        content_style: contentStyle,
        placeholder: placeholder,
        file_picker_types: 'file image media',
        images_upload_handler: async (blobInfo, progress) => {
          if (!validateFileSize(blobInfo.blob(), 500)) {
            message.error('Hình ảnh bị giới hạn dung lượng 500kB!');
            return;
          }
          const fileUploaded = await uploadFile(blobInfo.blob(), (event) => {
            progress(event.progress || 0);
          });
          return fileUploaded.url;
        },
        min_height: 300,
        ...init,
      }}
      value={value}
      onEditorChange={onChange}
    />
  );
};

const contentStyle = `
	body {
		font-size: 14px;
	}
`;
