import dayjs from 'dayjs';

export enum DiscountType {
  exactTotalPrice = 'exact_total_price',
  percentTotalPrice = 'percent_total_price',
}

export enum CouponGroup {
  promotion = 'promotion',
  accountRank = 'account_rank',
  bountyProgram = 'bounty_program',
}

export enum CouponStatus {
  active = 'active',
  inactive = 'inactive',
}

export type CouponItem = {
  id: number;
  code: string;
  group: CouponGroup;
  name: string;
  discountType: DiscountType;
  discount: number;
  maxPrice?: number;
  status: CouponStatus;
  expireDate: string;
  isExpiredDate: boolean;
  quantityPerUser: number;
  quantity: number;
  appliedQuantity: number;
  conditionMaxTotalPrice?: number;
  conditionMinTotalPrice?: number;
  applyAllUser: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type Coupon = CouponItem;

export type CouponCreationForm = {
  code: string;
  name: string;
  discountType: DiscountType;
  discount: number;
  maxPrice?: number;
  status: CouponStatus;
  expireDate: dayjs.Dayjs;
  quantityPerUser: number;
  quantity: number;
  conditionMaxTotalPrice?: number;
  conditionMinTotalPrice?: number;
  applyAllUser: boolean;
};

export type CouponQuery = {
  group?: CouponGroup;
  search?: string;
  status?: CouponStatus;
  fromExpireDate?: Date;
  toExpireDate?: Date;
  sort?: string;
  page?: number;
  limit?: number;
};

export type CouponResponse = {
  records: CouponItem[];
  metadata: {
    total: number;
  };
};

export type CreateCouponRequest = {
  code: string;
  group: CouponGroup;
  name: string;
  discountType: DiscountType;
  discount: number;
  maxPrice?: number;
  status: CouponStatus;
  expireDate: string;
  quantityPerUser: number;
  quantity: number;
  conditionMaxTotalPrice?: number;
  conditionMinTotalPrice?: number;
  applyAllUser: boolean;
};
export type UpdateCouponRequest = {
  code?: string;
  name?: string;
  discountType?: DiscountType;
  discount?: number;
  maxPrice?: number;
  status?: CouponStatus;
  expireDate?: string;
  quantityPerUser?: number;
  quantity?: number;
  conditionMaxTotalPrice?: number;
  conditionMinTotalPrice?: number;
  applyAllUser: boolean;
};
