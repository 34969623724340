import { CouponStatus } from '@/types/coupon';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { FC } from 'react';

export type CouponStatusSelectProps = AntSelectProps;
export const CouponStatusSelect: FC<CouponStatusSelectProps> = (props) => {
  const { placeholder = 'Trạng thái', ...restProps } = props;

  return (
    <AntSelect
      {...restProps}
      filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
      options={[
        { label: 'Active', value: CouponStatus.active },
        { label: 'Inactive', value: CouponStatus.inactive },
      ]}
      placeholder={placeholder}
    />
  );
};
