import { create } from 'zustand';
import * as accountApi from '@/api/account';
import { Account, AccountItem, FetchAccountListQuery, UpdateAccountRequest } from '@/types/account';

export type AccountState = {
  loading: boolean;
  loaded: boolean;
  query: FetchAccountListQuery;
  total: number;
  items: AccountItem[];
  submitting: boolean;
  deleting: boolean;
  fetchAccountList: (query?: Partial<FetchAccountListQuery>) => Promise<void>;
  updateAccount: (id: number, input: UpdateAccountRequest) => Promise<Account>;
  createAccount: (input: UpdateAccountRequest) => Promise<Account>;
  deleteAccount: (id: number) => Promise<void>;
};
export const useAccountStore = create<AccountState>((set, get) => ({
  loading: false,
  loaded: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  submitting: false,
  deleting: false,
  fetchAccountList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const res = await accountApi.fetchAccountList(query);
      set({
        loading: false,
        loaded: true,
        total: res.metadata.total,
        items: res.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  updateAccount: async (id, update) => {
    try {
      set({ submitting: true });
      const account = await accountApi.updateAccount(id, update);
      if (get().loaded) get().fetchAccountList();
      if (useAccountSelectionStore.getState().loaded)
        useAccountSelectionStore.getState().fetchAllAccountList({ reload: true });
      return account;
    } finally {
      set({ submitting: false });
    }
  },
  createAccount: async (input) => {
    try {
      set({ submitting: true });
      const account = await accountApi.createAccount(input);
      if (get().loaded) get().fetchAccountList();
      if (useAccountSelectionStore.getState().loaded)
        useAccountSelectionStore.getState().fetchAllAccountList({ reload: true });
      return account;
    } finally {
      set({ submitting: false });
    }
  },
  deleteAccount: async (id) => {
    try {
      set({ deleting: true });
      await accountApi.deleteAccount(id);
      if (get().loaded) get().fetchAccountList();
      if (useAccountSelectionStore.getState().loaded)
        useAccountSelectionStore.getState().fetchAllAccountList({ reload: true });
    } finally {
      set({ deleting: false });
    }
  },
}));

export type AccountSelectionState = {
  loading: boolean;
  loaded: boolean;
  items: AccountItem[];
  fetchAllAccountList: (options?: { reload?: boolean }) => Promise<void>;
};
export const useAccountSelectionStore = create<AccountSelectionState>((set, get) => ({
  loading: false,
  loaded: false,
  items: [],
  fetchAllAccountList: async ({ reload = true } = {}) => {
    if (!reload && get().loaded) return;
    try {
      set({ loading: true });
      const AccountRes = await accountApi.fetchAccountList({
        page: 1,
        limit: 1000,
      });
      set({ loading: false, loaded: true, items: AccountRes.records || [] });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
}));
