import { useProductCompositionCreationModalStore, useProductCompositionStore } from '@/stores/productComposition';
import { ProductCompositionItem, ProductCompositionQuery } from '@/types/productComposition';
import { serializeSorter } from '@/utils/helpers/serializeSorter';
import { blue } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useEffect } from 'react';
import format from 'date-fns/format';

export const ProductCompositionList = () => {
  const { loading, query, total, items, fetchProductCompositionList, deleteProductComposition } =
    useProductCompositionStore();
  const { openModalWithFetch } = useProductCompositionCreationModalStore();

  useEffect(() => {
    fetchProductCompositionList().catch(() => message.error('Lỗi không thể tải danh sách Thành phần', 3));
  }, []);

  const onDeleteProductComposition = async (manufacturerId: number) => {
    Modal.confirm({
      title: 'Xóa Thành phần',
      content: 'Bạn có muốn xóa Thành phần này không?',
      onOk: async () => {
        try {
          await deleteProductComposition(manufacturerId);
          message.success('Xóa Thành phần thành công');
        } catch (error: any) {
          message.error('Lỗi không thể xóa Thành phần này!');
        }
      },
    });
  };

  const columns: ColumnProps<ProductCompositionItem>[] = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   width: 60,
    //   sorter: {
    //     multiple: 1,
    //   },
    // },
    {
      title: 'Tên',
      dataIndex: 'name',
      sorter: {
        multiple: 2,
      },
      render: (_, record) => (
        <>
          <div>{record.name}</div>
          <a href={`${process.env.REACT_APP_SITE_DOMAIN}/thuong-hieu/${record.slug}`} target="_blank" rel="noreferrer">
            {record.slug}
          </a>
        </>
      ),
    },
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      width: 150,
      sorter: {
        multiple: 4,
      },
      render: (createdAt) => (createdAt ? format(new Date(createdAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      width: 150,
      sorter: {
        multiple: 5,
      },
      render: (updatedAt) => (updatedAt ? format(new Date(updatedAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              shape="circle"
              title="Edit"
              onClick={async () => {
                message.loading('Đang tải...', 0);
                try {
                  await openModalWithFetch(record.id);
                  message.destroy();
                } catch (error) {
                  message.destroy();
                  message.error('Tải thất bại');
                }
              }}>
              <EditOutlined twoToneColor={blue.primary} />
            </Button>
            <Button
              danger
              type="link"
              shape="circle"
              title="Delete"
              onClick={() => onDeleteProductComposition(record.id)}>
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  const onChange = useCallback((pagination: any, _: any, sorter: any) => {
    const newParams: ProductCompositionQuery = {
      page: pagination.current || query.page,
      limit: pagination.pageSize || query.limit,
      sort: serializeSorter(sorter),
    };
    fetchProductCompositionList(newParams);
  }, []);

  return (
    <Table
      rowKey={(item) => item.id}
      loading={loading}
      columns={columns}
      dataSource={items}
      onChange={onChange}
      pagination={{
        current: query.page,
        pageSize: query.limit,
        total: total,
      }}
    />
  );
};
