import { LoginPage } from '@/pages/Login';
import { ProductsPage } from '@/pages/Products';
import { ProductCategoriesPage } from '@/pages/ProductCategories';
import { ProductIndicationsPage } from '@/pages/ProductIndications';
import { UserPage } from '@/pages/User';
import { RouteItem } from '@/types/route';
import { paths } from './path';
import { ProductUsingsPage } from '@/pages/ProductUsings';
import { ProductBrandsPage } from '@/pages/ProductBrands';
import { ProductManufacturersPage } from '@/pages/ProductManufacturers';
import { ProductCompositionsPage } from '@/pages/ProductCompositions';
import { ProductCreationPage } from '@/pages/ProductCreation';
import { CouponsPage } from '@/pages/Coupons/CouponsPage';
import { CouponCreationPage } from '@/pages/CouponCreation';
import { AccountsPage } from '@/pages/Accounts';
import { CouponDetailPage } from '@/pages/CouponDetail';
import { OrdersPage } from '@/pages/Orders';
import { OrderDetailPage } from '@/pages/OrderDetail';
import { HomePage, PagesPage } from '@/pages/Pages';
import { PageCreationPage } from '@/pages/PageCreation';
import { ThemeOptionsFooterPage } from '@/pages/ThemeOptions/ThemeOptionsFooterPage';
import { ThemeOptionsGeneralPage } from '@/pages/ThemeOptions/ThemeOptionsGeneralPage';
import { ThemeOptionsSmsPage } from '@/pages/ThemeOptions/ThemeOptionsSmsPage';
import { AccountCreationPage } from '@/pages/AccountCreation';
import { DataFeedbacksPage } from '@/pages/DataFeedbacks/DataFeedbacksPage';
import { DataFeedbackPage } from '@/pages/DataFeedback/DataFeedbackPage';
import { DataMultiOrdersPage } from '@/pages/DataMultiOrders/DataMultiOrdersPage';
import { DataMultiOrderPage } from '@/pages/DataMultiOrder/DataMultiOrderPage';
import { ThemeOptionsHeaderPage } from '@/pages/ThemeOptions/ThemeOptionsHeaderPage';
import { BountyProgramsPage } from '@/pages/BountyPrograms';
import { BountyProgramCreationPage } from '@/pages/BountyProgramCreation';
import { AccountPointsPage } from '@/pages/AccountPoints';
import { ThemeOptionsViewPage } from '@/pages/ThemeOptions/ThemeOptionsViewPage';
import { UserRole } from '@/types/user';

export const routeItems: RouteItem[] = [
  {
    key: 'login',
    path: paths.LOGIN,
    Component: LoginPage,
    layout: {
      hideHeader: true,
      hideMenu: true,
    },
    authentication: {
      allowUnauth: true,
    },
  },
  {
    key: 'user',
    path: paths.USERS,
    Component: UserPage,
    layout: {
      title: 'Quản lí tài khoản',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'account',
    path: paths.ACCOUNT,
    Component: AccountsPage,
    layout: {
      title: 'Quản lí người dùng',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'account_detail',
    path: paths.ACCOUNT_DETAIL,
    element: <AccountCreationPage type="view" />,
    layout: {
      title: 'Quản lí người dùng',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'account_edit',
    path: paths.ACCOUNT_EDIT,
    element: <AccountCreationPage type="edit" />,
    layout: {
      title: 'Quản lí người dùng',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'account_create',
    path: paths.ACCOUNT_CREATE,
    element: <AccountCreationPage type="new" />,
    layout: {
      title: 'Quản lí người dùng',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'account_point',
    path: paths.ACCOUNT_POINT,
    Component: AccountPointsPage,
    layout: {
      title: 'Điểm thưởng',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'products',
    path: paths.PRODUCT,
    Component: ProductsPage,
    layout: {
      title: 'Sản phẩm',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'product-create',
    path: paths.PRODUCT_CREATE,
    Component: ProductCreationPage,
    layout: {
      title: 'Tạo mới sản phẩm',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'product-update',
    path: paths.PRODUCT_UPDATE,
    Component: ProductCreationPage,
    layout: {
      title: 'Cập nhật sản phẩm',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'product-manufacturer',
    path: paths.PRODUCT_MANUFACTURER,
    Component: ProductManufacturersPage,
    layout: {
      title: 'Nhà sản xuất',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'product-category',
    path: paths.PRODUCT_CATEGORY,
    Component: ProductCategoriesPage,
    layout: {
      title: 'Nhóm thuốc',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'product-brand',
    path: paths.PRODUCT_BRAND,
    Component: ProductBrandsPage,
    layout: {
      title: 'Thương hiệu',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'product-indication',
    path: paths.PRODUCT_INDICATION,
    Component: ProductIndicationsPage,
    layout: {
      title: 'Chỉ định thuốc',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'product-using',
    path: paths.PRODUCT_USING,
    Component: ProductUsingsPage,
    layout: {
      title: 'Đối tượng sử dụng',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'product-composition',
    path: paths.PRODUCT_COMPOSITION,
    Component: ProductCompositionsPage,
    layout: {
      title: 'Thành phần',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'coupons',
    path: paths.COUPON,
    Component: CouponsPage,
    layout: {
      title: 'Mã giảm',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'coupon-create',
    path: paths.COUPON_CREATE,
    Component: CouponCreationPage,
    layout: {
      title: 'Tạo mới mã giảm',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'coupon-update',
    path: paths.COUPON_UPDATE,
    Component: CouponCreationPage,
    layout: {
      title: 'Cập nhật mã giảm',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'coupon-detail',
    path: paths.COUPON_DETAIL,
    Component: CouponDetailPage,
    layout: {
      title: 'Chi tiết mã giảm',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'bounty-programs',
    path: paths.BOUNTY_PROGRAM,
    Component: BountyProgramsPage,
    layout: {
      title: 'Chương trình trả thưởng',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'bounty-program-create',
    path: paths.BOUNTY_PROGRAM_CREATE,
    Component: BountyProgramCreationPage,
    layout: {
      title: 'Chương trình trả thưởng',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'bounty-program-update',
    path: paths.BOUNTY_PROGRAM_UPDATE,
    Component: BountyProgramCreationPage,
    layout: {
      title: 'Chương trình trả thưởng',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'orders',
    path: paths.ORDERS,
    Component: OrdersPage,
    layout: {
      title: 'Đơn hàng',
    },
    authentication: {
      allowAuth: true,
    },
  },
  {
    key: 'order-detail',
    path: paths.ORDER_DETAIL,
    Component: OrderDetailPage,
    layout: {
      title: 'Đơn hàng',
    },
    authentication: {
      allowAuth: true,
    },
  },
  {
    key: 'pages',
    path: paths.PAGE,
    Component: PagesPage,
    layout: {
      title: 'Trang',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'page-create',
    path: paths.PAGE_CREATE,
    Component: PageCreationPage,
    layout: {
      title: 'Tạo mới trang',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'page-home',
    path: paths.PAGE_HOME,
    Component: HomePage,
    layout: {
      title: 'Trang chủ',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'page-update',
    path: paths.PAGE_UPDATE,
    Component: PageCreationPage,
    layout: {
      title: 'Cập nhật trang',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'theme-options-footer',
    path: paths.THEME_OPTIONS_FOOTER,
    Component: ThemeOptionsFooterPage,
    layout: {
      title: 'Cấu hình - Footer',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'theme-options-header',
    path: paths.THEME_OPTIONS_HEADER,
    Component: ThemeOptionsHeaderPage,
    layout: {
      title: 'Cấu hình - Header',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'theme-options-general',
    path: paths.THEME_OPTIONS_GENERAL,
    Component: ThemeOptionsGeneralPage,
    layout: {
      title: 'Cấu hình chung',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'theme-options-general',
    path: paths.THEME_OPTIONS_VIEW,
    Component: ThemeOptionsViewPage,
    layout: {
      title: 'Cấu hình hiển thị',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'theme-options-sms',
    path: paths.THEME_OPTIONS_SMS,
    Component: ThemeOptionsSmsPage,
    layout: {
      title: 'Cấu hình sms',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'data-feedbacks',
    path: paths.DATA_FEEDBACK,
    Component: DataFeedbacksPage,
    layout: {
      title: 'Hòm thư góp ý',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'data-feedback',
    path: paths.DATA_FEEDBACK_DETAIL,
    Component: DataFeedbackPage,
    layout: {
      title: 'Hòm thư góp ý',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'data-multi-orders',
    path: paths.DATA_MULTI_ORDER,
    Component: DataMultiOrdersPage,
    layout: {
      title: 'Đặt hàng số lượng lớn',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
  {
    key: 'data-multi-order',
    path: paths.DATA_MULTI_ORDER_DETAIL,
    Component: DataMultiOrderPage,
    layout: {
      title: 'Đặt hàng số lượng lớn',
    },
    authentication: {
      allowAuth: true,
      role: UserRole.admin,
    },
  },
];
