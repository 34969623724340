import { OrderCoupon } from './orderCoupon';
import { OrderHistory } from './orderHistory';
import { OrderItem } from './orderItem';
import { OrderPayment } from './orderPayment';
import { OrderShipping, ShippingPartner } from './orderShipping';
import { AccountItem } from './account';
import { OrderBill } from './orderBill';

export enum OrderStatus {
  // Chờ xác nhận
  waitToConfirm = 'wait_to_confirm',
  // Đã xác nhận
  confirmed = 'confirmed',
  // Đang xử lý
  processing = 'processing',
  // Chờ giao hàng
  waitToDelivery = 'wait_to_delivery',
  // Đang giao hàng
  delivering = 'delivering',
  // Đã giao hàng
  delivered = 'delivered',
  // Hoàn tất
  completed = 'completed',
  // Hủy bỏ
  cancelled = 'cancelled',
}

export const orderReasonsMapping: Record<string, string> = {
  user_not_pay: 'Người dùng không thanh toán',
  user_not_feedback: 'Người dùng không nghe máy',
  stock_out: 'Không còn hàng',
  delivery_not_receive: 'Người dùng không nhận hàng',
  delivery_error: 'Lỗi không xác định khi giao hàng',
  check_order_error: 'Đối soát không khớp',
  other: 'Khác',
};

export const orderStatusNameMapping: Record<OrderStatus, string> = {
  [OrderStatus.waitToConfirm]: 'Chờ xác nhận',
  [OrderStatus.confirmed]: 'Đã xác nhận',
  [OrderStatus.processing]: 'Đang xử lý',
  [OrderStatus.waitToDelivery]: 'Chờ giao hàng',
  [OrderStatus.delivering]: 'Đang giao hàng',
  [OrderStatus.delivered]: 'Đã giao hàng',
  [OrderStatus.completed]: 'Hoàn tất',
  [OrderStatus.cancelled]: 'Hủy bỏ',
};

export type Order = {
  id: number;
  code: string;
  accountId: number;
  account: AccountItem;
  orderItems: OrderItem[];
  orderCoupons: OrderCoupon[];
  orderBill: OrderBill;
  orderShippingId: number;
  orderShipping: OrderShipping;
  orderPaymentId: number;
  orderPayment: OrderPayment;
  totalPrincipalPrice: number;
  totalPrincipalDiscountPrice: number;
  totalAllItemsPrice: number;
  totalCouponDiscountPrice: number;
  totalEstimatePrice: number;
  totalPrincipalDeliveryPrice: number;
  totalDeliveryDiscountPrice: number;
  totalDeliveryPrice: number;
  totalPaymentDiscountPrice: number;
  totalPrice: number;
  totalQuantity: number;
  status: OrderStatus;
  orderHistories: OrderHistory[];
  note?: string;
  createdAt: Date;
  updatedAt: Date;
};

export type OrderLite = {
  id: number;
  code: string;
  accountId: number;
  account: AccountItem;
  orderShippingId: number;
  orderShipping: OrderShipping;
  orderPaymentId: number;
  totalPrincipalPrice: number;
  totalPrincipalDiscountPrice: number;
  totalAllItemsPrice: number;
  totalCouponDiscountPrice: number;
  totalEstimatePrice: number;
  totalPrincipalDeliveryPrice: number;
  totalDeliveryDiscountPrice: number;
  totalDeliveryPrice: number;
  totalPaymentDiscountPrice: number;
  totalPrice: number;
  totalQuantity: number;
  status: OrderStatus;
  createdAt: Date;
  updatedAt: Date;
};

export type FetchOrderListRequest = {
  search?: string;
  status?: OrderStatus;
  accountId?: number;
  provinceCode?: string;
  sort?: string;
  page?: number;
  limit?: number;
};

export type FetchOrderListResponse = {
  records: OrderLite[];
  metadata: {
    total: number;
  };
};

export type UpdateStatusRequest = {
  partner?: ShippingPartner;
  status: OrderStatus;
  reason?: string;
  reasonDetail?: string;
};
