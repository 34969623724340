import { CouponStatusSelect } from '@/components/CouponStatusSelect';
import { useCouponStore } from '@/stores/coupon';
import { CouponGroup } from '@/types/coupon';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useRef } from 'react';

export type FilterProps = {
  group: CouponGroup;
};
export const Filter: FC<FilterProps> = ({ group }) => {
  const { query, fetchCouponList } = useCouponStore();
  const [form] = Form.useForm();
  const prevGroupRef = useRef<CouponGroup>(group);

  if (prevGroupRef.current !== group) {
    form.setFieldsValue({
      status: undefined,
      fromExpireDate: undefined,
      toExpireDate: undefined,
      search: '',
    });
    prevGroupRef.current = group;
  }

  const onFinish = (value: any) => {
    fetchCouponList({ ...value, page: 1 });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Row gutter={[16, 0]}>
        <Col span={4}>
          <Form.Item name="status" initialValue={query.status}>
            <CouponStatusSelect allowClear />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="fromExpireDate"
            initialValue={query.fromExpireDate}
            getValueFromEvent={(value: dayjs.Dayjs) => (value ? value.endOf('D').toDate() : value)}
            getValueProps={(value?: Date) => ({ value: value ? dayjs(value) : value })}>
            <DatePicker placeholder="Thời hạn từ" format="DD/MM/YYYY" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="toExpireDate"
            initialValue={query.toExpireDate}
            getValueFromEvent={(value: dayjs.Dayjs) => (value ? value.endOf('D').toDate() : value)}
            getValueProps={(value?: Date) => ({ value: value ? dayjs(value) : value })}>
            <DatePicker placeholder="Thời hạn đến" format="DD/MM/YYYY" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="search" initialValue={query.search}>
            <Input placeholder="Tìm kiếm mã giảm..." />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Button htmlType="submit">Tìm kiếm</Button>
        </Col>
      </Row>
    </Form>
  );
};
