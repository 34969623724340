import './styles/globals.scss';
import 'react-quill/dist/quill.snow.css';
import 'dayjs/locale/vi';

import { AppContainer } from '@/components/AppContainer';
import { AppRoutes } from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { Layout } from './components/Layout';
import { AppLoading } from './AppLoading';
import { StyleProvider } from '@ant-design/cssinjs';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'tinymce';

dayjs.extend(updateLocale);
dayjs.updateLocale('vi', {
  weekStart: 1,
});

function App() {
  return (
    <AppContainer>
      <BrowserRouter>
        <AppLoading>
          <StyleProvider hashPriority="high" autoClear>
            <Layout>
              <AppRoutes />
            </Layout>
          </StyleProvider>
        </AppLoading>
      </BrowserRouter>
    </AppContainer>
  );
}

export default App;
