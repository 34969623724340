import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  LayoutProps,
  Typography,
  message
} from 'antd'
import { FC, useCallback, useState } from 'react'
import { LoginFormValues } from '@/types/auth'
import { paths } from '@/config/path'
import { useAuthStore } from '@/stores/auth'
import { useNavigate } from 'react-router-dom'
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons'

import styles from './styles.module.scss'

export const LoginForm: FC<LayoutProps> = () => {
  const navigate = useNavigate()
  const { login, loading, firstLocationHref } = useAuthStore()
  const [error, setError] = useState(false)

  const getNextPath = useCallback(() => {
    if (firstLocationHref) {
      const newHref = firstLocationHref.replace(/(^\w+:|^)\/\//, '')
      const pathName = newHref.substring(newHref.indexOf('/'))
      if (
        Boolean(pathName) &&
        !pathName.toLowerCase().startsWith(paths.LOGIN)
      ) {
        return pathName
      }
    }
    return '/'
  }, [firstLocationHref])

  const onFinish = async (data: LoginFormValues) => {
    try {
      await login(data)
      navigate(getNextPath(), { replace: true })
    } catch (error: any) {
      if (error.response?.data?.name === 'auth_password_incorrect') {
        setError(true)
      } else {
        message.error('Có lỗi xảy ra.', 3)
      }
    }
  }

  return (
    <Card className={styles.loginContainer}>
      {error && (
        <Alert
          showIcon
          className={styles.alert}
          message="Username or password is incorrect."
          type="error"
        />
      )}
      <div className={styles.titleBox}>
        <img src="/images/logo.svg" height={60} alt="ZaloPay" />
        <Typography.Paragraph type="secondary" strong>
          Thuận gia Admin
        </Typography.Paragraph>
      </div>
      <Form
        name="login"
        labelCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your domain' }]}>
          <Input
            //
            data-testid="username"
            placeholder="Username"
            prefix={<UserOutlined />}
            size="large"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password' }]}>
          <Input.Password
            //
            data-testid="password"
            placeholder="Password"
            prefix={<LockOutlined />}
            size="large"
          />
        </Form.Item>
        <Button
          block
          className={styles.loginButton}
          data-testid="login-submit"
          htmlType="submit"
          icon={<LoginOutlined />}
          loading={loading}
          size="large"
          type="primary">
          Login
        </Button>
      </Form>
    </Card>
  )
}
