import { CouponSelect } from '@/components/CouponSelect';
import { CouponGroup } from '@/types/coupon';
import { Form, Modal } from 'antd';
import { FC, useState } from 'react';

export type CouponModalProps = {
  open?: boolean;
  onCancel?: () => void;
  onFinish?: (values: { couponId: number }) => Promise<void>;
};
export const CouponModal: FC<CouponModalProps> = ({ open, onCancel, onFinish: _onFinish }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      await _onFinish?.(values);
      onCancel?.();
      setSubmitting(false);
      form.resetFields();
    } catch (error) {
      setSubmitting(false);
    }
  };
  return (
    <Form labelCol={{ span: 24 }} onFinish={onFinish} form={form}>
      <Modal
        title="Chọn mã giảm muốn tặng"
        open={open}
        onCancel={onCancel}
        onOk={form.submit}
        okButtonProps={{ loading: submitting, disabled: submitting }}>
        <Form.Item label="Mã giảm" name="couponId" rules={[{ required: true, message: 'Vui lòng chọn mã giảm' }]}>
          <CouponSelect group={CouponGroup.accountRank} showEdit showAdd showDetail />
        </Form.Item>
      </Modal>
    </Form>
  );
};
