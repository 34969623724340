import { CreatePageRequest, PageItem, PageQuery, UpdatePageRequest } from '@/types/page';
import { create } from 'zustand';
import * as pageApi from '@/api/page';

export type PageState = {
  loading: boolean;
  loaded: boolean;
  query: PageQuery;
  total: number;
  items: PageItem[];
  submitting: boolean;
  fetchPageList: (query?: Partial<PageQuery>) => Promise<void>;
  createPage: (input: CreatePageRequest) => Promise<void>;
  updatePage: (id: number, input: UpdatePageRequest) => Promise<void>;
  deletePage: (id: number) => Promise<void>;
};

export const usePageStore = create<PageState>((set, get) => ({
  loading: false,
  loaded: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  submitting: false,
  fetchPageList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const res = await pageApi.fetchPageList(query);
      set({
        loading: false,
        loaded: true,
        total: res.metadata.total,
        items: res.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  createPage: async (create) => {
    try {
      set({ submitting: true });
      await pageApi.createPage(create);
      if (get().loaded) get().fetchPageList();
    } finally {
      set({ submitting: false });
    }
  },
  updatePage: async (id, update) => {
    try {
      set({ submitting: true });
      await pageApi.updatePage(id, update);
      if (get().loaded) get().fetchPageList();
    } finally {
      set({ submitting: false });
    }
  },
  deletePage: async (id) => {
    try {
      set({ loading: true });
      await pageApi.deletePage(id);
      if (get().loaded) get().fetchPageList();
    } finally {
      set({ loading: false });
    }
  },
}));
