import { create } from 'zustand';
import * as bountyProgramApi from '@/api/bountyProgram';
import {
  BountyProgramQuery,
  BountyProgram,
  CreateBountyProgramRequest,
  UpdateBountyProgramRequest,
} from '@/types/bountyProgram';

export type BountyProgramState = {
  loading: boolean;
  loaded: boolean;
  query: BountyProgramQuery;
  total: number;
  items: BountyProgram[];
  submitting?: boolean;
  fetchBountyProgramList: (query?: Partial<BountyProgramQuery>) => Promise<void>;
  createBountyProgram: (input: CreateBountyProgramRequest) => Promise<void>;
  updateBountyProgram: (id: number, input: UpdateBountyProgramRequest) => Promise<void>;
  deleteBountyProgram: (BountyProgramId: number) => Promise<void>;
};
export const useBountyProgramStore = create<BountyProgramState>((set, get) => ({
  loading: false,
  loaded: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  submitting: false,
  fetchBountyProgramList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const BountyProgramRes = await bountyProgramApi.fetchBountyProgramList(query);
      set({
        loading: false,
        loaded: true,
        total: BountyProgramRes.metadata.total,
        items: BountyProgramRes.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  createBountyProgram: async (create) => {
    try {
      set({ submitting: true });
      await bountyProgramApi.createBountyProgram(create);
      if (get().loaded) get().fetchBountyProgramList();
    } finally {
      set({ submitting: false });
    }
  },
  updateBountyProgram: async (id, update) => {
    try {
      set({ submitting: true });
      await bountyProgramApi.updateBountyProgram(id, update);
      if (get().loaded) get().fetchBountyProgramList();
    } finally {
      set({ submitting: false });
    }
  },
  deleteBountyProgram: async (BountyProgramId) => {
    try {
      set({ loading: true });
      await bountyProgramApi.deleteBountyProgram(BountyProgramId);
      if (get().loaded) get().fetchBountyProgramList();
    } finally {
      set({ loading: false });
    }
  },
}));
