import { Coupon, CouponQuery, CouponResponse, CreateCouponRequest, UpdateCouponRequest } from '@/types/coupon';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/coupon';

export const fetchCouponList = async (params: CouponQuery) => {
  const { data } = await baseApi.get<CouponResponse>(ROOT_PATH, { params });
  return data;
};

export const fetchCoupon = async (id: number) => {
  const { data } = await baseApi.get<Coupon>(`${ROOT_PATH}/${id}`);
  return data;
};

export const createCoupon = async (input: CreateCouponRequest) => {
  const { data } = await baseApi.post<Coupon>(ROOT_PATH, serializeNullType(input));
  return data;
};

export const updateCoupon = async (id: number, input: UpdateCouponRequest) => {
  const { data } = await baseApi.put<Coupon>(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deleteCoupon = async (id: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${id}`);
  return data;
};
