import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useRouteItems } from './utils/hooks/useRouteItems'

export const AppRoutes: FC = () => {
  const routeItems = useRouteItems()
  return (
    <Routes>
      {routeItems.map((item) => (
        <Route
          path={item.path}
          key={item.key}
          Component={item.Component}
          element={item.element}
        />
      ))}
      <Route path="*" element={<Navigate replace to={routeItems[0].path} />} />
    </Routes>
  )
}
