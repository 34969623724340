import {
  CreateProductCompositionRequest,
  ProductComposition,
  ProductCompositionItem,
  ProductCompositionQuery,
  UpdateProductCompositionRequest,
} from '@/types/productComposition';
import { create } from 'zustand';
import * as productCompositionApi from '@/api/productComposition';

export type ProductCompositionState = {
  loading: boolean;
  loaded: boolean;
  query: ProductCompositionQuery;
  total: number;
  items: ProductCompositionItem[];
  fetchProductCompositionList: (query?: Partial<ProductCompositionQuery>) => Promise<void>;
  createProductComposition: (input: CreateProductCompositionRequest) => Promise<void>;
  updateProductComposition: (id: number, input: UpdateProductCompositionRequest) => Promise<void>;
  deleteProductComposition: (id: number) => Promise<void>;
};

export const useProductCompositionStore = create<ProductCompositionState>((set, get) => ({
  loading: false,
  loaded: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  fetchProductCompositionList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const res = await productCompositionApi.fetchProductCompositionList(query);
      set({
        loading: false,
        loaded: true,
        total: res.metadata.total,
        items: res.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  createProductComposition: async (create) => {
    try {
      useProductCompositionCreationModalStore.getState().setLoading(true);
      await productCompositionApi.createProductComposition(create);
      if (get().loaded) get().fetchProductCompositionList();
      if (useProductCompositionSelectionStore.getState().loaded)
        useProductCompositionSelectionStore.getState().fetchAllProductCompositionList({ reload: true });
    } finally {
      useProductCompositionCreationModalStore.getState().setLoading(false);
    }
  },
  updateProductComposition: async (id, update) => {
    try {
      useProductCompositionCreationModalStore.getState().setLoading(true);
      await productCompositionApi.updateProductComposition(id, update);
      if (get().loaded) get().fetchProductCompositionList();
      if (useProductCompositionSelectionStore.getState().loaded)
        useProductCompositionSelectionStore.getState().fetchAllProductCompositionList({ reload: true });
    } finally {
      useProductCompositionCreationModalStore.getState().setLoading(false);
    }
  },
  deleteProductComposition: async (id) => {
    try {
      set({ loading: true });
      await productCompositionApi.deleteProductComposition(id);
      if (get().loaded) get().fetchProductCompositionList();
      if (useProductCompositionSelectionStore.getState().loaded)
        useProductCompositionSelectionStore.getState().fetchAllProductCompositionList({ reload: true });
    } finally {
      set({ loading: false });
    }
  },
}));

export type ProductCompositionCreationModalState = {
  loading: boolean;
  open: boolean;
  initial?: ProductComposition;
  setLoading: (loading: boolean) => void;
  openModalWithFetch: (id: number) => Promise<void>;
  openModal: (initial?: ProductComposition) => void;
  closeModal: () => void;
};
export const useProductCompositionCreationModalStore = create<ProductCompositionCreationModalState>((set, get) => ({
  loading: false,
  open: false,
  initial: undefined,
  setLoading: (loading) => set({ loading }),
  openModalWithFetch: async (id) => {
    const initial = await productCompositionApi.fetchProductComposition(id);
    get().openModal(initial);
  },
  openModal: (initial) => {
    set({ initial, open: true });
  },
  closeModal: () => {
    set({ initial: undefined, open: false });
  },
}));

export type ProductCompositionSelectionState = {
  loading: boolean;
  loaded: boolean;
  items: ProductCompositionItem[];
  fetchAllProductCompositionList: (options?: { reload?: boolean }) => Promise<void>;
};
export const useProductCompositionSelectionStore = create<ProductCompositionSelectionState>((set, get) => ({
  loading: false,
  loaded: false,
  items: [],
  fetchAllProductCompositionList: async ({ reload = true } = {}) => {
    if (!reload && get().loaded) return;
    try {
      set({ loading: true });
      const ProductCompositionRes = await productCompositionApi.fetchProductCompositionList({
        page: 1,
        limit: 1000,
      });
      set({ loading: false, loaded: true, items: ProductCompositionRes.records || [] });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
}));
