import { FormInstance } from 'antd'
import { NamePath } from 'antd/es/form/interface'
import { toLowerCaseNonAccentVietnamese } from './search'

export const nameToSlug = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = toLowerCaseNonAccentVietnamese(str)

  // remove accents, swap ñ for n, etc
  const from = '·/_,:;'
  const to = '------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

export const handleNameToSlug =
  (form: FormInstance<any>, field: NamePath) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const slug = nameToSlug(value)
    form.setFieldValue(field, slug)
  }
