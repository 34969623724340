import {
  CouponAccount,
  CreateCouponAccountRequest,
  CreateManyCouponAccountsRequest,
  UpdateCouponAccountRequest,
} from '@/types/couponAccount';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/coupon-account';

export const fetchAllCouponAccountsByCouponId = async (couponId: number) => {
  const { data } = await baseApi.get<CouponAccount[]>(`${ROOT_PATH}/coupon/${couponId}`);
  return data;
};

export const createCouponAccount = async (input: CreateCouponAccountRequest) => {
  const { data } = await baseApi.post<CouponAccount>(ROOT_PATH, serializeNullType(input));
  return data;
};

export const createManyCouponAccounts = async (input: CreateManyCouponAccountsRequest) => {
  const { data } = await baseApi.post<CouponAccount[]>(`${ROOT_PATH}/many`, serializeNullType(input));
  return data;
};

export const updateCouponAccount = async (id: number, input: UpdateCouponAccountRequest) => {
  const { data } = await baseApi.put<CouponAccount>(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deleteCouponAccount = async (id: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${id}`);
  return data;
};
