import { ContentTabInput } from '@/components/ContentTabInput';
import { useProductCompositionCreationModalStore, useProductCompositionStore } from '@/stores/productComposition';
import { ProductCompositionModalForm } from '@/types/productComposition';
import { handleNameToSlug } from '@/utils/helpers/nameToSlug';
import { Button, Form, Input, Modal, message } from 'antd';
import { FC, useEffect } from 'react';

export const productCompositionTabs = [
  { key: 'general', label: 'Thông tin chung' },
  { key: 'indication', label: 'Chỉ định' },
  { key: 'dosage', label: 'Liều Lượng - Cách Dùng' },
  { key: 'contraindication', label: 'Chống Chỉ Định' },
  { key: 'interaction', label: 'Tương Tác Thuốc' },
  { key: 'specification', label: 'Bảo Quản' },
  { key: 'overbold', label: 'Quá Liều' },
  { key: 'pharmacodynamics', label: 'Dược Lực Hoc' },
  { key: 'pharmacokinetics', label: 'Dược Động Hoc' },
];

export const ProductCompositionCreationModal: FC = () => {
  const [form] = Form.useForm<ProductCompositionModalForm>();
  const { createProductComposition, updateProductComposition } = useProductCompositionStore();
  const { loading, initial, open, closeModal } = useProductCompositionCreationModalStore();

  useEffect(() => {
    if (initial)
      form.setFieldsValue({
        ...initial,
        metadataItems: (initial.metadataItems || []).reduce((prev, cur) => ({ ...prev, [cur.key]: cur.value }), {}),
      });
  }, [initial]);

  const onFinish = async (value: ProductCompositionModalForm) => {
    try {
      // Upload Record
      if (initial) {
        await updateProductComposition(initial.id, {
          ...value,
          metadataItems: productCompositionTabs.map(({ key }) => ({ key, value: value.metadataItems?.[key] || '' })),
        });
      } else {
        await createProductComposition({
          ...value,
          metadataItems: productCompositionTabs.map(({ key }) => ({ key, value: value.metadataItems?.[key] || '' })),
        });
      }
      message.success(initial ? 'Cập nhật Thành phần thành công' : 'Tạo mới Thành phần thành công');
      closeModal();
      form.resetFields();
    } catch (error: any) {
      if (error?.response?.data?.name === 'name_is_exist') {
        form.setFields([
          {
            name: 'name',
            errors: ['Thành phần đã tồn tại!'],
          },
        ]);
      }
      if (error?.response?.data?.name === 'slug_is_exist') {
        form.setFields([
          {
            name: 'slug',
            errors: ['Đường dẫn đã tồn tại!'],
          },
        ]);
      } else {
        message.error(initial ? 'Cập nhật Thành phần không thành công' : 'Tạo mới Thành phần không thành công');
      }
    }
  };

  const onClose = () => {
    closeModal();
    form.resetFields();
  };

  return (
    <Modal
      title={initial ? 'Cập nhật Thành phần' : 'Tạo mới Thành phần'}
      open={open}
      cancelText="Hủy"
      okText={initial ? 'Cập nhật' : 'Tạo mới'}
      onCancel={onClose}
      onOk={form.submit}
      width={1000}
      maskClosable={false}
      okButtonProps={{ loading }}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <Form.Item label="Tên Thành phần" name="name" rules={[{ required: true, message: 'Tên Thành phần bắt buộc' }]}>
          <Input placeholder="Tên Thành phần" onChange={initial ? undefined : handleNameToSlug(form, 'slug')} />
        </Form.Item>
        <Form.Item label="Đường dẫn" name="slug" rules={[{ required: true, message: 'Đường dẫn bắt buộc' }]}>
          <Input placeholder="Đường dẫn" />
        </Form.Item>
        <Form.Item name="metadataItems">
          <ContentTabInput tabs={productCompositionTabs} />
        </Form.Item>
        <div style={{ display: 'none' }}>
          <Button htmlType="submit" hidden></Button>
        </div>
      </Form>
    </Modal>
  );
};
