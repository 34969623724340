import { Tabs } from 'antd';
import { FC, useState } from 'react';
import { Quill } from '../Quill';

export type ContentTab = {
  label: string;
  key: string;
};
export type ContentTabValue = Record<string, string>;
export type ContentTabInputProps = {
  tabs: ContentTab[];
  value?: ContentTabValue;
  onChange?: (value: ContentTabValue) => void;
};

export const ContentTabInput: FC<ContentTabInputProps> = ({ tabs, ...props }) => {
  const [localValue, setLocalValue] = useState<ContentTabValue>(() => props.value || {});
  const value = 'value' in props ? props.value || {} : localValue;
  const onChange = (value: ContentTabValue) => {
    setLocalValue(value);
    props.onChange?.(value);
  };
  return (
    <Tabs
      items={tabs.map((tab) => ({
        key: tab.key,
        label: tab.label,
        children: (
          <Quill
            placeholder={tab.label}
            value={value[tab.key] || ''}
            onChange={(tabValue) => onChange({ ...value, [tab.key]: tabValue })}
          />
        ),
      }))}
    />
  );
};
