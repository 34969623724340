import { LayoutContent } from '@/components/Layout';
import { paths } from '@/config/path';
import { usePageStore } from '@/stores/page';
import { PageCreationForm } from '@/types/page';
import { handleNameToSlug } from '@/utils/helpers/nameToSlug';
import { Button, Form, Input, Modal, Space, Spin, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as pageApi from '@/api/page';
import { TextEditor } from '@/components/TextEditor';

export type PageCreationPageProps = {};
export const PageCreationPage: FC<PageCreationPageProps> = () => {
  const { id: _id } = useParams();
  const id = parseInt(_id);
  const [loading, setLoading] = useState(true);
  const { submitting, updatePage, createPage } = usePageStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<PageCreationForm>();

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    pageApi
      .fetchPage(id)
      .then((data) => {
        form.setFieldsValue({
          slug: data.slug,
          title: data.title,
          content: data.content,
        });
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const onFinish = async (values: PageCreationForm) => {
    try {
      message.loading(id ? 'Đang cập nhật...' : 'Đang thêm mới...');

      if (id) {
        await updatePage(id, values);
      } else {
        await createPage(values);
      }
      message.destroy();
      message.success(id ? 'Cập nhật trang thành công' : 'Tạo mới trang thành công');
      navigate(paths.PAGE, { replace: true });
    } catch (error: any) {
      if (error?.response?.data?.name === 'slug_is_exist') {
        form.setFields([{ name: 'slug', errors: ['Đường dẫn đã tồn tại!'] }]);
      } else {
        message.error(id ? 'Cập nhật trang không thành công' : 'Tạo mới trang không thành công');
      }
      message.destroy();
    }
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} scrollToFirstError layout="vertical" onFinish={onFinish}>
        <LayoutContent
          actions={
            <Space>
              <Button
                disabled={submitting}
                onClick={() =>
                  Modal.confirm({
                    title: 'Hủy thay đổi',
                    content: 'Bạn có chắc muốn hủy thay đổi không?',
                    onOk: () => navigate(paths.PRODUCT),
                  })
                }>
                Hủy
              </Button>
              <Button type="primary" disabled={submitting} loading={submitting} htmlType="submit">
                {id ? 'Cập nhật' : 'Thêm mới'}
              </Button>
            </Space>
          }>
          <Form.Item label="Tiêu đề" name="title" rules={[{ required: true, message: 'Tiêu đề bắt buộc' }]}>
            <Input placeholder="Nhập tiêu đề" onChange={id ? undefined : handleNameToSlug(form, 'slug')} />
          </Form.Item>
          <Form.Item
            label="Liên kết"
            name="slug"
            rules={[
              { required: true, message: 'Liên kết bắt buộc' },
              { pattern: /^[-a-zA-Z0-9@.+_]+$/g, message: 'Liên kết bắt buộc KHÔNG có khoảng trắng!' },
            ]}>
            <Input placeholder="Liên kết" />
          </Form.Item>
          <Form.Item label="Nội dung" name="content">
            <TextEditor placeholder="Nhập nội dung" />
          </Form.Item>
        </LayoutContent>
      </Form>
    </Spin>
  );
};
