import { AppConfig, AppConfigKey, GetManyAppConfigRequest, UpsertManyAppConfigRequest } from '@/types/appConfig';
import { create } from 'zustand';
import * as appConfigApi from '@/api/appConfig';

export type AppConfigState = {
  loading: boolean;
  submitting: boolean;
  items: AppConfig[];
  fetchManyAppConfigs: (params: GetManyAppConfigRequest) => Promise<void>;
  updateManyAppConfigs: (input: UpsertManyAppConfigRequest) => Promise<void>;
};

export const useAppConfigStore = create<AppConfigState>((set) => ({
  loading: false,
  submitting: false,
  items: [],
  fetchManyAppConfigs: async (params) => {
    try {
      set({ loading: true });
      const appConfigs = await appConfigApi.fetchManyAppConfigs(params);
      set(({ items }) => {
        const keys = params.keys;
        const newItems = items.filter((item) => !keys.includes(item.key));
        return { items: [...newItems, ...appConfigs] };
      });
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
    }
  },
  updateManyAppConfigs: async (request) => {
    try {
      set({ submitting: true });
      const appConfigs = await appConfigApi.upsertManyAppConfigs(request);
      set(({ items }) => {
        const keys = request.appConfigs.map(({ key }) => key);
        const newItems = items.filter((item) => !keys.includes(item.key));
        return { items: [...newItems, ...appConfigs] };
      });
      set({ submitting: false });
    } catch (error) {
      set({ submitting: false });
    }
  },
}));

export const appConfigsSelector = (keys: AppConfigKey[]) => (state: AppConfigState) => {
  return keys.map((key) => {
    const item = state.items.find((item) => item.key === key);
    if (!item) return { key, value: '' };
    return item;
  });
};

export const appConfigValueSelector = (key: AppConfigKey) => (state: AppConfigState) => {
  const item = state.items.find((item) => item.key === key);
  if (!item) return '';
  return item.value;
};


export const appConfigValuesSelector = (keys: AppConfigKey[]) => (state: AppConfigState) => {
  return state.items.filter(item => keys.includes(item.key)).reduce((prev, cur) => ({
    ...prev,
    [cur.key]: cur.value
  }), {})
};
