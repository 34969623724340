export const FILE_UPLOAD_TYPE = ['image/png', 'image/jpeg', 'image/jpg'];
export const FILE_UPLOAD_SIZE = 100;

// size by kB
export function validateFileSize(file?: File | Blob, size = FILE_UPLOAD_SIZE) {
  if (!file) {
    return false;
  }
  if (file.size === undefined) return true;
  return file.size / 1024 < size;
}

// type: default image/*
export function validateFileType(file?: File, type = FILE_UPLOAD_TYPE) {
  if (!file) {
    return;
  }
  const isMatch = type.includes(file.type);
  return isMatch;
}

export function validateFile(file?: File, type = FILE_UPLOAD_TYPE, size = FILE_UPLOAD_SIZE) {
  return validateFileSize(file, size) && validateFileType(file, type);
}
