import { serializeSorter } from '@/utils/helpers/serializeSorter';
import { cyan } from '@ant-design/colors';
import { EyeTwoTone } from '@ant-design/icons';
import { Button, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useEffect } from 'react';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/config/path';
import { useOrderStore } from '@/stores/order';
import { OrderLite, orderStatusNameMapping } from '@/types/order';
import { numberToVNDPrice } from '@/utils/helpers/pricing';

export const OrderList = () => {
  const { loading, query, total, items, fetchOrderList } = useOrderStore();
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrderList().catch(() => message.error('Lỗi không thể tải danh sách đơn hàng', 3));
  }, []);

  const columns: ColumnProps<OrderLite>[] = [
    {
      title: 'Code',
      dataIndex: 'id',
      width: 60,
      sorter: {
        multiple: 1,
      },
      render: (_, record) => `#${record.code}`,
    },
    {
      title: 'Người đặt',
      dataIndex: 'accountId',
      sorter: {
        multiple: 1,
      },
      render: (_, record) => {
        if (record.account?.fullName !== record.account?.phone) {
          return (
            <>
              <strong>{record.account?.fullName}</strong>
              <br />
              {record.account?.phone}
            </>
          );
        }
        return <strong>{record.account?.fullName}</strong>;
      },
    },
    {
      title: 'Giá trị đơn hàng',
      dataIndex: 'totalPrice',
      sorter: {
        multiple: 1,
      },
      render: (_, record) => (
        <>
          <strong>{numberToVNDPrice(record.totalPrice)}</strong>
          <br />
          <span>Tổng SL {record.totalQuantity}</span>
        </>
      ),
    },
    {
      title: 'Tỉnh/Thành',
      dataIndex: 'totalPrice',
      render: (_, record) => record.orderShipping.provinceName,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      sorter: {
        multiple: 1,
      },
      render: (_, record) => orderStatusNameMapping[record.status],
    },
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      width: 150,
      sorter: {
        multiple: 4,
      },
      render: (createdAt) => (createdAt ? format(new Date(createdAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      width: 150,
      sorter: {
        multiple: 5,
      },
      render: (updatedAt) => (updatedAt ? format(new Date(updatedAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 80,
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              shape="circle"
              title="View"
              onClick={() => navigate(`${paths.ORDERS}/view/${record.id}`)}>
              <EyeTwoTone twoToneColor={cyan.primary} />
            </Button>
          </>
        );
      },
    },
  ];

  const onChange = useCallback((pagination: any, _: any, sorter: any) => {
    fetchOrderList({
      page: pagination.current || query.page,
      limit: pagination.pageSize || query.limit,
      sort: serializeSorter(sorter),
    });
  }, []);

  return (
    <Table
      rowKey={(item) => item.id}
      loading={loading}
      columns={columns}
      dataSource={items}
      onChange={onChange}
      pagination={{
        current: query.page,
        pageSize: query.limit,
        total: total,
      }}
    />
  );
};
