import { FormData } from '@/components/FormData/FormData';
import { Col, Form, Input, Row } from 'antd';
import { FC } from 'react';

export const DataFeedbackPage: FC = () => {
  return (
    <FormData>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="fullName" label="Họ và Tên">
            <Input placeholder="Họ và Tên" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="phone" label="Số điện thoại">
            <Input placeholder="Số điện thoại" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="content" label="Nội dung">
            <Input.TextArea placeholder="Nội dung" rows={8} />
          </Form.Item>
        </Col>
      </Row>
    </FormData>
  );
};
