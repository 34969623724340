import { LayoutContent } from '@/components/Layout';
import { AppConfigKey } from '@/types/appConfig';
import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { FC } from 'react';
import { AppConfigType, useThemeOption } from './useThemeOption';

const appConfigTypes: AppConfigType[] = [
  {
    key: AppConfigKey.SMS_API_KEY,
    type: 'string',
    default: '',
  },
  {
    key: AppConfigKey.SMS_SECRET_KEY,
    type: 'string',
    default: '',
  },
  {
    key: AppConfigKey.SMS_BRAND_NAME,
    type: 'string',
    default: '',
  },
  {
    key: AppConfigKey.SMS_REGISTER_TEMPLATE,
    type: 'string',
    default: '',
  },
  {
    key: AppConfigKey.SMS_LOGIN_TEMPLATE,
    type: 'string',
    default: '',
  },
];

export const ThemeOptionsSmsPage: FC = () => {
  const [form] = Form.useForm();
  const { loading, submitting, onFinish } = useThemeOption({ form, types: appConfigTypes });

  return (
    <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
      <Spin spinning={loading}>
        <LayoutContent
          footer={
            <div style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={() => form.submit()}
                disabled={loading || submitting}
                loading={submitting}>
                Lưu
              </Button>
            </div>
          }
          sections={[
            {
              key: 'sms',
              title: 'Cấu hình',
              content: (
                <>
                  {console.log(process.env.REACT_APP_ENV)} 1
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        label="SMS_API_KEY"
                        required
                        rules={[{ required: true, message: 'Trường bắt buộc' }]}
                        name={AppConfigKey.SMS_API_KEY}>
                        <Input placeholder="SMS_API_KEY" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="SMS_SECRET_KEY"
                        required
                        rules={[{ required: true, message: 'Trường bắt buộc' }]}
                        name={AppConfigKey.SMS_SECRET_KEY}>
                        <Input placeholder="SMS_SECRET_KEY" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="SMS_BRAND_NAME"
                        required
                        rules={[{ required: true, message: 'Trường bắt buộc' }]}
                        name={AppConfigKey.SMS_BRAND_NAME}>
                        <Input placeholder="SMS_BRAND_NAME" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Template đăng nhập"
                        required
                        help={
                          <>
                            Thông tin được cung cấp từ esms, muốn thay đổi phải được bên esms cấu hình
                            <br />
                            Mẫu:
                            <br />
                            {process.env.REACT_APP_ENV === 'production' &&
                              `eThuanGia Ma OTP la {{otp}} tren website https://ethuangia.vn`}
                            {process.env.REACT_APP_ENV !== 'production' &&
                              `{{{otp}}} la ma dat lai mat khau Baotrixemay cua ban`}
                          </>
                        }
                        rules={[{ required: true, message: 'Trường bắt buộc' }]}
                        name={AppConfigKey.SMS_LOGIN_TEMPLATE}>
                        <Input placeholder="SMS_LOGIN_TEMPLATE" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Template đăng ký"
                        required
                        help={
                          <>
                            Thông tin được cung cấp từ esms, muốn thay đổi phải được bên esms cấu hình
                            <br />
                            Mẫu:
                            <br />
                            {process.env.REACT_APP_ENV === 'production' &&
                              `eThuanGia Ma OTP la {{otp}} tren website https://ethuangia.vn`}
                            {process.env.REACT_APP_ENV !== 'production' &&
                              `{{{otp}}} la ma dat lai mat khau Baotrixemay cua ban`}
                          </>
                        }
                        rules={[{ required: true, message: 'Trường bắt buộc' }]}
                        name={AppConfigKey.SMS_REGISTER_TEMPLATE}>
                        <Input placeholder="SMS_REGISTER_TEMPLATE" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ),
            },
          ]}
        />
      </Spin>
    </Form>
  );
};
