import { FC, useState } from 'react';
import { Image, Layout as AntLayout, LayoutProps } from 'antd';
import clsx from 'clsx';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { UserInfoHeader } from './UserInfoHeader';
import { MenuLayout } from './MenuLayout';
import { useRouteItems } from '@/utils/hooks/useRouteItems';

import styles from './styles.module.scss';

const MENU_COLLAPSED_KEY = 'MENU_COLLAPSED';
const WIDTH_SIDEBAR = 220;

export const Layout: FC<LayoutProps> = ({ children }) => {
  const [menuCollapsed, setMenuCollapsed] = useState(localStorage.getItem(MENU_COLLAPSED_KEY) !== '0' ?? true);
  const toggleMenuCollapsed = () => {
    setMenuCollapsed((menuCollapsed) => {
      localStorage.setItem(MENU_COLLAPSED_KEY, !menuCollapsed ? '1' : '0');
      return !menuCollapsed;
    });
  };
  const { layout } = useCurrentRoute() || {};
  const { title, hideHeader, hideMenu } = layout || {};

  return (
    <AntLayout className={clsx(styles.container, styles.hideMenu)}>
      {!hideMenu && (
        <AntLayout.Sider
          className={styles.sideBar}
          collapsible
          collapsed={menuCollapsed}
          trigger={null}
          theme="light"
          width={WIDTH_SIDEBAR}>
          <Link to="/" className={styles.logoContainer}>
            <Image className={styles.logo} src="/images/logo.svg" preview={false} />
          </Link>
          <MenuLayout defaultCollapsed={menuCollapsed} />
          <div className={styles.sideBarCollapseButton} onClick={() => toggleMenuCollapsed()}>
            {menuCollapsed ? <MenuUnfoldOutlined size={20} /> : <MenuFoldOutlined size={20} />}
          </div>
        </AntLayout.Sider>
      )}
      <AntLayout className={styles.layout} style={{ '--sidebar-width': `${WIDTH_SIDEBAR}px` } as any}>
        {!hideHeader && (
          <UserInfoHeader
            className={clsx(styles.stickyHeader, {
              [styles.stickyHeaderWithSmallSidebar]: menuCollapsed,
              [styles.stickyHeaderWithoutSidebar]: hideMenu,
            })}
            title={title}
          />
        )}
        <AntLayout.Content
          className={clsx(styles.contentWrapper, {
            [styles.contentWrapperWithoutHeader]: hideHeader,
            [styles.contentWrapperWithoutSidebar]: hideMenu,
            [styles.contentWrapperWithSmallSidebar]: menuCollapsed,
          })}>
          {children}
        </AntLayout.Content>
      </AntLayout>
    </AntLayout>
  );
};

const useCurrentRoute = () => {
  const { pathname } = useLocation();
  const routeItems = useRouteItems();
  return routeItems.find((route) => matchPath(route.path, pathname));
};
