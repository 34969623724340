import {
  BountyProgram,
  BountyProgramQuery,
  BountyProgramResponse,
  CreateBountyProgramRequest,
  UpdateBountyProgramRequest,
} from '@/types/bountyProgram';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/bounty-program';

export const fetchBountyProgramList = async (params: BountyProgramQuery) => {
  const { data } = await baseApi.get<BountyProgramResponse>(ROOT_PATH, { params });
  return data;
};

export const fetchBountyProgram = async (id: number) => {
  const { data } = await baseApi.get<BountyProgram>(`${ROOT_PATH}/${id}`);
  return data;
};

export const createBountyProgram = async (input: CreateBountyProgramRequest) => {
  const { data } = await baseApi.post<BountyProgram>(ROOT_PATH, serializeNullType(input));
  return data;
};

export const updateBountyProgram = async (id: number, input: UpdateBountyProgramRequest) => {
  const { data } = await baseApi.put<BountyProgram>(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deleteBountyProgram = async (id: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${id}`);
  return data;
};
