import { create } from 'zustand';
import * as couponApi from '@/api/coupon';
import { CouponQuery, Coupon, CreateCouponRequest, UpdateCouponRequest } from '@/types/coupon';

export type CouponState = {
  loading: boolean;
  loaded: boolean;
  query: CouponQuery;
  total: number;
  items: Coupon[];
  submitting?: boolean;
  reset: () => void;
  fetchCouponList: (query?: Partial<CouponQuery>) => Promise<void>;
  createCoupon: (input: CreateCouponRequest) => Promise<void>;
  updateCoupon: (id: number, input: UpdateCouponRequest) => Promise<void>;
  deleteCoupon: (CouponId: number) => Promise<void>;
};
export const useCouponStore = create<CouponState>((set, get) => ({
  loading: false,
  loaded: false,
  query: {
    group: undefined,
    search: '',
    status: undefined,
    fromExpireDate: undefined,
    toExpireDate: undefined,
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  submitting: false,
  reset: () => {
    set({
      loading: false,
      loaded: false,
      query: {
        group: undefined,
        search: '',
        status: undefined,
        fromExpireDate: undefined,
        toExpireDate: undefined,
        sort: '',
        page: 1,
        limit: 10,
      },
      total: 0,
      items: [],
      submitting: false,
    });
  },
  fetchCouponList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const CouponRes = await couponApi.fetchCouponList(query);
      set({
        loading: false,
        loaded: true,
        total: CouponRes.metadata.total,
        items: CouponRes.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  createCoupon: async (create) => {
    try {
      set({ submitting: true });
      await couponApi.createCoupon(create);
      if (get().loaded) get().fetchCouponList();
      if (useCouponSelectionStore.getState().loaded)
        useCouponSelectionStore.getState().fetchAllCouponList({ reload: true });
    } finally {
      set({ submitting: false });
    }
  },
  updateCoupon: async (id, update) => {
    try {
      set({ submitting: true });
      await couponApi.updateCoupon(id, update);
      if (get().loaded) get().fetchCouponList();
      if (useCouponSelectionStore.getState().loaded)
        useCouponSelectionStore.getState().fetchAllCouponList({ reload: true });
    } finally {
      set({ submitting: false });
    }
  },
  deleteCoupon: async (CouponId) => {
    try {
      set({ loading: true });
      await couponApi.deleteCoupon(CouponId);
      if (get().loaded) get().fetchCouponList();
      if (useCouponSelectionStore.getState().loaded)
        useCouponSelectionStore.getState().fetchAllCouponList({ reload: true });
    } finally {
      set({ loading: false });
    }
  },
}));

export type CouponSelectionState = {
  loading: boolean;
  loaded: boolean;
  items: Coupon[];
  fetchAllCouponList: (options?: { reload?: boolean }) => Promise<void>;
};
export const useCouponSelectionStore = create<CouponSelectionState>((set, get) => ({
  loading: false,
  loaded: false,
  items: [],
  fetchAllCouponList: async ({ reload = true } = {}) => {
    if (!reload && get().loaded) return;
    try {
      set({ loading: true });
      const CouponRes = await couponApi.fetchCouponList({
        page: 1,
        limit: 1000,
      });
      set({ loading: false, loaded: true, items: CouponRes.records || [] });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
}));
