import { useProductManufacturerSelectionStore } from '@/stores/productManufacturer';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { FC, useEffect, useMemo } from 'react';

export type ProductManufacturerSelectProps = AntSelectProps & {
  ignoreValues?: number[];
};
export const ProductManufacturerSelect: FC<ProductManufacturerSelectProps> = (props) => {
  const {
    placeholder = 'Chọn nhà sản xuất',
    loading: loadingProps,
    allowClear = true,
    showSearch = true,
    ignoreValues = [],
    ...restProps
  } = props;
  const { loading, items, fetchAllProductManufacturerList } = useProductManufacturerSelectionStore();

  const options = useMemo(() => {
    return items
      .filter((item) => !ignoreValues.includes(item.id))
      .map((item) => ({ label: item.name, value: item.id }));
  }, [items, ...ignoreValues]);

  useEffect(() => {
    fetchAllProductManufacturerList({ reload: false });
  }, [fetchAllProductManufacturerList]);

  return (
    <AntSelect
      {...restProps}
      filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
      loading={loadingProps || loading}
      options={options}
      placeholder={placeholder}
      allowClear={allowClear}
      showSearch={showSearch}
    />
  );
};
