import { AccountSelect } from '@/components/AccountSelect';
import { ProvinceSelect } from '@/components/Location/ProvinceSelectComponent';
import { OrderStatusSelect } from '@/components/OrderStatusSelect';
import { useOrderStore } from '@/stores/order';
import { Button, Col, Form, Input, Row } from 'antd';
import { FC } from 'react';

export const Filter: FC = () => {
  const { query, fetchOrderList } = useOrderStore();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    fetchOrderList({
      search: values.search,
      accountId: values.accountId,
      status: values.status,
      provinceCode: values.provinceCode,
      page: 1,
    });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Row gutter={[16, 0]}>
        <Col span={4}>
          <Form.Item name="accountId" initialValue={query.accountId}>
            <AccountSelect placeholder="Chọn người đặt..." />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="status" initialValue={query.status}>
            <OrderStatusSelect placeholder="Chọn trạng thái..." />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="provinceCode" initialValue={query.provinceCode}>
            <ProvinceSelect placeholder="Tỉnh thành" allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="search" initialValue={query.search}>
            <Input placeholder="Tìm kiếm mã hoặc tên sản phẩm..." />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Button htmlType="submit">Tìm kiếm</Button>
        </Col>
      </Row>
    </Form>
  );
};
