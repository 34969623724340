import { LayoutContent } from '@/components/Layout';
import { paths } from '@/config/path';
import { useCouponStore } from '@/stores/coupon';
import { CouponCreationForm, DiscountType, CouponStatus, CouponGroup } from '@/types/coupon';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  message,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import * as couponApi from '@/api/coupon';
import { CouponStatusSelect } from '@/components/CouponStatusSelect';
import dayjs from 'dayjs';
import { InputPrice } from '@/components/InputPrice/InputPrice';
import { UploadAuto } from '@/components/Upload/UploadAuto';

export type CouponCreationPageProps = {};
export const CouponCreationPage: FC<CouponCreationPageProps> = () => {
  const { id: _id, group } = useParams<{ group: CouponGroup; id: string }>();
  const id = parseInt(_id);
  const [loading, setLoading] = useState(true);
  const { submitting, updateCoupon, createCoupon } = useCouponStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm<CouponCreationForm>();

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    couponApi
      .fetchCoupon(id)
      .then((data) => {
        form.setFieldsValue({ ...data, expireDate: data.expireDate ? dayjs(data.expireDate) : null });
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const onSubmit = async (values: CouponCreationForm) => {
    try {
      message.loading(id ? 'Đang cập nhật...' : 'Đang thêm mới...');
      if (id) {
        await updateCoupon(id, { ...values, expireDate: values.expireDate.toISOString() });
      } else {
        await createCoupon({ ...values, group, expireDate: values.expireDate.toISOString() });
      }
      message.destroy();
      message.success(id ? 'Cập nhật mã giảm thành công' : 'Tạo mới mã giảm thành công');
      if (
        location.state?.prevPath === `${paths.COUPON_ALL}/${group}` ||
        location.state?.prevPath === `${paths.COUPON_ALL}/${group}/view/${id}`
      )
        navigate(-1);
      else navigate(`${paths.COUPON_ALL}/${group}`, { replace: true });
    } catch (error: any) {
      console.error(error);
      message.destroy();
      if (error?.response?.data?.name === 'code_is_exist') {
        form.setFields([{ name: 'code', errors: ['Code đã tồn tại!'] }]);
      } else {
        message.error(id ? 'Cập nhật mã giảm không thành công' : 'Tạo mới mã giảm không thành công');
      }
    }
  };

  const onFinish = async (values: CouponCreationForm) => {
    Modal.confirm({
      title: 'Bạn có muốn lưu mã giảm này không?',
      content: values.applyAllUser ? (
        <>
          <br />
          Sau khi tạo với <strong>Áp dụng tất cả user</strong>, tất cả user sẽ nhận được mã giảm và bạn không thể thu
          hồi
          <br />
          <br />
          <strong>Số lượng tối đa cho mỗi user</strong> chỉ được áp dụng cho những người dùng mới
        </>
      ) : (
        ''
      ),
      onOk: async () => onSubmit(values),
    });
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <LayoutContent
          actions={
            <Space>
              <Button
                disabled={submitting}
                onClick={() =>
                  Modal.confirm({
                    title: 'Hủy thay đổi',
                    content: 'Bạn có chắc muốn hủy thay đổi không?',
                    onOk: () => navigate(paths.PRODUCT),
                  })
                }>
                Hủy
              </Button>
              <Button type="primary" disabled={submitting} loading={submitting} htmlType="submit">
                {id ? 'Cập nhật' : 'Thêm mới'}
              </Button>
            </Space>
          }
          sections={[
            {
              key: 'general',
              title: 'Thông tin cơ bản',
              content: (
                <Row gutter={32}>
                  <Col span={12}>
                    <Row gutter={32}>
                      <Col style={{ width: 132 }}>
                        <Form.Item label="Logo" name="logo">
                          <UploadAuto maxSize={10240} />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item label="Code" name="code" rules={[{ required: true, message: 'Code bắt buộc' }]}>
                          <Input placeholder="Code" />
                        </Form.Item>
                        <Form.Item
                          label="Tên mã giảm"
                          name="name"
                          rules={[{ required: true, message: 'Tên mã giảm bắt buộc' }]}>
                          <Input placeholder="Tên mã giảm" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={16}>
                        <Form.Item
                          label="Giá giảm"
                          name="discount"
                          rules={[{ required: true, message: 'Giá giảm bắt buộc' }]}>
                          <InputPrice placeholder="Giá giảm" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Đơn vị"
                          name="discountType"
                          rules={[{ required: true, message: 'Đơn vị bắt buộc' }]}
                          initialValue={DiscountType.exactTotalPrice}>
                          <Select
                            placeholder="Đơn vị"
                            options={[
                              { label: 'VNĐ', value: DiscountType.exactTotalPrice },
                              { label: '%', value: DiscountType.percentTotalPrice },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="Giá giảm tối đa" name="maxPrice">
                      <InputNumber placeholder="Giá giảm tối đa" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Trạng thái" name="status" initialValue={CouponStatus.active}>
                      <CouponStatusSelect />
                    </Form.Item>
                    <Form.Item
                      label="Ngày hết hạn"
                      name="expireDate"
                      rules={[{ required: true, message: 'Ngày hết hạn bắt buộc' }]}
                      initialValue={dayjs().startOf('D').add(2, 'month')}>
                      <DatePicker showTime format="DD/MM/YYYY HH:mm" />
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                      {(form) => {
                        const errors = form.getFieldError('quantity');
                        return (
                          <Form.Item
                            label="Số lượng tối đa cho mỗi user"
                            name="quantityPerUser"
                            rules={[{ required: true, message: 'Số lượng bắt buộc' }]}
                            initialValue={1}
                            help={errors?.length ? errors : 'Nhập 0 để không giới hạn số lượng'}>
                            <InputNumber placeholder="Số lượng" />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                      {(form) => {
                        const errors = form.getFieldError('quantity');
                        return (
                          <Form.Item
                            label="Số lượng tối đa"
                            name="quantity"
                            rules={[{ required: true, message: 'Số lượng bắt buộc' }]}
                            initialValue={0}
                            help={errors?.length ? errors : 'Nhập 0 để không giới hạn số lượng'}>
                            <InputNumber placeholder="Số lượng" />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              ),
            },
            {
              key: 'condition',
              title: 'Điều kiện áp dụng',
              content: (
                <>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Tổng đơn từ" name="conditionMinTotalPrice">
                        <InputPrice placeholder="Tổng đơn từ" addonAfter="VNĐ" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Tổng đơn đến" name="conditionMaxTotalPrice">
                        <InputPrice placeholder="Tổng đơn đến" addonAfter="VNĐ" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Áp dụng tất cả user (Tự động phát user mới khi đăng ký tài khoản)"
                    name="applyAllUser"
                    initialValue={group === CouponGroup.promotion}
                    hidden={group !== CouponGroup.promotion}
                    valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </>
              ),
            },
          ]}
        />
        <div style={{ display: 'none' }}>
          <Button htmlType="submit" hidden></Button>
        </div>
      </Form>
    </Spin>
  );
};
