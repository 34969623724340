import { useProductIndicationCreationModalStore, useProductIndicationStore } from '@/stores/productIndication';
import { ProductIndicationModalForm } from '@/types/productIndication';
import { Button, Form, Input, Modal, message } from 'antd';
import { FC, useEffect } from 'react';

export const ProductIndicationCreationModal: FC = () => {
  const [form] = Form.useForm();
  const { createProductIndication, updateProductIndication } = useProductIndicationStore();
  const { loading, initial, open, closeModal } = useProductIndicationCreationModalStore();

  useEffect(() => {
    if (initial) form.setFieldsValue(initial);
  }, [initial]);

  const onFinish = async (value: ProductIndicationModalForm) => {
    try {
      // Upload Record
      if (initial) {
        await updateProductIndication(initial.id, value);
      } else {
        await createProductIndication(value);
      }
      message.success(initial ? 'Cập nhật Đối tượng sử dụng thành công' : 'Tạo mới Đối tượng sử dụng thành công');
      closeModal();
      form.resetFields();
    } catch (error: any) {
      if (error?.response?.data?.name === 'name_is_exist') {
        form.setFields([
          {
            name: 'name',
            errors: ['Đối tượng sử dụng đã tồn tại!'],
          },
        ]);
      } else {
        message.error(
          initial ? 'Cập nhật Đối tượng sử dụng không thành công' : 'Tạo mới Đối tượng sử dụng không thành công'
        );
      }
    }
  };

  const onClose = () => {
    closeModal();
    form.resetFields();
  };

  return (
    <Modal
      title={initial ? 'Cập nhật Đối tượng sử dụng' : 'Tạo mới Đối tượng sử dụng'}
      open={open}
      cancelText="Hủy"
      okText={initial ? 'Cập nhật' : 'Tạo mới'}
      onCancel={onClose}
      onOk={form.submit}
      maskClosable={false}
      okButtonProps={{ loading }}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <Form.Item
          label="Tên Đối tượng sử dụng"
          name="name"
          rules={[{ required: true, message: 'Tên Đối tượng sử dụng bắt buộc' }]}>
          <Input placeholder="Tên Đối tượng sử dụng" />
        </Form.Item>
        <div style={{ display: 'none' }}>
          <Button htmlType="submit" hidden></Button>
        </div>
      </Form>
    </Modal>
  );
};
