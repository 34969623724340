import { FormDataList } from '@/components/FormData/FormDataList';
import { Filter } from '@/components/FormData/Filter';
import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { FormDataName } from '@/types/formData';
import { paths } from '@/config/path';

export type DataMultiOrdersPageProps = {};
export const DataMultiOrdersPage: FC<DataMultiOrdersPageProps> = () => {
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
      }}>
      <FormDataList
        searchFields={['fullName', 'phone', 'product']}
        formName={FormDataName.MULTI_ORDER}
        basePath={paths.DATA_MULTI_ORDER}
        columns={[
          {
            title: 'Họ và Tên',
            dataIndex: 'fullName',
            width: 160,
            render: (_, record) => record.formDataItems.find((item) => item.key === 'fullName')?.value,
          },
          {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            width: 130,
            render: (_, record) => record.formDataItems.find((item) => item.key === 'phone')?.value,
          },
          {
            title: 'Sản phẩm quan tâm',
            dataIndex: 'product',
            render: (_, record) => record.formDataItems.find((item) => item.key === 'product')?.value,
          },
        ]}
      />
    </LayoutContent>
  );
};
