export enum PaymentMethod {
  cash = 'cash',
  transfer = 'transfer',
  gateways = 'gateways',
}

export const paymentMethodNameMapping: Record<PaymentMethod, string> = {
  [PaymentMethod.cash]: 'Thanh toán khi nhận hàng',
  [PaymentMethod.transfer]: 'Thanh toán chuyển khoản',
  [PaymentMethod.gateways]: 'Thanh toán qua cổng thanh toán',
};

export type OrderPayment = {
  id: number;
  paymentMethod: PaymentMethod;
};
