export enum UserRole {
  admin = 'admin',
  employee = 'employee',
}

export const userRoleLabels = {
  [UserRole.admin]: 'Admin',
  [UserRole.employee]: 'Nhân viên',
};

export type UserDetail = {
  id: number;
  username: string;
  fullName: string;
  role: UserRole;
  roleProvinceCodes: string[];
  roleProvinceNames: string[];
  createdAt: Date;
  updatedAt: Date;
};

export type CreateUserForm = {
  fullName: string;
  username: string;
  password: string;
  role: UserRole;
  roleProvinceCodes?: string[];
};

export type CreateUserRequest = CreateUserForm;

export type CreateUserResponse = UserDetail;

export type UpdateUserForm = {
  fullName: string;
  role: UserRole;
  roleProvinceCodes?: string[];
};

export type UpdateUserRequest = UpdateUserForm;

export type UpdateUserResponse = UserDetail;

export type ChangePasswordRequest = {
  password: string;
};

export type ChangePasswordResponse = UserDetail;
