import { InputPrice } from '@/components/InputPrice/InputPrice';
import { LayoutContent } from '@/components/Layout';
import { AppConfigKey } from '@/types/appConfig';
import { Button, Col, Divider, Form, Input, InputNumber, Row, Spin } from 'antd';
import { FC } from 'react';
import { AppConfigType, useThemeOption } from './useThemeOption';
import { Upload, validateSingleImage } from '@/components/Upload';
import { uploadFile } from '@/api/file';

const appConfigTypes: AppConfigType[] = [
  {
    key: AppConfigKey.REFERRAL_POINT,
    type: 'number',
    default: '',
  },
  {
    key: AppConfigKey.DOWNTOWN_SHIPPING,
    type: 'json',
    default: {},
  },
  {
    key: AppConfigKey.SUBURBS_SHIPPING,
    type: 'json',
    default: {},
  },
  {
    key: AppConfigKey.PAYMENT_INFO_NAME,
    type: 'string',
    default: '',
  },
  {
    key: AppConfigKey.PAYMENT_INFO_NUMBER,
    type: 'string',
    default: '',
  },
  {
    key: AppConfigKey.PAYMENT_INFO_BRAND_NAME,
    type: 'string',
    default: '',
  },
  {
    key: AppConfigKey.PAYMENT_INFO_TEMPLATE,
    type: 'string',
    default: '',
  },
  {
    key: AppConfigKey.PAYMENT_INFO_IMAGE_URL,
    type: 'string',
    default: '',
  },
  {
    key: AppConfigKey.CONTACT_PHONE,
    type: 'string',
    default: '',
  },
  {
    key: AppConfigKey.CONTACT_EMAIL,
    type: 'string',
    default: '',
  },
];

export const ThemeOptionsGeneralPage: FC = () => {
  const [form] = Form.useForm();
  const { loading, submitting, onFinish: onFinishThemeOption } = useThemeOption({ form, types: appConfigTypes });

  const onFinish = async (values: any) => {
    if (
      values[AppConfigKey.PAYMENT_INFO_IMAGE_URL] &&
      typeof values[AppConfigKey.PAYMENT_INFO_IMAGE_URL] !== 'string'
    ) {
      const imageRes = await uploadFile(values[AppConfigKey.PAYMENT_INFO_IMAGE_URL].originFileObj);
      values[AppConfigKey.PAYMENT_INFO_IMAGE_URL] = imageRes.url;
    }
    onFinishThemeOption(values);
  };

  return (
    <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
      <Spin spinning={loading}>
        <LayoutContent
          footer={
            <div style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={() => form.submit()}
                disabled={loading || submitting}
                loading={submitting}>
                Lưu
              </Button>
            </div>
          }
          sections={[
            {
              key: 'referral',
              title: 'Giới thiêu',
              content: (
                <Row>
                  <Col span={12}>
                    <Form.Item label="Điểm khi giới thiệu" required name={AppConfigKey.REFERRAL_POINT}>
                      <InputNumber placeholder="Điểm khi giới thiệu" />
                    </Form.Item>
                  </Col>
                </Row>
              ),
            },
            {
              key: 'contact',
              title: 'Thông tin liên hệ',
              content: (
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label="Số điện thoại"
                      rules={[{ required: true, message: 'Số điện thoại bắt buộc' }]}
                      name={AppConfigKey.CONTACT_PHONE}>
                      <Input placeholder="Số điện thoại" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Email"
                      rules={[{ required: true, message: 'Email bắt buộc' }]}
                      name={AppConfigKey.CONTACT_EMAIL}>
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                </Row>
              ),
            },
            {
              key: 'shipping',
              title: 'Giao hàng',
              content: (
                <Row gutter={24}>
                  <Col span={12}>
                    <h3>Nội thành</h3>
                    <Divider />
                    <Form.Item
                      label="Phí giao hàng"
                      required
                      rules={[{ required: true, message: 'Phí giao hàng cần được cấu hình' }]}
                      name={[AppConfigKey.DOWNTOWN_SHIPPING, 'defaultPrice']}>
                      <InputPrice placeholder="Phí giao hàng" style={{ width: '100%' }} addonAfter="VNĐ" />
                    </Form.Item>
                    <Form.Item
                      label="Freeship cho đơn hàng từ"
                      required
                      name={[AppConfigKey.DOWNTOWN_SHIPPING, 'conditions', 0, 'minTotalPrice']}>
                      <InputPrice placeholder="Freeship cho đơn hàng từ" style={{ width: '100%' }} addonAfter="VNĐ" />
                    </Form.Item>
                    <Form.Item
                      hidden
                      name={[AppConfigKey.DOWNTOWN_SHIPPING, 'conditions', 0, 'price']}
                      initialValue={0}>
                      <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <h3>Ngoại thành</h3>
                    <Divider />
                    <Form.Item
                      label="Phí giao hàng"
                      required
                      rules={[{ required: true, message: 'Phí giao hàng cần được cấu hình' }]}
                      name={[AppConfigKey.SUBURBS_SHIPPING, 'defaultPrice']}>
                      <InputPrice placeholder="Phí giao hàng" style={{ width: '100%' }} addonAfter="VNĐ" />
                    </Form.Item>
                    <Form.Item
                      label="Freeship cho đơn hàng từ"
                      required
                      name={[AppConfigKey.SUBURBS_SHIPPING, 'conditions', 0, 'minTotalPrice']}>
                      <InputPrice placeholder="Freeship cho đơn hàng từ" style={{ width: '100%' }} addonAfter="VNĐ" />
                    </Form.Item>
                    <Form.Item hidden name={[AppConfigKey.SUBURBS_SHIPPING, 'conditions', 0, 'price']} initialValue={0}>
                      <InputNumber />
                    </Form.Item>
                  </Col>
                </Row>
              ),
            },
            {
              key: 'bank',
              title: 'Thông tin thanh toán',
              content: (
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label="Chủ tài khoản"
                      rules={[{ required: true, message: 'Chủ tài khoản bắt buộc' }]}
                      name={AppConfigKey.PAYMENT_INFO_NAME}>
                      <Input placeholder="Chủ tài khoản" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Số tài khoản"
                      rules={[{ required: true, message: 'Số tài khoản bắt buộc' }]}
                      name={AppConfigKey.PAYMENT_INFO_NUMBER}>
                      <Input placeholder="Số tài khoản" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Ngân hàng"
                      rules={[{ required: true, message: 'Ngân hàng bắt buộc' }]}
                      name={AppConfigKey.PAYMENT_INFO_BRAND_NAME}>
                      <Input placeholder="Ngân hàng" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Nội dung"
                      rules={[{ required: true, message: 'Nội dung bắt buộc' }]}
                      name={AppConfigKey.PAYMENT_INFO_TEMPLATE}>
                      <Input placeholder="Nội dung" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Hình ảnh"
                      required
                      rules={[{ validator: validateSingleImage({ maxSize: 512, required: true }) }]}
                      name={AppConfigKey.PAYMENT_INFO_IMAGE_URL}>
                      <Upload maxSize={512} multiple={false} listType="picture-card" />
                    </Form.Item>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </Spin>
    </Form>
  );
};
