import { baseApi } from './common/baseApi';
import { AppConfig, GetManyAppConfigRequest, UpsertManyAppConfigRequest } from '@/types/appConfig';

const ROOT_PATH = '/app-config';

export const fetchManyAppConfigs = async (params: GetManyAppConfigRequest) => {
  const { data } = await baseApi.get<AppConfig[]>(ROOT_PATH, { params: { keys: params.keys.join('|') } });
  return data;
};

export const upsertManyAppConfigs = async (input: UpsertManyAppConfigRequest) => {
  const { data } = await baseApi.post<AppConfig[]>(ROOT_PATH, input);
  return data;
};
