import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { Filter } from './components/Filter';
import { ProductList } from './components/ProductList';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/config/path';

export const ProductsPage: FC = () => {
  const navigate = useNavigate();
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <Button type="primary" onClick={() => navigate(paths.PRODUCT_CREATE)}>
            Thêm mới
          </Button>
        ),
      }}>
      <ProductList />
    </LayoutContent>
  );
};
