import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { Filter } from './components/Filter';
import { ProductUsingList } from './components/ProductUsingList';
import { Button } from 'antd';
import { useProductUsingCreationModalStore } from '@/stores/productUsing';
import { ProductUsingCreationModal } from './components/ProductUsingCreationModal';

export const ProductUsingsPage: FC = () => {
  const { openModal } = useProductUsingCreationModalStore();
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <Button type="primary" onClick={() => openModal()}>
            Thêm mới
          </Button>
        ),
      }}>
      <ProductUsingList />
      <ProductUsingCreationModal />
    </LayoutContent>
  );
};
