export type AppConfig = {
  key: AppConfigKey;
  value: string;
};

export type UpsertManyAppConfigRequest = {
  appConfigs: Array<AppConfig>;
};

export type GetManyAppConfigRequest = {
  keys: string[];
};

export enum AppConfigKey {
  HEADER = 'header',
  FOOTER = 'footer',
  HOME_PAGE = 'home_page',
  REFERRAL_POINT = 'referral_point',
  DOWNTOWN_SHIPPING = 'downtown_shipping',
  SUBURBS_SHIPPING = 'suburbs_shipping',
  SMS_API_KEY = 'sms_api_key',
  SMS_SECRET_KEY = 'sms_secret_key',
  SMS_BRAND_NAME = 'sms_brand_name',
  SMS_REGISTER_TEMPLATE = 'sms_register_template',
  SMS_LOGIN_TEMPLATE = 'sms_login_template',
  PAYMENT_INFO_NAME = 'payment_info_name',
  PAYMENT_INFO_NUMBER = 'payment_info_number',
  PAYMENT_INFO_BRAND_NAME = 'payment_info_brand_name',
  PAYMENT_INFO_TEMPLATE = 'payment_info_template',
  PAYMENT_INFO_IMAGE_URL = 'payment_info_image_url',
  CONTACT_EMAIL = 'contact_email',
  CONTACT_PHONE = 'contact_phone',
  VISIBLE_ACCOUNT_POINT = 'visible_account_point',
  VISIBLE_REFER_ACCOUNT = 'visible_refer_account',
  VISIBLE_COUPON = 'visible_coupon',
  VISIBLE_BOUNTY_PROGRAM = 'visible_bounty_program',
}
