import { FC, useEffect } from 'react';
import { useLocationStore } from '@/stores/location';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { Select, SelectProps } from '../Select';

export type DistrictSelectProps = Omit<SelectProps, 'options'> & {
  provinceCode?: string;
};
export const DistrictSelect: FC<DistrictSelectProps> = ({ value, onChange, provinceCode, ...props }) => {
  const { districtLoading, districts, loadDistricts } = useLocationStore();
  useEffect(() => {
    if (provinceCode) loadDistricts(provinceCode);
  }, [loadDistricts, provinceCode]);
  const items = provinceCode ? districts[provinceCode] || [] : [];
  return (
    <Select
      showSearch
      filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
      options={items.map((item) => ({ value: item.code, label: item.name }))}
      {...props}
      loading={(!!provinceCode && districtLoading[provinceCode]) || props.loading}
      value={items.length ? (value as any) : undefined}
      onChange={onChange as any}
    />
  );
};
