import {
  CreateProductBrandRequest,
  ProductBrand,
  ProductBrandQuery,
  UpdateProductBrandRequest,
} from '@/types/productBrand';
import { create } from 'zustand';
import * as productBrandApi from '@/api/productBrand';

export type ProductBrandState = {
  loading: boolean;
  loaded: boolean;
  query: ProductBrandQuery;
  total: number;
  items: ProductBrand[];
  fetchProductBrandList: (query?: Partial<ProductBrandQuery>) => Promise<void>;
  createProductBrand: (input: CreateProductBrandRequest) => Promise<void>;
  updateProductBrand: (id: number, input: UpdateProductBrandRequest) => Promise<void>;
  deleteProductBrand: (id: number) => Promise<void>;
};

export const useProductBrandStore = create<ProductBrandState>((set, get) => ({
  loading: false,
  loaded: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  fetchProductBrandList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const res = await productBrandApi.fetchProductBrandList(query);
      set({
        loading: false,
        loaded: true,
        total: res.metadata.total,
        items: res.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  createProductBrand: async (create) => {
    try {
      useProductBrandCreationModalStore.getState().setLoading(true);
      await productBrandApi.createProductBrand(create);
      if (get().loaded) get().fetchProductBrandList();
      if (useProductBrandSelectionStore.getState().loaded)
        useProductBrandSelectionStore.getState().fetchAllProductBrandList({ reload: true });
    } finally {
      useProductBrandCreationModalStore.getState().setLoading(false);
    }
  },
  updateProductBrand: async (id, update) => {
    try {
      useProductBrandCreationModalStore.getState().setLoading(true);
      await productBrandApi.updateProductBrand(id, update);
      if (get().loaded) get().fetchProductBrandList();
      if (useProductBrandSelectionStore.getState().loaded)
        useProductBrandSelectionStore.getState().fetchAllProductBrandList({ reload: true });
    } finally {
      useProductBrandCreationModalStore.getState().setLoading(false);
    }
  },
  deleteProductBrand: async (id) => {
    try {
      set({ loading: true });
      await productBrandApi.deleteProductBrand(id);
      if (get().loaded) get().fetchProductBrandList();
      if (useProductBrandSelectionStore.getState().loaded)
        useProductBrandSelectionStore.getState().fetchAllProductBrandList({ reload: true });
    } finally {
      set({ loading: false });
    }
  },
}));

export type ProductBrandCreationModalState = {
  loading: boolean;
  open: boolean;
  initial?: ProductBrand;
  setLoading: (loading: boolean) => void;
  openModal: (initial?: ProductBrand) => void;
  closeModal: () => void;
};
export const useProductBrandCreationModalStore = create<ProductBrandCreationModalState>((set) => ({
  loading: false,
  open: false,
  initial: undefined,
  setLoading: (loading) => set({ loading }),
  openModal: (initial) => {
    set({ initial, open: true });
  },
  closeModal: () => {
    set({ initial: undefined, open: false });
  },
}));

export type ProductBrandSelectionState = {
  loading: boolean;
  loaded: boolean;
  items: ProductBrand[];
  fetchAllProductBrandList: (options?: { reload?: boolean }) => Promise<void>;
};
export const useProductBrandSelectionStore = create<ProductBrandSelectionState>((set, get) => ({
  loading: false,
  loaded: false,
  items: [],
  fetchAllProductBrandList: async ({ reload = true } = {}) => {
    if (!reload && get().loaded) return;
    try {
      set({ loading: true });
      const ProductBrandRes = await productBrandApi.fetchProductBrandList({
        page: 1,
        limit: 1000,
      });
      set({ loading: false, loaded: true, items: ProductBrandRes.records || [] });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
}));
