import { OrderStatus, orderStatusNameMapping } from '@/types/order';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { FC, useMemo } from 'react';

export type OrderStatusSelectProps = AntSelectProps & {
  ignoreValues?: OrderStatus[];
};
export const OrderStatusSelect: FC<OrderStatusSelectProps> = (props) => {
  const {
    placeholder = 'Chọn trạng thái',
    loading: loadingProps,
    allowClear = true,
    showSearch = true,
    ignoreValues = [],
    ...restProps
  } = props;

  const options = useMemo(() => {
    return Object.values(OrderStatus)
      .filter((orderStatus) => !ignoreValues.includes(orderStatus))
      .map((orderStatus) => ({ label: orderStatusNameMapping[orderStatus], value: orderStatus }));
  }, [...ignoreValues]);

  return (
    <AntSelect
      {...restProps}
      filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
      loading={loadingProps}
      options={options}
      placeholder={placeholder}
      allowClear={allowClear}
      showSearch={showSearch}
    />
  );
};
