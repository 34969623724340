import { useBountyProgramStore } from '@/stores/bountyProgram';
import { BountyProgram, BountyProgramQuery, BountyProgramStatus } from '@/types/bountyProgram';
import { serializeSorter } from '@/utils/helpers/serializeSorter';
import { blue } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tag, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { paths } from '@/config/path';

export type BountyProgramListProps = {};
export const BountyProgramList: FC<BountyProgramListProps> = () => {
  const { loading, query, total, items, deleteBountyProgram, fetchBountyProgramList } = useBountyProgramStore();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBountyProgramList().catch(() => message.error('Lỗi không thể tải mã giảm', 3));
  }, []);

  const columns: ColumnProps<BountyProgram>[] = [
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
    },
    {
      title: 'Thời gian',
      dataIndex: 'date',
      width: 180,
      render: (_, record) => (
        <>
          {record.startDate ? dayjs(new Date(record.startDate)).format('DD/MM/YYYY HH:mm') : ''}
          <br />
          {record.endDate ? dayjs(new Date(record.endDate)).format('DD/MM/YYYY HH:mm') : ''}
        </>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 120,
      render: (status: BountyProgramStatus) => {
        if (status === BountyProgramStatus.upcoming) return <Tag color="warning">Sắp diễn ra</Tag>;
        if (status === BountyProgramStatus.processing) return <Tag color="red">Đang diễn ra</Tag>;
        if (status === BountyProgramStatus.finished) return <Tag color="default">Đã kết thúc</Tag>;
        return '';
      },
    },
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      width: 151,
      render: (_, record) => (record.createdAt ? dayjs(new Date(record.createdAt)).format('DD/MM/YYYY HH:mm') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      width: 151,
      render: (_, record) => (
        <>{record.updatedAt ? dayjs(new Date(record.updatedAt)).format('DD/MM/YYYY HH:mm') : ''}</>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              danger
              shape="circle"
              title="Delete"
              onClick={() =>
                Modal.confirm({
                  title: 'Xóa chương trình',
                  content: 'Bạn có muốn xóa chương trình này không?',
                  okText: 'Xóa',
                  okButtonProps: { danger: true },
                  onOk: () => deleteBountyProgram(record.id),
                })
              }>
              <DeleteOutlined />
            </Button>
            <Button
              type="link"
              shape="circle"
              title="Edit"
              onClick={() => navigate(`${paths.BOUNTY_PROGRAM}/${record.id}`)}>
              <EditOutlined twoToneColor={blue.primary} />
            </Button>
          </>
        );
      },
    },
  ];

  const onChange = useCallback((pagination: any, _: any, sorter: any) => {
    const newParams: BountyProgramQuery = {
      page: pagination.current || query.page,
      limit: pagination.pageSize || query.limit,
      sort: serializeSorter(sorter),
    };
    fetchBountyProgramList(newParams);
  }, []);

  return (
    <Table
      rowKey={(item) => item.id}
      loading={loading}
      columns={columns}
      dataSource={items}
      onChange={onChange}
      pagination={{
        current: query.page,
        pageSize: query.limit,
        total: total,
      }}
    />
  );
};
