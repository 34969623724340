import { useAccountStore } from '@/stores/account';
import { AccountItem, AccountStatus, FetchAccountListQuery, accountStatusNameMapping } from '@/types/account';
import { serializeSorter } from '@/utils/helpers/serializeSorter';
import { Button, Modal, Table, Tag, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useEffect } from 'react';
import format from 'date-fns/format';
import { EyeTwoTone, EditTwoTone, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/config/path';

export const AccountList = () => {
  const navigate = useNavigate();
  const { loading, query, total, items, deleteAccount, fetchAccountList } = useAccountStore();

  useEffect(() => {
    fetchAccountList().catch(() => message.error('Lỗi không thể tải tài khoản', 3));
  }, []);

  const onDeleteAccount = async (id: number) => {
    Modal.confirm({
      title: 'Xóa tài khoản',
      content: 'Bạn có muốn xóa tài khoản này không?',
      onOk: async () => {
        try {
          await deleteAccount(id);
          message.success('Xóa tài khoản thành công');
        } catch (error: any) {
          message.error('Lỗi không thể xóa tài khoản này!');
        }
      },
    });
  };

  const columns: ColumnProps<AccountItem>[] = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   width: 60,
    //   sorter: {
    //     multiple: 1,
    //   },
    // },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      sorter: { multiple: 2 },
    },
    {
      title: 'Tên tài khoản',
      dataIndex: 'fullName',
      sorter: { multiple: 2 },
    },
    {
      title: 'Tên phòng khám',
      dataIndex: 'record.accountBusiness.name',
      render: (_, record) => record.accountBusiness.name || 'N/A',
    },
    {
      title: 'Tỉnh / Thành Phố',
      dataIndex: 'record.accountBusiness.provinceName',
      render: (_, record) => record.accountBusiness.provinceName || 'N/A',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      sorter: { multiple: 2 },
      render: (status: AccountStatus) => (
        <Tag
          color={
            status === AccountStatus.active ? 'success' : status === AccountStatus.unverified ? 'orange' : undefined
          }>
          {accountStatusNameMapping[status]}
        </Tag>
      ),
    },
    {
      title: (
        <>
          Ngày khởi tạo
          <br />
          Ngày cập nhật
        </>
      ),
      dataIndex: 'createdAt',
      width: 150,
      sorter: {
        multiple: 4,
      },
      render: (_, record) => (
        <>
          {record.createdAt ? format(new Date(record.createdAt), 'dd/MM/yyyy kk:mm') : 'N/A'}
          <br />
          {record.updatedAt ? format(new Date(record.updatedAt), 'dd/MM/yyyy kk:mm') : 'N/A'}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 130,
      render: (_, record) => {
        return (
          <>
            <Button type="link" danger shape="circle" title="Delete" onClick={() => onDeleteAccount(record.id)}>
              <DeleteOutlined />
            </Button>
            <Button
              type="link"
              shape="circle"
              title="Edit"
              onClick={() => navigate(`${paths.ACCOUNT}/edit/${record.id}`)}>
              <EditTwoTone />
            </Button>
            <Button
              type="link"
              shape="circle"
              title="View"
              onClick={() => navigate(`${paths.ACCOUNT}/view/${record.id}`)}>
              <EyeTwoTone />
            </Button>
          </>
        );
      },
    },
  ];

  const onChange = useCallback((pagination: any, _: any, sorter: any) => {
    const newParams: FetchAccountListQuery = {
      page: pagination.current || query.page,
      limit: pagination.pageSize || query.limit,
      sort: serializeSorter(sorter),
    };
    fetchAccountList(newParams);
  }, []);

  return (
    <Table
      rowKey={(item) => item.id}
      loading={loading}
      columns={columns}
      dataSource={items}
      onChange={onChange}
      pagination={{
        current: query.page,
        pageSize: query.limit,
        total: total,
      }}
    />
  );
};
