import {
  CreateProductManufacturerRequest,
  ProductManufacturer,
  ProductManufacturerQuery,
  FetchProductManufacturerListResponse,
  UpdateProductManufacturerRequest,
} from '@/types/productManufacturer';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/product-manufacturer';
export const fetchProductManufacturerList = async (
  params: ProductManufacturerQuery
): Promise<FetchProductManufacturerListResponse> => {
  const { data } = await baseApi.get<FetchProductManufacturerListResponse>(ROOT_PATH, {
    params,
  });
  return data;
};

export const fetchProductManufacturer = async (id: number): Promise<ProductManufacturer> => {
  const { data } = await baseApi.get<ProductManufacturer>(`${ROOT_PATH}/${id}`);
  return data;
};

export const createProductManufacturer = async (
  input: CreateProductManufacturerRequest
): Promise<ProductManufacturer> => {
  const { data } = await baseApi.post(ROOT_PATH, serializeNullType(input));
  return data;
};

export const updateProductManufacturer = async (
  id: number,
  input: UpdateProductManufacturerRequest
): Promise<ProductManufacturer> => {
  const { data } = await baseApi.put(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deleteProductManufacturer = async (id: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${id}`);
  return data;
};
