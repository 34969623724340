import { LayoutContent } from '@/components/Layout';
import { UploadAuto } from '@/components/Upload/UploadAuto';
import { appConfigValueSelector, useAppConfigStore } from '@/stores/appConfig';
import { AppConfigKey } from '@/types/appConfig';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, InputNumber, Row, Space, Spin, message } from 'antd';
import { FC, useEffect } from 'react';

export const ThemeOptionsFooterPage: FC = () => {
  const footerConfigString = useAppConfigStore(appConfigValueSelector(AppConfigKey.FOOTER));
  const { loading, submitting, fetchManyAppConfigs, updateManyAppConfigs } = useAppConfigStore();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!footerConfigString) return;
    try {
      const footerConfig = JSON.parse(footerConfigString);
      form.setFieldsValue(footerConfig);
    } catch (error) {
      console.log(error);
    }
  }, [footerConfigString, form]);

  useEffect(() => {
    fetchManyAppConfigs({ keys: [AppConfigKey.FOOTER] });
  }, [fetchManyAppConfigs]);

  const onFinish = async (values: any) => {
    try {
      message.loading('Đang cập nhật...');
      await updateManyAppConfigs({ appConfigs: [{ key: AppConfigKey.FOOTER, value: JSON.stringify(values) }] });
      message.destroy();
      message.success('Cập nhật thành công');
    } catch (error) {
      message.destroy();
      message.error('Đã có lỗi xảy ra');
    }
  };

  return (
    <LayoutContent
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" onClick={() => form.submit()} disabled={loading || submitting} loading={submitting}>
            Lưu
          </Button>
        </div>
      }>
      <Spin spinning={loading}>
        <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={8}>
              <h3>Cột 1</h3>
              <Divider />
              <Form.Item label="Tiêu đề" name={['row1', 'title']}>
                <Input placeholder="Nhập tiêu đề" />
              </Form.Item>
              <Form.Item label="Thông tin công ty" name={['row1', 'companyInfo1']}>
                <Input placeholder="Thông tin công ty" />
              </Form.Item>
              <Form.Item label="Thông tin công ty" name={['row1', 'companyInfo2']}>
                <Input placeholder="Thông tin công ty" />
              </Form.Item>
              <Form.Item label="Địa chỉ" name={['row1', 'address']}>
                <Input placeholder="Nhập địa chỉ" />
              </Form.Item>
              <Form.Item label="Số điện thoại 1" name={['row1', 'phone1']}>
                <Input placeholder="Số điện thoại 1" />
              </Form.Item>
              <Form.Item label="Số điện thoại 2" name={['row1', 'phone2']}>
                <Input placeholder="Số điện thoại 2" />
              </Form.Item>
              <Form.Item label="Email" name={['row1', 'email']}>
                <Input placeholder="Nhập Email" />
              </Form.Item>
              <Row gutter={16} wrap={false}>
                <Col style={{ width: 116 }}>
                  <Form.Item label="Hình ảnh" name={['row1', 'imageUrl']}>
                    <UploadAuto maxSize={1024} />
                  </Form.Item>
                </Col>
                <Col flex={1}>
                  <Form.Item label="Kích thước chiều ngang" name={['row1', 'imageWidth']} initialValue={120}>
                    <InputNumber placeholder="Kích thước chiều ngang" />
                  </Form.Item>
                  <Form.Item label="Đường dẫn " name={['row1', 'imageLink']}>
                    <Input placeholder="Đường dẫn" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <h3>Cột 2</h3>
              <Divider />
              <Form.Item label="Tiêu đề" name={['row2', 'title']}>
                <Input placeholder="Nhập tiêu đề" />
              </Form.Item>
              <Form.Item label="Menu">
                <Form.List name={['row2', 'items']}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'title']}
                            rules={[{ required: true, message: 'Tiêu đề bắt buộc' }]}>
                            <Input placeholder="Tiêu đề" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'link']}
                            rules={[{ required: true, message: 'Liên kết bắt buộc' }]}>
                            <Input placeholder="Liên kết" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
              <Row gutter={16} wrap={false}>
                <Col style={{ width: 116 }}>
                  <Form.Item label="Hình ảnh" name={['row2', 'imageUrl']}>
                    <UploadAuto maxSize={1024} />
                  </Form.Item>
                </Col>
                <Col flex={1}>
                  <Form.Item label="Kích thước chiều ngang" name={['row2', 'imageWidth']} initialValue={120}>
                    <InputNumber placeholder="Kích thước chiều ngang" />
                  </Form.Item>
                  <Form.Item label="Đường dẫn" name={['row2', 'imageLink']}>
                    <InputNumber placeholder="Đường dẫn" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <h3>Cột 3</h3>
              <Divider />
              <Form.Item label="Tiêu đề" name={['row3', 'title']}>
                <Input placeholder="Nhập tiêu đề" />
              </Form.Item>
              <Form.Item label="Menu">
                <Form.List name={['row3', 'items']}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'title']}
                            rules={[{ required: true, message: 'Tiêu đề bắt buộc' }]}>
                            <Input placeholder="Tiêu đề" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'link']}
                            rules={[{ required: true, message: 'Liên kết bắt buộc' }]}>
                            <Input placeholder="Liên kết" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
              <Row gutter={16} wrap={false}>
                <Col style={{ width: 116 }}>
                  <Form.Item label="Hình ảnh" name={['row3', 'imageUrl']}>
                    <UploadAuto maxSize={1024} />
                  </Form.Item>
                </Col>
                <Col flex={1}>
                  <Form.Item label="Kích thước chiều ngang" name={['row3', 'imageWidth']} initialValue={120}>
                    <InputNumber placeholder="Kích thước chiều ngang" />
                  </Form.Item>
                  <Form.Item label="Đường dẫn" name={['row3', 'imageLink']}>
                    <InputNumber placeholder="Đường dẫn" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </LayoutContent>
  );
};
