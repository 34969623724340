import { Order, OrderStatus, orderReasonsMapping, orderStatusNameMapping } from '@/types/order';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Input, Modal, Select, Space, message } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import * as orderApi from '@/api/order';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { ShippingPartner, shippingPartnerNameMapping } from '@/types/orderShipping';

export type ActionsProps = {
  order: Order;
  onChangeSuccess?: () => void;
};
export type OrderStatusForm = {
  partner?: ShippingPartner;
  reason?: string;
  reasonDetail?: string;
};

export const Actions: FC<ActionsProps> = ({ order, onChangeSuccess }) => {
  const [form] = Form.useForm<OrderStatusForm>();
  const [newStatus, setNewStatus] = useState<OrderStatus | undefined>();
  const [nextStatuses, setNextStatuses] = useState<OrderStatus[]>([]);
  const [nextStatusesFetching, setNextStatusesFetching] = useState(true);

  const onOrderStatusChange = async (values: OrderStatusForm, status?: OrderStatus) => {
    status = status || newStatus;
    message.loading('Đang cập nhật...');
    try {
      await orderApi.updateOrderStatus(order.id, {
        status: status,
        reason: values.reason,
        partner: values.partner,
        reasonDetail: values.reasonDetail,
      });
      message.destroy();
      message.success(`Thay đổi trạng thái thành công`);
      onChangeSuccess?.();
      setNewStatus(undefined);
    } catch (error: any) {
      console.log(error);
      message.destroy();
      if (error?.response?.status === 403) {
        message.error(`Bạn không có quyền thay đổi sang trạng thái ${orderStatusNameMapping[status]}`);
      } else {
        message.error(`Đã xảy ra lỗi khi thay đổi trạng thái`);
      }
    }
  };
  const onOrderStatusChangeWithConfirm = (status: OrderStatus) => {
    Modal.confirm({
      title: 'Thay đổi trạng thái',
      content: (
        <>
          Bạn có muốn thay đổi trạng thái sang <strong>{orderStatusNameMapping[status]}</strong> không?
        </>
      ),
      cancelText: 'Hủy bỏ',
      okText: 'Xác nhận',
      onOk: () => onOrderStatusChange({}, status),
    });
  };

  useEffect(() => {
    setNextStatusesFetching(true);
    orderApi
      .fetchNextStatuses(order.id)
      .then((nextStatuses) => {
        setNextStatuses(nextStatuses);
      })
      .finally(() => setNextStatusesFetching(false));
  }, [order]);

  const prevShownRef = useRef(!!nextStatuses.length);
  if (
    (!prevShownRef.current && (nextStatusesFetching || !nextStatuses.length)) ||
    (prevShownRef.current && !nextStatuses.length)
  ) {
    prevShownRef.current = false;
    return null;
  }
  prevShownRef.current = true;

  return (
    <>
      <Dropdown
        menu={{
          items: nextStatuses.map((nextStatus) => ({
            key: nextStatus,
            label: orderStatusNameMapping[nextStatus],
            danger: nextStatus === OrderStatus.cancelled,
          })),
          onClick: ({ key }) => {
            const nextStatus = key as OrderStatus;
            // if (nextStatus === OrderStatus.cancelled) {
            //   setNewStatus(OrderStatus.cancelled);
            //   setOpenChangeStatus(true);
            // } else
            if (nextStatus === OrderStatus.waitToDelivery) {
              setNewStatus(OrderStatus.waitToDelivery);
            } else {
              onOrderStatusChangeWithConfirm(nextStatus);
            }
          },
        }}>
        <Button type="primary">
          <Space>
            Cập nhật trạng thái
            {nextStatusesFetching && <LoadingOutlined />}
            {!nextStatusesFetching && <DownOutlined />}
          </Space>
        </Button>
      </Dropdown>
      <Modal
        title={`Cập nhật trạn thái sang ${newStatus ? orderStatusNameMapping[newStatus] : ''}`}
        open={newStatus === OrderStatus.cancelled}
        onCancel={() => setNewStatus(undefined)}
        okText="Xác nhận"
        onOk={form.submit}>
        <Form form={form} labelCol={{ span: 24 }} style={{ marginTop: 20 }} onFinish={onOrderStatusChange}>
          <Form.Item name="reason" label="Lý do">
            <Select
              allowClear
              options={Object.keys(orderReasonsMapping).map((reason) => ({
                value: reason,
                label: orderReasonsMapping[reason],
              }))}
              filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
            />
          </Form.Item>
          <Form.Item name="reasonDetail" label="Chi tiết">
            <Input.TextArea />
          </Form.Item>
          <div style={{ display: 'none' }}>
            <Button htmlType="submit" hidden></Button>
          </div>
        </Form>
      </Modal>
      <Modal
        title={`Chọn đơn vị vận chuyển`}
        open={newStatus === OrderStatus.waitToDelivery}
        onCancel={() => setNewStatus(undefined)}
        okText="Xác nhận"
        onOk={form.submit}>
        <Form form={form} labelCol={{ span: 24 }} style={{ marginTop: 20 }} onFinish={onOrderStatusChange}>
          <Form.Item name="partner" label="Đơn vị giao hàng" initialValue={ShippingPartner.etg}>
            <Select
              allowClear
              options={Object.keys(shippingPartnerNameMapping).map((shippingPartner) => ({
                value: shippingPartner,
                label: shippingPartnerNameMapping[shippingPartner as ShippingPartner],
                disabled: shippingPartner === ShippingPartner.ghn,
              }))}
              filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
            />
          </Form.Item>
          <div style={{ display: 'none' }}>
            <Button htmlType="submit" hidden></Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
