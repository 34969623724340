import { LayoutContent } from '@/components/Layout';
import { Button, Col, Row, Spin, Tag } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as couponApi from '@/api/coupon';
import { Coupon, CouponGroup, CouponStatus, DiscountType } from '@/types/coupon';
import { paths } from '@/config/path';
import { numberToVNDPrice } from '@/utils/helpers/pricing';
import dayjs from 'dayjs';
import { AccountList } from './components/AccountList';

export type CouponDetailPageProps = {};
export const CouponDetailPage: FC<CouponDetailPageProps> = () => {
  const { id: _id, group } = useParams<{ group: CouponGroup; id: string }>();
  const id = parseInt(_id);
  const [loading, setLoading] = useState(true);
  const [coupon, setCoupon] = useState<Coupon>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    couponApi
      .fetchCoupon(id)
      .then((data) => {
        setCoupon(data);
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  return (
    <Spin spinning={loading}>
      <LayoutContent
        header={{
          leftElement: <h3>Mã giảm #{coupon?.code || '...'}</h3>,
          rightElement: (
            <Button
              type="primary"
              onClick={() =>
                navigate(`${paths.COUPON_ALL}/${group}/${id}`, {
                  state: { prevPath: `${paths.COUPON_ALL}/${group}/view/${id}` },
                })
              }>
              Cập nhật
            </Button>
          ),
        }}
        sections={[
          {
            key: 'info',
            title: 'Thông tin mã giảm',
            content: (
              <>
                <Row gutter={32}>
                  <Col span={12}>
                    <Row gutter={[32, 24]}>
                      <Col span={8}>Code</Col>
                      <Col span={16}>
                        <strong>{coupon?.code}</strong>
                      </Col>

                      <Col span={8}>Tên mã giảm</Col>
                      <Col span={16}>
                        <strong>{coupon?.name}</strong>
                      </Col>

                      <Col span={8}>Giá giảm</Col>
                      <Col span={16}>
                        {!!coupon && (
                          <strong>
                            {DiscountType.exactTotalPrice === coupon.discountType && numberToVNDPrice(coupon.discount)}
                            {DiscountType.percentTotalPrice === coupon.discountType && <>{coupon.discount}%</>}
                          </strong>
                        )}
                      </Col>

                      <Col span={8}>Giá giảm tối đa</Col>
                      <Col span={16}>
                        <strong>{coupon?.maxPrice}</strong>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row gutter={[32, 24]}>
                      <Col span={8}>Trạng thái</Col>
                      <Col span={16}>
                        {coupon?.status === CouponStatus.active && <Tag color="success">ACTIVE</Tag>}
                        {coupon?.status === CouponStatus.inactive && <Tag color="error">INACTIVE</Tag>}
                      </Col>

                      <Col span={8}>Ngày hết hạn</Col>
                      <Col span={16}>
                        <strong>
                          {coupon?.expireDate ? dayjs(new Date(coupon.expireDate)).format('DD/MM/YYYY HH:mm') : ''}
                        </strong>
                      </Col>

                      <Col span={8}>SL tối đa cho mỗi user</Col>
                      <Col span={16}>
                        <strong>{coupon?.quantityPerUser}</strong>
                      </Col>

                      <Col span={8}>SL tối đa</Col>
                      <Col span={16}>
                        <strong>
                          {coupon?.appliedQuantity} / {coupon?.quantity || 'Không giới hạn'}
                        </strong>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
              </>
            ),
          },
          {
            key: 'condition',
            title: 'Điều kiện áp dụng',
            content: (
              <>
                <Row gutter={32}>
                  <Col span={12}>
                    <Row gutter={[32, 24]}>
                      <Col span={8}>Tổng đơn từ</Col>
                      <Col span={16}>
                        {coupon?.conditionMinTotalPrice ? (
                          <strong>{numberToVNDPrice(coupon.conditionMinTotalPrice)}</strong>
                        ) : (
                          <i>{'<Không áp dụng>'}</i>
                        )}
                      </Col>
                      <Col span={8}>Áp dụng tất cả user</Col>
                      <Col span={16}>
                        <strong>{coupon?.applyAllUser ? 'Có' : 'Không'}</strong>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row gutter={[32, 24]}>
                      <Col span={8}>Tổng đơn đến</Col>
                      <Col span={16}>
                        {coupon?.conditionMaxTotalPrice ? (
                          <strong>{numberToVNDPrice(coupon.conditionMaxTotalPrice)}</strong>
                        ) : (
                          <i>{'<Không áp dụng>'}</i>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
              </>
            ),
          },
          {
            key: 'accounts',
            content: <AccountList couponId={coupon?.id} />,
          },
        ]}
      />
    </Spin>
  );
};
