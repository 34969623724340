import { FC, useMemo } from 'react';
import { Menu as AntMenu } from 'antd';
import { useMenuItems } from '@/utils/hooks/useMenuItems';

import styles from './styles.module.scss';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

type MenuLayoutProps = {
  defaultCollapsed?: boolean;
};

export const MenuLayout: FC<MenuLayoutProps> = ({ defaultCollapsed }) => {
  const navigate = useNavigate();
  const selectedKeys = useCurrentSelectedKeys();
  const menuItems = useMenuItems();

  return (
    <AntMenu
      theme="light"
      mode="inline"
      className={styles.menu}
      items={menuItems.map((item) => ({
        ...item,
        onClick: () => !item.children?.length && item.path && navigate(item.path),
        children: item.children?.map((item) => ({
          ...item,
          onClick: () => item.path && navigate(item.path),
        })),
      }))}
      defaultOpenKeys={defaultCollapsed ? [] : selectedKeys}
      selectedKeys={selectedKeys}
    />
  );
};

const useCurrentSelectedKeys = () => {
  const { pathname } = useLocation();
  const menuItems = useMenuItems();

  return useMemo(() => {
    const selectedKeys: string[] = [];
    menuItems.forEach((item) => {
      if (item.path && matchPath(item.path, pathname)) selectedKeys.push(item.key);
      item.children?.forEach((childItem) => {
        if (
          (childItem.path && matchPath(childItem.path, pathname)) ||
          (childItem.path && pathname.match(`${childItem.path}/`))
        ) {
          selectedKeys.push(item.key);
          selectedKeys.push(childItem.key);
        }
      });
    });
    return selectedKeys;
  }, [pathname, menuItems]);
};
