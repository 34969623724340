import {
  ProductQuery,
  FetchProductListResponse,
  Product,
  CreateProductRequest,
  UpdateProductRequest,
} from '@/types/product';
import { baseApi } from './common/baseApi';

const ROOT_PATH = '/product/admin';

export const fetchProductList = async (params: ProductQuery): Promise<FetchProductListResponse> => {
  const { data } = await baseApi.get<FetchProductListResponse>(ROOT_PATH, {
    params: { ...params, productCategoryIds: params.productCategoryId },
  });
  return data;
};

export const fetchProduct = async (id: number): Promise<Product> => {
  const { data } = await baseApi.get<Product>(`${ROOT_PATH}/${id}`);
  return data;
};

export const createProduct = async (input: CreateProductRequest): Promise<Product> => {
  const { data } = await baseApi.post(ROOT_PATH, input);
  return data;
};

export const updateProduct = async (id: number, input: UpdateProductRequest): Promise<Product> => {
  const { data } = await baseApi.put(`${ROOT_PATH}/${id}`, input);
  return data;
};

export const deleteProduct = async (ProductId: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${ProductId}`);
  return data;
};
