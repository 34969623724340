import { Button, Col, DatePicker, Form, InputNumber, Row } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';

export type FormData = {
  minPoint: number;
  maxPoint: number;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
};
export type FilterProps = {
  onFinish?: (data: FormData) => void;
};
export const Filter: FC<FilterProps> = ({ onFinish }) => {
  const [form] = Form.useForm<FormData>();

  return (
    <Form onFinish={onFinish} form={form} labelCol={{ span: 24 }}>
      <Row gutter={16} wrap={false}>
        <Col flex={1}>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item name="minPoint" label="Điểm từ" rules={[{ required: true, message: 'Trường bắt buộc' }]}>
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="maxPoint" label="Điểm đến" rules={[{ required: true, message: 'Trường bắt buộc' }]}>
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="startDate"
                label="Ngày bắt đầu"
                getValueFromEvent={(value: dayjs.Dayjs) => value?.startOf('D')}
                rules={[{ required: true, message: 'Trường bắt buộc' }]}>
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="endDate"
                label="Ngày kết thúc"
                getValueFromEvent={(value: dayjs.Dayjs) => value?.endOf('D')}
                rules={[{ required: true, message: 'Trường bắt buộc' }]}>
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit" style={{ marginTop: 40 }}>
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
