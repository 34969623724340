import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { Filter } from './components/Filter';
import { ProductCategoryList } from './components/ProductCategoryList';
import { Button } from 'antd';
import { useProductCategoryCreationModalStore } from '@/stores/productCategory';
import { ProductCategoryCreationModal } from './components/ProductCategoryCreationModal';

export const ProductCategoriesPage: FC = () => {
  const { openModal } = useProductCategoryCreationModalStore();
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <Button type="primary" onClick={() => openModal()}>
            Thêm mới
          </Button>
        ),
      }}>
      <ProductCategoryList />
      <ProductCategoryCreationModal />
    </LayoutContent>
  );
};
