export function serializeNullType<T = any>(item: any): T {
  if (typeof item === 'undefined') return null;
  if (Array.isArray(item)) {
    return item.map(serializeNullType) as T;
  }
  if (typeof item === 'object' && item !== null) {
    const newObject = { ...item };
    Object.keys(newObject).forEach((key) => {
      newObject[key] = serializeNullType(newObject[key]);
    });
    return newObject;
  }
  return item;
}
