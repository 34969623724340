import { LayoutContent } from '@/components/Layout';
import { FC, useEffect, useState } from 'react';
import { AccountPointList } from './components/AccountPointList';
import { Filter } from './components/Filter';
import { FormData } from './components/Filter';
import { AccountWithPoint } from '@/types/account';
import { fetchAllAccountsWithPoints } from '@/api/account';
import { Button, Col, Row, message } from 'antd';
import { CouponModal } from './components/CouponModal';
import * as couponAccountApi from '@/api/couponAccount';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/config/path';
import { CouponGroup } from '@/types/coupon';

export const AccountPointsPage: FC = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<FormData | undefined>();
  const [items, setItems] = useState<AccountWithPoint[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!filter) return;

    setLoading(true);
    fetchAllAccountsWithPoints({
      endDate: filter.endDate.toISOString(),
      startDate: filter.startDate.toISOString(),
      maxPoint: filter.maxPoint,
      minPoint: filter.minPoint,
    })
      .then((items) => {
        setItems(items);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filter]);

  const onAssignCoupon = async ({ couponId }: { couponId: number }) => {
    try {
      await couponAccountApi.createManyCouponAccounts({
        accountIds: items.map((item) => item.id),
        couponId,
        quantity: 1,
      });
      message.success('Đã gửi tặng mã giảm thành công');
      navigate(`${paths.COUPON_ALL}/${CouponGroup.accountRank}/view/${couponId}`);
    } catch (error: any) {
      if (error?.response?.data?.name === 'assigned') {
        message.error('Mã giảm đã được tặng');
      } else {
        message.error('Gửi tặng mã giảm không thành công');
      }
      return Promise.reject(error);
    }
  };

  return (
    <>
      <LayoutContent
        header={{
          leftElement: <Filter onFinish={setFilter} />,
        }}
        sections={
          filter
            ? [
                ...(items.length
                  ? [
                      {
                        key: 'info',
                        content: (
                          <Row gutter={16} align="middle">
                            <Col flex={1}>Tìm thấy {items.length} người dùng thoải điều kiện</Col>
                            <Col>
                              <Button type="primary" onClick={() => setOpen(true)}>
                                Gửi tặng mã giảm
                              </Button>
                            </Col>
                          </Row>
                        ),
                      },
                    ]
                  : []),
                {
                  key: 'table',
                  content: <AccountPointList items={items} loading={loading} />,
                },
              ]
            : []
        }
      />
      <CouponModal open={open} onCancel={() => setOpen(false)} onFinish={onAssignCoupon} />
    </>
  );
};
