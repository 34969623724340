import { blue } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, InputNumber, Modal, Popconfirm, Row, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FC, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as couponAccountApi from '@/api/couponAccount';
import { CouponAccount } from '@/types/couponAccount';
import { AccountSelect } from '@/components/AccountSelect';

export type AccountFormValues = {
  accountId: number;
  quantity: number;
};

export type AccountListProps = {
  couponId?: number;
};
export const AccountList: FC<AccountListProps> = ({ couponId }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<CouponAccount[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm<AccountFormValues>();
  const [editorId, setEditorId] = useState<number>();

  const onFinish = async (value: AccountFormValues) => {
    try {
      setSubmitting(true);
      if (!editorId) {
        await couponAccountApi.createCouponAccount({
          accountId: value.accountId,
          couponId,
          quantity: value.quantity,
        });
      } else {
        await couponAccountApi.updateCouponAccount(editorId, {
          quantity: value.quantity,
        });
      }
      setModalOpen(false);
      form.resetFields();
      setEditorId(undefined);
      message.success(editorId ? 'Cập nhật người dùng thành công' : 'Thêm mới người dùng thành công');
      fetchAccountList();
      setSubmitting(false);
    } catch (error: any) {
      setSubmitting(false);
      if (error?.response?.data?.name === 'account_is_exist') {
        form.setFields([
          {
            name: 'accountId',
            errors: ['Người dùng đã tồn tại!'],
          },
        ]);
      } else {
        message.error(editorId ? 'Cập nhật người dùng không thành công' : 'Thêm mới người dùng không thành công');
      }
    }
  };

  const onDeleteCouponAccount = async (couponAccountId: number) => {
    try {
      await couponAccountApi.deleteCouponAccount(couponAccountId);
      message.success('Đã xóa 1 tài khoản nhận coupon');
      fetchAccountList();
    } catch (error) {
      message.error('Xóa không thành công');
    }
  };

  const fetchAccountList = useCallback(async () => {
    setLoading(true);
    try {
      const items = await couponAccountApi.fetchAllCouponAccountsByCouponId(couponId);
      setItems(items);
      setLoading(false);
    } catch (error) {
      message.error('Tải danh sách người dùng không thành công');
      setLoading(false);
    }
  }, [couponId, setLoading, setItems]);

  useEffect(() => {
    if (couponId) fetchAccountList();
  }, [fetchAccountList, couponId]);

  const columns: ColumnProps<CouponAccount>[] = [
    {
      title: 'Người dùng',
      dataIndex: 'accountId',
      sorter: { multiple: 1 },
      render: (_, record) => (
        <>
          <strong>{record.account ? record.account.fullName : '<Tài khoản đã bị xóa>'}</strong>
          <br />
          {record.account ? record.account.phone : '...'}
        </>
      ),
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      sorter: { multiple: 2 },
      width: 130,
    },
    {
      title: 'Đã sử dụng',
      dataIndex: 'appliedQuantity',
      sorter: { multiple: 2 },
      width: 130,
    },
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      width: 150,
      sorter: {
        multiple: 4,
      },
      render: (createdAt) => (createdAt ? dayjs(new Date(createdAt)).format('DD/MM/YYYY HH:mm') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      width: 150,
      sorter: {
        multiple: 5,
      },
      render: (updatedAt) => (updatedAt ? dayjs(new Date(updatedAt)).format('DD/MM/YYYY HH:mm') : ''),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              shape="circle"
              title="Edit"
              onClick={() => {
                form.setFieldsValue({
                  accountId: record.accountId,
                  quantity: record.quantity,
                });
                setEditorId(record.id);
                setModalOpen(true);
              }}>
              <EditOutlined twoToneColor={blue.primary} />
            </Button>
            <Popconfirm
              title="Xóa tải khoản nhận coupon"
              description="Bạn có muốn xóa tài khoản nhận coupon này không?"
              onConfirm={async () => onDeleteCouponAccount(record.id)}
              okText="Yes"
              cancelText="No">
              <Button danger type="link" shape="circle" title="Delete">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row align="middle">
        <Col flex={1}>
          <h2 style={{ fontSize: 16, fontWeight: 600 }}>Người dùng nhận coupons</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setEditorId(undefined);
              setModalOpen(true);
            }}>
            Thêm người dùng
          </Button>
        </Col>
      </Row>
      <Divider />
      {!!couponId && (
        <>
          <Table
            rowKey={(item) => item.id}
            loading={loading}
            columns={columns}
            dataSource={items}
            pagination={{ pageSize: 20 }}
          />
          <Modal
            title={editorId ? 'Cập nhật người dùng nhận coupon' : 'Thêm người dùng nhận coupon'}
            open={modalOpen}
            cancelText="Hủy"
            okText={editorId ? 'Cập nhật' : 'Thêm'}
            onCancel={() => {
              form.resetFields();
              setEditorId(undefined);
              setModalOpen(false);
            }}
            onOk={form.submit}
            maskClosable={false}
            okButtonProps={{ loading: submitting }}>
            <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
              <Form.Item
                label="Người dùng"
                name="accountId"
                rules={[{ required: true, message: 'Người dùng bắt buộc' }]}>
                <AccountSelect disabled={!!editorId} />
              </Form.Item>
              <Form.Item
                label="Số lượng"
                name="quantity"
                rules={[{ required: true, message: 'Số lượng bắt buộc' }]}
                initialValue={1}>
                <InputNumber placeholder="Nhập số lượng" />
              </Form.Item>
              <div style={{ display: 'none' }}>
                <Button htmlType="submit" hidden></Button>
              </div>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};
