import { LoginData, LoginFormValues } from '@/types/auth'
import { UserDetail } from '@/types/user'
import { create } from 'zustand'
import * as authApi from '@/api/auth'
import { removeDefaultToken, setDefaultToken } from '@/api/common/baseApi'

export type AccountState = {
  loading: boolean
  firstLocationHref?: string
  userDetail?: UserDetail
  login: (data: LoginFormValues) => Promise<void>
  logout: () => Promise<void>
  loadUser: () => void
}

export const useAuthStore = create<AccountState>((set) => ({
  loading: false,
  userDetail: undefined,
  firstLocationHref: undefined,
  login: async (input) => {
    try {
      set({ loading: true })
      const data = await authApi.login(input)
      setDefaultToken(data.accessToken)
      setLoginDataStorage(data)
      set({ userDetail: data.user })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      set({ loading: false })
    }
  },
  logout: async () => {
    removeLoginDataStorage()
    removeDefaultToken()
    set({ userDetail: undefined })
  },
  loadUser: () => {
    const data = getLoginDataStorage()
    if (!data) return
    setDefaultToken(data.accessToken)
    set({ userDetail: data.user })
  }
}))

const CACHE_KEY = 'authorizedUser'

export const setLoginDataStorage = (data: LoginData) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(CACHE_KEY, JSON.stringify(data))
  }
}

export const getLoginDataStorage = (): LoginData | undefined => {
  if (typeof window !== 'undefined') {
    const found = window.localStorage.getItem(CACHE_KEY)
    if (found) {
      return JSON.parse(found)
    }
  }
  return undefined
}

export const removeLoginDataStorage = (): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(CACHE_KEY)
  }
}
