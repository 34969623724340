import {
  CreateProductCategoryRequest,
  ProductCategory,
  ProductCategoryQuery,
  ProductCategoryResponse,
  UpdateProductCategoryRequest,
} from '@/types/productCategory';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/product-category';
export const fetchProductCategoryList = async (params: ProductCategoryQuery): Promise<ProductCategoryResponse> => {
  const { data } = await baseApi.get<ProductCategoryResponse>(ROOT_PATH, {
    params,
  });
  return data;
};

export const fetchProductCategory = async (ProductCategoryId: number): Promise<ProductCategory> => {
  const { data } = await baseApi.get<ProductCategory>(`${ROOT_PATH}/${ProductCategoryId}`);
  return data;
};

export const createProductCategory = async (input: CreateProductCategoryRequest): Promise<ProductCategory> => {
  const { data } = await baseApi.post(ROOT_PATH, serializeNullType(input));
  return data;
};

export const updateProductCategory = async (
  id: number,
  input: UpdateProductCategoryRequest
): Promise<ProductCategory> => {
  const { data } = await baseApi.put(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deleteProductCategory = async (ProductCategoryId: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${ProductCategoryId}`);
  return data;
};
