import { create } from 'zustand';
import * as orderApi from '@/api/order';
import { FetchOrderListRequest, OrderLite } from '@/types/order';

export type OrderState = {
  loading: boolean;
  loaded: boolean;
  submitting: boolean;
  query: FetchOrderListRequest;
  total: number;
  items: OrderLite[];
  fetchOrderList: (query?: Partial<FetchOrderListRequest>) => Promise<void>;
};
export const useOrderStore = create<OrderState>((set, get) => ({
  loading: false,
  loaded: false,
  submitting: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
  },
  total: 0,
  items: [],
  fetchOrderList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const res = await orderApi.fetchOrderList(query);
      set({
        loading: false,
        loaded: true,
        total: res.metadata.total,
        items: res.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
}));
