import { AccountBill, UpsertAccountBill } from './accountBill';
import { AccountBusiness, UpsertAccountBusiness } from './accountBusiness';
import { AccountShipping, UpsertAccountShipping } from './accountShipping';
import { BankAccount, UpsertBankAccount } from './bankAccount';

export enum AccountStatus {
  active = 'active',
  unverified = 'unverified',
  inactive = 'inactive',
}
export const accountStatusNameMapping: Record<AccountStatus, string> = {
  [AccountStatus.active]: 'Kích hoạt',
  [AccountStatus.unverified]: 'Chờ duyệt',
  [AccountStatus.inactive]: 'Chưa kích hoạt',
};
export enum AccountType {
  Pharmacy = 'pharmacy', // Nhà thuốc
  MedicalCenter = 'medical_center', // Trung tâm y tế
  Clinic = 'clinic', // Phòng khám
  DentalClinic = 'dental_clinic', // Nha khoa
  Drugstore = 'drugstore', // Quầy thuốc
  BeautySalon = 'beauty_salon', // Thẩm mỹ viện
  Hospital = 'hospital', // Bệnh viện
  PharmaceuticalCompany = 'pharmaceutical_company', // Công ty dược phẩm
}
export const accountTypenameMapping: Record<AccountType, string> = {
  [AccountType.Pharmacy]: 'Nhà thuốc',
  [AccountType.MedicalCenter]: 'Trung tâm y tế',
  [AccountType.Clinic]: 'Phòng khám',
  [AccountType.DentalClinic]: 'Nha khoa',
  [AccountType.Drugstore]: 'Quầy thuốc',
  [AccountType.BeautySalon]: 'Thẩm mỹ viện',
  [AccountType.Hospital]: 'Bệnh viện',
  [AccountType.PharmaceuticalCompany]: 'Công ty dược phẩm',
};
export type AccountItem = {
  id: number;
  phone: string;
  needToChangePassword: boolean;
  fullName: string;
  email?: string;
  isEmailVerified?: boolean;
  point: number;
  accountType: AccountType;
  accountBusinessId: number;
  accountBusiness: AccountBusiness;
  bankAccountId: number;
  bankAccount: BankAccount;
  accountBills: AccountBill[];
  accountShippings: AccountShipping[];
  status: AccountStatus;
  avatarUrl?: string;
  createdAt: string;
  updatedAt: string;
};

export type Account = AccountItem;

export type UpdateAccountRequest = {
  password?: string;
  needToChangePassword?: boolean;
  fullName?: string;
  email?: string;
  accountType?: AccountType;
  accountBusiness?: UpsertAccountBusiness;
  bankAccount?: UpsertBankAccount;
  accountBills?: UpsertAccountBill[];
  accountShippings?: UpsertAccountShipping[];
  avatarUrl?: string;
  status?: AccountStatus;
};

export type CreateAccountRequest = {
  phone: number;
  password: string;
  needToChangePassword?: boolean;
  fullName: string;
  email?: string;
  accountType: AccountType;
  accountBusiness?: UpsertAccountBusiness;
  bankAccount?: UpsertBankAccount;
  accountBills?: UpsertAccountBill[];
  accountShippings?: UpsertAccountShipping[];
  avatarUrl?: string;
  status: AccountStatus;
};

export type AccountForm = {
  phone: number;
  password?: string;
  confirmPassword?: string;
  fullName?: string;
  email?: string;
  accountType?: AccountType;
  accountBusiness?: UpsertAccountBusiness;
  bankAccount?: UpsertBankAccount;
  accountBills?: UpsertAccountBill[];
  accountShippings?: UpsertAccountShipping[];
  avatarUrl?: string;
  status?: AccountStatus;
};

export type UpdateAccountStatusRequest = {
  status: AccountStatus;
};

export type FetchAccountListQuery = {
  search?: string;
  status?: AccountStatus;
  sort?: string;
  page: number;
  limit: number;
};

export type FetchAccountListResponse = {
  records: AccountItem[];
  metadata: {
    total: number;
  };
};

export type AccountWithPoint = {
  id: number;
  phone: string;
  fullName: string;
  email?: string;
  status: AccountStatus;
  point: number;
};

export type QueryAllAccountsWithPointRequest = {
  minPoint: number;
  maxPoint: number;
  startDate: string;
  endDate: string;
};
