import { LoginRequest, LoginResponse } from '@/types/auth'
import { baseApi } from './common/baseApi'

export const login = async (input: LoginRequest): Promise<LoginResponse> => {
  const { data } = await baseApi.post<LoginResponse>('/auth/user/login', {
    username: input.username,
    password: input.password
  })
  return data
}
