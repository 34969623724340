import { useCouponStore } from '@/stores/coupon';
import { Coupon, CouponGroup, CouponQuery, CouponStatus, DiscountType } from '@/types/coupon';
import { serializeSorter } from '@/utils/helpers/serializeSorter';
import { blue, cyan } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Modal, Table, Tag, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { numberToVNDPrice } from '@/utils/helpers/pricing';
import dayjs from 'dayjs';
import { paths } from '@/config/path';

export type CouponListProps = {
  group: CouponGroup;
};
export const CouponList: FC<CouponListProps> = ({ group }) => {
  const { loading, query, total, items, reset, deleteCoupon, fetchCouponList } = useCouponStore();
  const navigate = useNavigate();
  const prevGroupRef = useRef<CouponGroup>(group);

  if (prevGroupRef.current !== group) {
    reset();
    prevGroupRef.current = group;
  }
  useEffect(() => {
    fetchCouponList({ group }).catch(() => message.error('Lỗi không thể tải mã giảm', 3));
  }, [group]);

  const columns: ColumnProps<Coupon>[] = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: 90,
      sorter: { multiple: 1 },
    },
    {
      title: 'Tên mã giảm',
      dataIndex: 'name',
    },
    {
      title: 'Giá giảm',
      dataIndex: 'discount',
      width: 110,
      render: (_, record) => (
        <>
          {DiscountType.exactTotalPrice === record.discountType && numberToVNDPrice(record.discount)}
          {DiscountType.percentTotalPrice === record.discountType && <>{record.discount}%</>}
        </>
      ),
    },
    {
      title: 'Thời hạn',
      dataIndex: 'expireDate',
      sorter: { multiple: 2 },
      width: 150,
      render: (expireDate, record) => (
        <>
          {expireDate ? dayjs(new Date(expireDate)).format('DD/MM/YYYY HH:mm') : ''}
          <br />
          {record.isExpiredDate && <Tag color="error">Hết hạn</Tag>}
        </>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 120,
      sorter: { multiple: 2 },
      render: (status: CouponStatus) => {
        if (status === CouponStatus.active) return <Tag color="success">ACTIVE</Tag>;
        if (status === CouponStatus.inactive) return <Tag color="error">INACTIVE</Tag>;
        return '';
      },
    },
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      width: 151,
      sorter: {
        multiple: 4,
      },
      render: (_, record) => (record.createdAt ? dayjs(new Date(record.createdAt)).format('DD/MM/YYYY HH:mm') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      width: 151,
      sorter: {
        multiple: 4,
      },
      render: (_, record) => (
        <>{record.updatedAt ? dayjs(new Date(record.updatedAt)).format('DD/MM/YYYY HH:mm') : ''}</>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 130,
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              danger
              shape="circle"
              title="Delete"
              onClick={() =>
                Modal.confirm({
                  title: 'Xóa mã giảm',
                  content: 'Bạn có muốn xóa mã giảm này không?',
                  okText: 'Xóa',
                  okButtonProps: { danger: true },
                  onOk: () => deleteCoupon(record.id),
                })
              }>
              <DeleteOutlined />
            </Button>
            <Button
              type="link"
              shape="circle"
              title="Edit"
              onClick={() =>
                navigate(`${paths.COUPON_ALL}/${group}/${record.id}`, {
                  state: { prevPath: `${paths.COUPON_ALL}/${group}` },
                })
              }>
              <EditOutlined twoToneColor={blue.primary} />
            </Button>
            <Button
              type="link"
              shape="circle"
              title="View"
              onClick={() =>
                navigate(`${paths.COUPON_ALL}/${group}/view/${record.id}`, {
                  state: { prevPath: `${paths.COUPON_ALL}/${group}` },
                })
              }>
              <EyeTwoTone twoToneColor={cyan.primary} />
            </Button>
          </>
        );
      },
    },
  ];

  const onChange = useCallback((pagination: any, _: any, sorter: any) => {
    const newParams: CouponQuery = {
      page: pagination.current || query.page,
      limit: pagination.pageSize || query.limit,
      sort: serializeSorter(sorter),
    };
    fetchCouponList(newParams);
  }, []);

  return (
    <Table
      rowKey={(item) => item.id}
      loading={loading}
      columns={columns}
      dataSource={items}
      onChange={onChange}
      pagination={{
        current: query.page,
        pageSize: query.limit,
        total: total,
      }}
    />
  );
};
