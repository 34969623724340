import { FC, ReactNode, useCallback } from 'react'
import clsx from 'clsx'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import {
  Button,
  Dropdown,
  Layout as AntLayout,
  LayoutProps,
  Space,
  Typography
} from 'antd'
import { useNavigate } from 'react-router-dom'

import styles from './styles.module.scss'
import { useAuthStore } from '@/stores/auth'
import { paths } from '@/config/path'

const { Header } = AntLayout

export interface UserInfoHeaderType extends Omit<LayoutProps, 'title'> {
  title?: ReactNode
  className?: string
}

export const LogoutAction: FC = () => {
  const { logout, userDetail } = useAuthStore()
  const navigate = useNavigate()
  const onLogOut = useCallback(async () => {
    navigate(paths.LOGIN)
    await logout()
  }, [logout, navigate])

  return userDetail ? (
    <Dropdown
      menu={{
        items: [
          {
            key: 'log-out',
            onClick: onLogOut,
            label: (
              <Space data-testid="log-out-action">
                <LogoutOutlined />
                <Typography.Text>Logout</Typography.Text>
              </Space>
            )
          }
        ]
      }}
      placement="bottomRight">
      <Button
        type="text"
        className={styles.displayName}
        data-testid={'drop-down-menu'}>
        <UserOutlined />{' '}
        <Typography.Text>{userDetail.username}</Typography.Text>
      </Button>
    </Dropdown>
  ) : (
    <div />
  )
}

export const UserInfoHeader: FC<UserInfoHeaderType> = ({
  title,
  className
}) => {
  return (
    <Header className={clsx(styles.userInfoHeader, className)}>
      <div className={styles.titleBox}>
        {typeof title === 'string' ? (
          <Typography.Title className={styles.titleString} level={5}>
            {title}
          </Typography.Title>
        ) : (
          title
        )}
      </div>
      <div className={styles.actionContainer}>
        <LogoutAction />
      </div>
    </Header>
  )
}
