import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { Filter } from './components/Filter';
import { ProductBrandList } from './components/ProductBrandList';
import { Button } from 'antd';
import { useProductBrandCreationModalStore } from '@/stores/productBrand';
import { ProductBrandCreationModal } from './components/ProductBrandCreationModal';

export const ProductBrandsPage: FC = () => {
  const { openModal } = useProductBrandCreationModalStore();
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <Button type="primary" onClick={() => openModal()}>
            Thêm mới
          </Button>
        ),
      }}>
      <ProductBrandList />
      <ProductBrandCreationModal />
    </LayoutContent>
  );
};
