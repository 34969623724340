import { create } from 'zustand';
import * as productApi from '@/api/product';
import {
  ProductQuery,
  CreateProductRequest,
  UpdateProductRequest,
  ProductItem,
  ProductSelectOption,
} from '@/types/product';

export const productListFields = [
  'id',
  'name',
  'hidden',
  'slug',
  'imageUrls',
  'price',
  'discountPrice',
  'productUnit',
  'productCategories',
  'productPromotion',
  'createdAt',
  'updatedAt',
];

export type ProductState = {
  loading: boolean;
  loaded: boolean;
  submitting: boolean;
  query: ProductQuery;
  total: number;
  items: ProductItem[];
  fetchProductList: (query?: Partial<ProductQuery>) => Promise<void>;
  createProduct: (input: CreateProductRequest) => Promise<void>;
  updateProduct: (id: number, input: UpdateProductRequest) => Promise<void>;
  deleteProduct: (id: number) => Promise<void>;
};
export const useProductStore = create<ProductState>((set, get) => ({
  loading: false,
  loaded: false,
  submitting: false,
  query: {
    search: '',
    sort: '',
    page: 1,
    limit: 10,
    fields: productListFields.join('|'),
  },
  total: 0,
  items: [],
  fetchProductList: async (_query) => {
    try {
      const query = { ...get().query, ..._query };
      set({ loading: true, query });
      const res = await productApi.fetchProductList(query);
      set({
        loading: false,
        loaded: true,
        total: res.metadata.total,
        items: res.records || [],
      });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
  createProduct: async (create) => {
    try {
      set({ submitting: true });
      await productApi.createProduct(create);
      set({ submitting: false });
    } finally {
      set({ submitting: false });
    }
  },
  updateProduct: async (id, update) => {
    try {
      set({ submitting: true });
      await productApi.updateProduct(id, update);
      set({ submitting: false });
    } finally {
      set({ submitting: false });
    }
  },
  deleteProduct: async (id) => {
    try {
      set({ loading: true });
      await productApi.deleteProduct(id);
      set({ loading: false });
      if (get().loaded) get().fetchProductList(get().query);
    } finally {
      set({ loading: false });
    }
  },
}));

export type ProductSelectionState = {
  loading: boolean;
  loaded: boolean;
  items: ProductSelectOption[];
  fetchAllProductList: (options?: { reload?: boolean }) => Promise<void>;
};
export const useProductSelectionStore = create<ProductSelectionState>((set, get) => ({
  loading: false,
  loaded: false,
  items: [],
  fetchAllProductList: async ({ reload = true } = {}) => {
    if (!reload && get().loaded) return;
    try {
      set({ loading: true });
      const res = await productApi.fetchProductList({
        page: 1,
        limit: 100000,
        fields: 'id|name',
      });
      set({ loading: false, loaded: true, items: res.records || [] });
    } catch (error) {
      set({ loading: false });
      return Promise.reject(error);
    }
  },
}));
