import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useAuthStore } from './stores/auth'

export const AppLoading: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const { loadUser } = useAuthStore()

  useEffect(() => {
    loadUser()
    setLoading(false)
  }, [loadUser, setLoading])

  if (loading) return null
  return <div>{children}</div>
}
