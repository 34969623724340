import {
  CreateProductIndicationRequest,
  ProductIndication,
  ProductIndicationQuery,
  FetchProductIndicationListResponse,
  UpdateProductIndicationRequest,
} from '@/types/productIndication';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/product-indication';
export const fetchProductIndicationList = async (
  params: ProductIndicationQuery
): Promise<FetchProductIndicationListResponse> => {
  const { data } = await baseApi.get<FetchProductIndicationListResponse>(ROOT_PATH, {
    params,
  });
  return data;
};

export const fetchProductIndication = async (id: number): Promise<ProductIndication> => {
  const { data } = await baseApi.get<ProductIndication>(`${ROOT_PATH}/${id}`);
  return data;
};

export const createProductIndication = async (input: CreateProductIndicationRequest): Promise<ProductIndication> => {
  const { data } = await baseApi.post(ROOT_PATH, serializeNullType(input));
  return data;
};

export const updateProductIndication = async (
  id: number,
  input: UpdateProductIndicationRequest
): Promise<ProductIndication> => {
  const { data } = await baseApi.put(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deleteProductIndication = async (id: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${id}`);
  return data;
};
