import { MenuItem } from '@/types/menu';
import {
  ClusterOutlined,
  FileDoneOutlined,
  FileOutlined,
  MedicineBoxOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  StrikethroughOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { paths } from './path';
import { CouponGroup } from '@/types/coupon';
import { UserRole } from '@/types/user';

export const menuItems: MenuItem[] = [
  {
    key: 'manager',
    label: 'Quản lí',
    icon: <ClusterOutlined />,
    role: UserRole.admin,
    children: [
      {
        key: 'user',
        label: 'Tài khoản',
        path: paths.USERS,
        icon: <TeamOutlined />,
      },
      {
        key: 'account',
        label: 'Người dùng',
        path: paths.ACCOUNT,
        icon: <UserOutlined />,
      },
    ],
  },
  {
    key: 'product_parent',
    label: 'Sản phẩm',
    icon: <MedicineBoxOutlined />,
    role: UserRole.admin,
    children: [
      {
        key: 'products',
        path: paths.PRODUCT,
        label: 'Sản phẩm',
      },
      {
        key: 'product-category',
        path: paths.PRODUCT_CATEGORY,
        label: 'Nhóm thuốc',
      },
      {
        key: 'product-composition',
        path: paths.PRODUCT_COMPOSITION,
        label: 'Thành phần',
      },
      {
        key: 'product-brand',
        path: paths.PRODUCT_BRAND,
        label: 'Thương hiệu',
      },
      {
        key: 'product-manufacturer',
        path: paths.PRODUCT_MANUFACTURER,
        label: 'Nhà sản xuất',
      },
      {
        key: 'product-using',
        path: paths.PRODUCT_USING,
        label: 'Đối tượng sử dụng',
      },
      {
        key: 'product-indication',
        path: paths.PRODUCT_INDICATION,
        label: 'Chỉ định thuốc',
      },
    ],
  },
  {
    key: 'order_parent',
    label: 'Đơn hàng',
    icon: <ShoppingCartOutlined />,
    children: [
      {
        key: 'orders',
        path: paths.ORDERS,
        label: 'Đơn hàng',
      },
    ],
  },
  {
    key: 'coupon_parent',
    label: 'Mã giảm',
    icon: <StrikethroughOutlined />,
    role: UserRole.admin,
    children: [
      {
        key: 'coupons_promotion',
        path: `${paths.COUPON_ALL}/${CouponGroup.promotion}`,
        label: 'Mã giảm',
      },
      {
        key: 'coupons_rank',
        path: `${paths.COUPON_ALL}/${CouponGroup.accountRank}`,
        label: 'Điểm tích luỹ',
      },
      {
        key: 'coupons_bounty',
        path: `${paths.COUPON_ALL}/${CouponGroup.bountyProgram}`,
        label: 'Chương trình trả thưởng',
      },
    ],
  },
  {
    key: 'bounty-programs-parant',
    label: 'Chương trình trả thưởng',
    icon: <StrikethroughOutlined />,
    role: UserRole.admin,
    children: [
      {
        key: 'bounty-programs',
        path: paths.BOUNTY_PROGRAM,
        label: 'Chương trình trả thưởng',
      },
    ],
  },
  {
    key: 'account-point-parent',
    label: 'Điểm thưởng',
    icon: <StrikethroughOutlined />,
    role: UserRole.admin,
    children: [
      {
        key: 'account-point',
        path: paths.ACCOUNT_POINT,
        label: 'Điểm thưởng',
      },
    ],
  },
  {
    key: 'pages-parent',
    label: 'Trang',
    icon: <FileOutlined />,
    role: UserRole.admin,
    children: [
      {
        key: 'pages',
        path: paths.PAGE,
        label: 'Trang',
      },
      {
        key: 'page-home',
        path: paths.PAGE_HOME,
        label: 'Trang chủ',
      },
    ],
  },
  {
    key: 'data',
    label: 'Form',
    icon: <FileDoneOutlined />,
    role: UserRole.admin,
    children: [
      {
        key: 'data-feedbacks',
        path: paths.DATA_FEEDBACK,
        label: 'Hòm thư góp ý',
      },
      {
        key: 'data-multi-orders',
        path: paths.DATA_MULTI_ORDER,
        label: 'Đặt hàng số lượng lớn',
      },
    ],
  },
  {
    key: 'theme-options',
    label: 'Cấu hình',
    icon: <SettingOutlined />,
    role: UserRole.admin,
    children: [
      {
        key: 'theme-options-general',
        path: paths.THEME_OPTIONS_GENERAL,
        label: 'Cấu hình chung',
      },
      {
        key: 'theme-options-sms',
        path: paths.THEME_OPTIONS_SMS,
        label: 'Cấu hình sms',
      },
      {
        key: 'theme-options-view',
        path: paths.THEME_OPTIONS_VIEW,
        label: 'Cấu hình hiển thị',
      },
      {
        key: 'theme-options-header',
        path: paths.THEME_OPTIONS_HEADER,
        label: 'Header',
      },
      {
        key: 'theme-options-footer',
        path: paths.THEME_OPTIONS_FOOTER,
        label: 'Footer',
      },
    ],
  },
];
