import { ProvinceSelect } from '@/components/Location/ProvinceSelectComponent';
import { useUserStore } from '@/stores/user';
import { UserDetail, CreateUserForm, UserRole, userRoleLabels } from '@/types/user';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import { FC, useEffect } from 'react';

export type ModalCreationProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  user?: UserDetail;
};
export const ModalCreation: FC<ModalCreationProps> = ({ user, open, setOpen }) => {
  const [form] = Form.useForm();
  const { createUser, updateUser } = useUserStore();

  const onFinish = async (value: CreateUserForm) => {
    try {
      if (!user) await createUser(value);
      else await updateUser(user.id, value);
      if (!user) message.success('Tạo mới Người dùng thành công');
      else message.success('Cập nhật Người dùng thành công');
      setOpen(false);
      form.resetFields();
    } catch (error: any) {
      if (error?.response?.data?.code === 10001) {
        form.setFields([{ name: 'username', errors: ['Tên đăng nhập đã tồn tại!'] }]);
      } else {
        if (!user) message.error('Tạo mới Người dùng bị lỗi');
        else message.error('Cập nhật Người dùng bị lỗi');
      }
    }
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    } else {
      form.resetFields();
    }
  }, [user]);

  return (
    <Modal
      title={!user ? 'Thêm mới Người dùng' : 'Cập nhật Người dùng'}
      open={open}
      cancelText="Hủy"
      okText={!user ? 'Tạo mới' : 'Cập nhật'}
      onCancel={onClose}
      onOk={form.submit}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <Form.Item label="Họ và tên" name="fullName" rules={[{ required: true, message: 'Họ và tên là bắt buộc' }]}>
          <Input placeholder="Nhập họ và tên" />
        </Form.Item>
        {!user && (
          <Form.Item
            label="Tên đăng nhập"
            name="username"
            rules={[{ required: true, message: 'Tên đăng nhập bắt buộc' }]}>
            <Input placeholder="Tên đăng nhập" />
          </Form.Item>
        )}
        {!user && (
          <Form.Item label="Mật khẩu" name="password" rules={[{ required: true, message: 'Mật khẩu bắt buộc' }]}>
            <Input.Password placeholder="Mật khẩu" />
          </Form.Item>
        )}
        <Form.Item label="Phân quyền" name="role" rules={[{ required: true, message: 'Phân quyền bắt buộc' }]}>
          <Select
            placeholder="Phân quyền"
            options={Object.values(UserRole).map((value) => ({ value, label: userRoleLabels[value] }))}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {(form) =>
            form.getFieldValue('role') === UserRole.employee && (
              <Form.Item label="Nơi quản lý" name="roleProvinceCodes">
                <ProvinceSelect mode="multiple" placeholder="Tất cả tỉnh/thành" allowClear />
              </Form.Item>
            )
          }
        </Form.Item>
        <div style={{ display: 'none' }}>
          <Button htmlType="submit" hidden></Button>
        </div>
      </Form>
    </Modal>
  );
};
