import { uploadFile } from '@/api/file';
import { ProductCategorySelect } from '@/components/ProductCategorySelect';
import { Upload } from '@/components/Upload';
import { useProductCategoryCreationModalStore, useProductCategoryStore } from '@/stores/productCategory';
import { ProductCategoryModalForm } from '@/types/productCategory';
import { handleNameToSlug } from '@/utils/helpers/nameToSlug';
import { Button, Col, Form, Input, Modal, Row, message } from 'antd';
import { FC, useEffect } from 'react';

export const ProductCategoryCreationModal: FC = () => {
  const [form] = Form.useForm();
  const { createProductCategory, updateProductCategory } = useProductCategoryStore();
  const { loading, initial, open, closeModal } = useProductCategoryCreationModalStore();

  useEffect(() => {
    if (initial) form.setFieldsValue(initial);
  }, [initial]);

  const onFinish = async (value: ProductCategoryModalForm) => {
    try {
      // Upload image
      let imageUrl: string | undefined = undefined;
      if (value.imageUrl && typeof value.imageUrl !== 'string' && value.imageUrl.originFileObj) {
        const fileRes = await uploadFile(value.imageUrl.originFileObj);
        imageUrl = fileRes.url;
        form.setFieldValue('imageUrl', imageUrl);
      }

      // Upload Record
      if (initial) {
        await updateProductCategory(initial.id, { ...value, imageUrl });
      } else {
        await createProductCategory({ ...value, imageUrl });
      }
      message.success(initial ? 'Cập nhật Nhóm thuốc thành công' : 'Tạo mới Nhóm thuốc thành công');
      closeModal();
      form.resetFields();
    } catch (error: any) {
      if (error?.response?.data?.name === 'name_is_exist') {
        form.setFields([
          {
            name: 'name',
            errors: ['Nhóm thuốc đã tồn tại!'],
          },
        ]);
      } else if (error?.response?.data?.name === 'slug_is_exist') {
        form.setFields([
          {
            name: 'slug',
            errors: ['Đường dẫn đã tồn tại!'],
          },
        ]);
      } else {
        message.error(initial ? 'Cập nhật Nhóm thuốc không thành công' : 'Tạo mới Nhóm thuốc không thành công');
      }
    }
  };

  const onClose = () => {
    closeModal();
    form.resetFields();
  };

  return (
    <Modal
      title={initial ? 'Cập nhật Nhóm thuốc' : 'Tạo mới Nhóm thuốc'}
      open={open}
      cancelText="Hủy"
      okText={initial ? 'Cập nhật' : 'Tạo mới'}
      onCancel={onClose}
      onOk={form.submit}
      width={800}
      maskClosable={false}
      okButtonProps={{ loading }}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <Row gutter={32}>
          <Col span={16}>
            <Form.Item
              label="Tên Nhóm thuốc"
              name="name"
              rules={[{ required: true, message: 'Tên Nhóm thuốc bắt buộc' }]}>
              <Input placeholder="Tên Nhóm thuốc" onChange={initial ? undefined : handleNameToSlug(form, 'slug')} />
            </Form.Item>
            <Form.Item label="Đường dẫn" name="slug" rules={[{ required: true, message: 'Đường dẫn bắt buộc' }]}>
              <Input placeholder="Đường dẫn" />
            </Form.Item>
            <Form.Item label="Nhóm cha" name="parentId">
              <ProductCategorySelect placeholder="Nhóm cha" ignoreValues={initial?.id ? [initial.id] : []} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Hình ảnh" name="imageUrl">
              <Upload />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ display: 'none' }}>
          <Button htmlType="submit" hidden></Button>
        </div>
      </Form>
    </Modal>
  );
};
