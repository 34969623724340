import { FetchOrderListRequest, FetchOrderListResponse, Order, OrderStatus, UpdateStatusRequest } from '@/types/order';
import { baseApi } from './common/baseApi';
import fileDownload from 'js-file-download';

const ROOT_PATH = '/order';

export const fetchOrder = async (orderId: number) => {
  const { data } = await baseApi.get<Order>(`${ROOT_PATH}/admin/${orderId}`);
  return data;
};

export const fetchOrderList = async (params: FetchOrderListRequest) => {
  const { data } = await baseApi.get<FetchOrderListResponse>(`${ROOT_PATH}/admin`, { params });
  return data;
};

export const updateOrderStatus = async (id: number, input: UpdateStatusRequest) => {
  const { data } = await baseApi.put<FetchOrderListResponse>(`${ROOT_PATH}/admin/${id}/status`, input);
  return data;
};

export const fetchNextStatuses = async (orderId: number) => {
  const { data } = await baseApi.get<OrderStatus[]>(`${ROOT_PATH}/admin/status/${orderId}`);
  return data;
};

export const exportOrders = async () => {
  const response = await baseApi.get(`${ROOT_PATH}/admin/export`, { responseType: 'blob' });
  fileDownload(response.data, 'orders.csv');
};

export const exportOrder = async (id: number) => {
  const response = await baseApi.get(`${ROOT_PATH}/admin/export/${id}`, { responseType: 'blob' });
  fileDownload(response.data, `order-${id}.pdf`);
};
