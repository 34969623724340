import { LayoutContent } from '@/components/Layout';
import { paths } from '@/config/path';
import { useBountyProgramStore } from '@/stores/bountyProgram';
import { BountyProgramCreationForm } from '@/types/bountyProgram';
import { Button, Col, DatePicker, Form, Input, Row, Spin, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as bountyProgramApi from '@/api/bountyProgram';
import dayjs from 'dayjs';
import { InputPrice } from '@/components/InputPrice/InputPrice';
import { CouponSelect } from '@/components/CouponSelect';
import { CouponGroup } from '@/types/coupon';

export type BountyProgramCreationPageProps = {};
export const BountyProgramCreationPage: FC<BountyProgramCreationPageProps> = () => {
  const { id: _id } = useParams<{ id: string }>();
  const id = parseInt(_id);
  const [loading, setLoading] = useState(true);
  const { submitting, updateBountyProgram, createBountyProgram } = useBountyProgramStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<BountyProgramCreationForm>();

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    bountyProgramApi
      .fetchBountyProgram(id)
      .then((data) => {
        form.setFieldsValue({
          ...data,
          startDate: data.startDate ? dayjs(data.startDate) : null,
          endDate: data.endDate ? dayjs(data.endDate) : null,
        });
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const onFinish = async (values: BountyProgramCreationForm) => {
    try {
      message.loading(id ? 'Đang cập nhật...' : 'Đang thêm mới...');
      if (id) {
        await updateBountyProgram(id, {
          ...values,
          startDate: values.startDate.toISOString(),
          endDate: values.endDate.toISOString(),
        });
      } else {
        await createBountyProgram({
          ...values,
          startDate: values.startDate.toISOString(),
          endDate: values.endDate.toISOString(),
        });
      }
      message.destroy();
      message.success(id ? 'Cập nhật chương trình thành công' : 'Tạo mới chương trình thành công');
      navigate(paths.BOUNTY_PROGRAM, { replace: true });
    } catch (error: any) {
      console.error(error);
      message.destroy();
      if (error?.response?.data?.name === 'code_is_exist') {
        form.setFields([{ name: 'code', errors: ['Code đã tồn tại!'] }]);
      } else {
        message.error(id ? 'Cập nhật chương trình không thành công' : 'Tạo mới chương trình không thành công');
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <LayoutContent
          actions={
            <Button type="primary" disabled={submitting} loading={submitting} htmlType="submit">
              {id ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          }>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Tiêu đề" name="title" rules={[{ required: true, message: 'Tiêu đề bắt buộc' }]}>
                <Input placeholder="Tiêu đề" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Ngày bắt đầu"
                name="startDate"
                rules={[{ required: true, message: 'Ngày bắt đầu bắt buộc' }]}
                initialValue={dayjs(new Date()).startOf('D')}>
                <DatePicker showTime format="DD/MM/YYYY HH:mm" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Ngày kết thúc"
                name="endDate"
                rules={[
                  { required: true, message: 'Ngày kết thúc bắt buộc' },
                  ({ getFieldValue }) => ({
                    validator(_, endDate) {
                      const startDate = getFieldValue('startDate');
                      if (!endDate || !startDate || dayjs(endDate).isAfter(dayjs(startDate))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Ngày kết thúc phải sau ngày bắt đầu!'));
                    },
                  }),
                ]}
                initialValue={dayjs(new Date()).startOf('D').add(1, 'month')}>
                <DatePicker showTime format="DD/MM/YYYY HH:mm" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tổng doanh số tối thiểu"
                name="minPrice"
                rules={[{ required: true, message: 'Tổng doanh số tối thiểu bắt buộc' }]}>
                <InputPrice placeholder="Tổng doanh số tối thiểu" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Mã giảm" name="couponId" rules={[{ required: true, message: 'Mã giảm là bắt buộc' }]}>
                <CouponSelect group={CouponGroup.bountyProgram} showDetail showEdit showAdd />
              </Form.Item>
            </Col>
          </Row>
        </LayoutContent>
        <div style={{ display: 'none' }}>
          <Button htmlType="submit" hidden></Button>
        </div>
      </Form>
    </Spin>
  );
};
