import { useProductCompositionSelectionStore } from '@/stores/productComposition';
import { toLowerNonAccentCaseSearch } from '@/utils/helpers/search';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { FC, useEffect, useMemo } from 'react';

export type ProductCompositionSelectProps = AntSelectProps & {
  ignoreValues?: number[];
};
export const ProductCompositionSelect: FC<ProductCompositionSelectProps> = (props) => {
  const {
    placeholder = 'Chọn thành phần thuốc',
    loading: loadingProps,
    allowClear = true,
    showSearch = true,
    ignoreValues = [],
    ...restProps
  } = props;
  const { loading, items, fetchAllProductCompositionList } = useProductCompositionSelectionStore();

  const options = useMemo(() => {
    return items
      .filter((item) => !ignoreValues.includes(item.id))
      .map((item) => ({ label: item.name, value: item.id }));
  }, [items, ...ignoreValues]);

  useEffect(() => {
    fetchAllProductCompositionList({ reload: false });
  }, [fetchAllProductCompositionList]);

  return (
    <AntSelect
      {...restProps}
      filterOption={(input, option) => toLowerNonAccentCaseSearch(option.label.toString(), input)}
      loading={loadingProps || loading}
      options={options}
      placeholder={placeholder}
      allowClear={allowClear}
      showSearch={showSearch}
    />
  );
};
