import {
  CreateProductUsingRequest,
  ProductUsing,
  ProductUsingQuery,
  FetchProductUsingListResponse,
  UpdateProductUsingRequest,
} from '@/types/productUsing';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/product-using';
export const fetchProductUsingList = async (params: ProductUsingQuery): Promise<FetchProductUsingListResponse> => {
  const { data } = await baseApi.get<FetchProductUsingListResponse>(ROOT_PATH, {
    params,
  });
  return data;
};

export const fetchProductUsing = async (id: number): Promise<ProductUsing> => {
  const { data } = await baseApi.get<ProductUsing>(`${ROOT_PATH}/${id}`);
  return data;
};

export const createProductUsing = async (input: CreateProductUsingRequest): Promise<ProductUsing> => {
  const { data } = await baseApi.post(ROOT_PATH, serializeNullType(input));
  return data;
};

export const updateProductUsing = async (id: number, input: UpdateProductUsingRequest): Promise<ProductUsing> => {
  const { data } = await baseApi.put(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deleteProductUsing = async (id: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${id}`);
  return data;
};
