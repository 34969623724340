import { Layout as AntLayout, Row } from 'antd'
import React from 'react'

import { LoginForm } from './LoginForm'

import styles from './styles.module.scss'

export const LoginPage: React.FC = () => (
  <AntLayout className={styles.loginLayout}>
    <Row className={styles.rightSection}>
      <LoginForm />
    </Row>
  </AntLayout>
)
