export const paths = {
  LOGIN: '/login',
  USERS: '/user',
  ACCOUNT: '/account',
  ACCOUNT_CREATE: '/account/new',
  ACCOUNT_DETAIL: '/account/view/:id',
  ACCOUNT_EDIT: '/account/edit/:id',
  ACCOUNT_POINT: '/account/points',
  PRODUCT: '/product',
  PRODUCT_CREATE: '/product/create',
  PRODUCT_UPDATE: '/product/:id',
  PRODUCT_INDICATION: '/product-indication',
  PRODUCT_USING: '/product-using',
  PRODUCT_CATEGORY: '/product-category',
  PRODUCT_BRAND: '/product-brand',
  PRODUCT_MANUFACTURER: '/product-manufacturer',
  PRODUCT_COMPOSITION: '/product-composition',
  COUPON_ALL: '/coupon',
  COUPON: '/coupon/:group',
  COUPON_DETAIL: '/coupon/:group/view/:id',
  COUPON_CREATE: '/coupon/:group/create',
  COUPON_UPDATE: '/coupon/:group/:id',
  BOUNTY_PROGRAM: '/bounty-program',
  BOUNTY_PROGRAM_CREATE: '/bounty-program/create',
  BOUNTY_PROGRAM_UPDATE: '/bounty-program/:id',
  ORDERS: '/order',
  ORDER_DETAIL: '/order/view/:id',
  PAGE: '/page',
  PAGE_HOME: '/page-home',
  PAGE_CREATE: '/page/create',
  PAGE_UPDATE: '/page/:id',
  THEME_OPTIONS_FOOTER: '/theme-options/footer',
  THEME_OPTIONS_HEADER: '/theme-options/header',
  THEME_OPTIONS_GENERAL: '/theme-options/general',
  THEME_OPTIONS_VIEW: '/theme-options/view',
  THEME_OPTIONS_SMS: '/theme-options/sms',
  DATA_FEEDBACK: '/data/data-feedbacks',
  DATA_FEEDBACK_DETAIL: '/data/data-feedbacks/:id',
  DATA_MULTI_ORDER: '/data/data-orders',
  DATA_MULTI_ORDER_DETAIL: '/data/data-orders/:id',
};
