import { FC } from 'react';
import { DatePickerProps as DatePickerCoreProps, DatePicker as DatePickerCore } from 'antd';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type DatePickerProps = DatePickerCoreProps & {
  readOnly?: boolean;
};

export const DatePicker: FC<DatePickerProps> = ({ readOnly, className, disabled, ...props }) => {
  return (
    <DatePickerCore
      {...props}
      className={classNames(className, { [styles.datePickerReadOnly]: readOnly })}
      disabled={disabled || readOnly}
    />
  );
};
