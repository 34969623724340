import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { OrderList } from './components/OrderList';
import { Filter } from './components/Filter';
import { Button, Modal } from 'antd';
import { exportOrders } from '@/api/order';

export type OrdersPageProps = {};
export const OrdersPage: FC<OrdersPageProps> = () => {
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <Button
            onClick={() =>
              Modal.confirm({
                content: 'Bạn có muốn xuất danh sách đơn hàng?',
                okText: 'Xuất',
                onOk: exportOrders,
              })
            }>
            Xuất
          </Button>
        ),
      }}>
      <OrderList />
    </LayoutContent>
  );
};
