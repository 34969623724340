import { useProductBrandCreationModalStore, useProductBrandStore } from '@/stores/productBrand';
import { ProductBrandModalForm } from '@/types/productBrand';
import { handleNameToSlug } from '@/utils/helpers/nameToSlug';
import { Button, Form, Input, Modal, message } from 'antd';
import { FC, useEffect } from 'react';

export const ProductBrandCreationModal: FC = () => {
  const [form] = Form.useForm();
  const { createProductBrand, updateProductBrand } = useProductBrandStore();
  const { loading, initial, open, closeModal } = useProductBrandCreationModalStore();

  useEffect(() => {
    if (initial) form.setFieldsValue(initial);
  }, [initial]);

  const onFinish = async (value: ProductBrandModalForm) => {
    try {
      // Upload Record
      if (initial) {
        await updateProductBrand(initial.id, value);
      } else {
        await createProductBrand(value);
      }
      message.success(initial ? 'Cập nhật Thương hiệu thành công' : 'Tạo mới Thương hiệu thành công');
      closeModal();
      form.resetFields();
    } catch (error: any) {
      if (error?.response?.data?.name === 'name_is_exist') {
        form.setFields([
          {
            name: 'name',
            errors: ['Thương hiệu đã tồn tại!'],
          },
        ]);
      } else if (error?.response?.data?.name === 'slug_is_exist') {
        form.setFields([
          {
            name: 'slug',
            errors: ['Đường dẫn đã tồn tại!'],
          },
        ]);
      } else {
        message.error(initial ? 'Cập nhật Thương hiệu không thành công' : 'Tạo mới Thương hiệu không thành công');
      }
    }
  };

  const onClose = () => {
    closeModal();
    form.resetFields();
  };

  return (
    <Modal
      title={initial ? 'Cập nhật Thương hiệu' : 'Tạo mới Thương hiệu'}
      open={open}
      cancelText="Hủy"
      okText={initial ? 'Cập nhật' : 'Tạo mới'}
      onCancel={onClose}
      onOk={form.submit}
      maskClosable={false}
      okButtonProps={{ loading }}>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <Form.Item
          label="Tên Thương hiệu"
          name="name"
          rules={[{ required: true, message: 'Tên Thương hiệu bắt buộc' }]}>
          <Input placeholder="Tên Thương hiệu" onChange={initial ? undefined : handleNameToSlug(form, 'slug')} />
        </Form.Item>
        <Form.Item label="Đường dẫn" name="slug" rules={[{ required: true, message: 'Đường dẫn bắt buộc' }]}>
          <Input placeholder="Đường dẫn" />
        </Form.Item>
        <Form.Item label="Xuất xứ" name="origin">
          <Input placeholder="Xuất xứ" />
        </Form.Item>
        <div style={{ display: 'none' }}>
          <Button htmlType="submit" hidden></Button>
        </div>
      </Form>
    </Modal>
  );
};
