import { validateFileSize } from '@/utils/helpers/validate';
import { UploadFile as AntUploadFile } from 'antd';
export function uploadPropsToState(
  values: AntUploadFile<any> | string | Array<AntUploadFile<any> | string>
): Array<AntUploadFile<any>> {
  if (typeof values === 'string') {
    const newValue: AntUploadFile<any>[] = [
      {
        uid: '0',
        name: values.split('/').pop(),
        thumbUrl: values,
        url: values,
        size: 0,
        percent: 0,
        status: 'success',
        type: '',
        lastModified: 0,
        lastModifiedDate: new Date(),
        originFileObj: undefined,
      },
    ];
    return newValue;
  }

  if (Array.isArray(values)) {
    return values.map((val: AntUploadFile<any> | string, index) => {
      if (typeof val === 'string') {
        const newValue: AntUploadFile<any> = {
          uid: index.toString(),
          name: val.split('/').pop(),
          thumbUrl: val,
          url: val,
          size: 0,
          percent: 0,
          status: 'success',
          type: '',
          lastModified: 0,
          lastModifiedDate: new Date(),
          originFileObj: undefined,
        };
        return newValue;
      }

      return val;
    });
  }

  return [values];
}

export function uploadValidateSize(fileList: AntUploadFile<any>[], maxSize = 100): AntUploadFile<any>[] {
  const newValues: AntUploadFile<any>[] = [];

  fileList.forEach((file) => {
    const newValue: AntUploadFile<any> = { ...file };
    if (file.originFileObj) {
      newValue.status = validateFileSize(file.originFileObj, maxSize) ? 'success' : 'error';
    }
    newValues.push(newValue);
  });

  return newValues;
}
