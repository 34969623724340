import { FormDataList } from '@/components/FormData/FormDataList';
import { Filter } from '@/components/FormData/Filter';
import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { FormDataName } from '@/types/formData';
import { paths } from '@/config/path';

export type DataFeedbacksPageProps = {};
export const DataFeedbacksPage: FC<DataFeedbacksPageProps> = () => {
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
      }}>
      <FormDataList
        searchFields={['fullName', 'phone']}
        formName={FormDataName.FEEDBACK}
        basePath={paths.DATA_FEEDBACK}
        columns={[
          {
            title: 'Họ và Tên',
            dataIndex: 'fullName',
            width: 160,
            render: (_, record) => record.formDataItems.find((item) => item.key === 'fullName')?.value,
          },
          {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            width: 130,
            render: (_, record) => record.formDataItems.find((item) => item.key === 'phone')?.value,
          },
          {
            title: 'Nội dung',
            dataIndex: 'content',
            render: (_, record) => record.formDataItems.find((item) => item.key === 'content')?.value,
          },
        ]}
      />
    </LayoutContent>
  );
};
