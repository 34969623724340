import { CreatePageRequest, Page, PageQuery, FetchPageListResponse, UpdatePageRequest } from '@/types/page';
import { baseApi } from './common/baseApi';
import { serializeNullType } from '@/utils/helpers/serializeNullType';

const ROOT_PATH = '/page';
export const fetchPageList = async (params: PageQuery): Promise<FetchPageListResponse> => {
  const { data } = await baseApi.get<FetchPageListResponse>(ROOT_PATH, {
    params,
  });
  return data;
};

export const fetchPage = async (id: number): Promise<Page> => {
  const { data } = await baseApi.get<Page>(`${ROOT_PATH}/${id}`);
  return data;
};

export const createPage = async (input: CreatePageRequest): Promise<Page> => {
  const { data } = await baseApi.post(ROOT_PATH, serializeNullType(input));
  return data;
};

export const updatePage = async (id: number, input: UpdatePageRequest): Promise<Page> => {
  const { data } = await baseApi.put(`${ROOT_PATH}/${id}`, serializeNullType(input));
  return data;
};

export const deletePage = async (id: number) => {
  const { data } = await baseApi.delete(`${ROOT_PATH}/${id}`);
  return data;
};
