import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { Filter } from './components/Filter';
import { ProductManufacturerList } from './components/ProductManufacturerList';
import { Button } from 'antd';
import { useProductManufacturerCreationModalStore } from '@/stores/productManufacturer';
import { ProductManufacturerCreationModal } from './components/ProductManufacturerCreationModal';

export const ProductManufacturersPage: FC = () => {
  const { openModal } = useProductManufacturerCreationModalStore();
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <Button type="primary" onClick={() => openModal()}>
            Thêm mới
          </Button>
        ),
      }}>
      <ProductManufacturerList />
      <ProductManufacturerCreationModal />
    </LayoutContent>
  );
};
