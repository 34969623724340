export enum ShippingMethod {
  standard = 'standard',
}

export const shippingMethodNameMapping: Record<ShippingMethod, string> = {
  [ShippingMethod.standard]: 'Giao hàng tiêu chuẩn',
};

export type OrderShippingGhn = {
  id: number;
  code: string;
  orderShippingId: number;
};

export enum ShippingPartner {
  ghn = 'ghn',
  etg = 'etg',
}

export const shippingPartnerNameMapping: Record<ShippingPartner, string> = {
  [ShippingPartner.etg]: 'Thuận Gia',
  [ShippingPartner.ghn]: 'Giao hàng nhanh',
};

export type OrderShipping = {
  id: number;
  fullName: string;
  phone: string;
  address: string;
  email?: string;
  provinceCode: string;
  provinceName: string;
  districtCode: string;
  districtName: string;
  wardCode: string;
  wardName: string;
  shippingMethod: ShippingMethod;
  partner?: ShippingPartner;
  orderShippingGhn?: OrderShippingGhn;
  expectedDeliveryTime?: Date;
};
