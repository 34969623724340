import { LayoutContent } from '@/components/Layout';
import { AppConfigKey } from '@/types/appConfig';
import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { FC } from 'react';
import { AppConfigType, useThemeOption } from './useThemeOption';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const appConfigTypes: AppConfigType[] = [
  {
    key: AppConfigKey.HEADER,
    type: 'json',
    default: '{}',
  },
];

export const ThemeOptionsHeaderPage: FC = () => {
  const [form] = Form.useForm();
  const { loading, submitting, onFinish: onFinishThemeOption } = useThemeOption({ form, types: appConfigTypes });

  const onFinish = async (values: any) => {
    onFinishThemeOption(values);
  };

  return (
    <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
      <Spin spinning={loading}>
        <LayoutContent
          footer={
            <div style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={() => form.submit()}
                disabled={loading || submitting}
                loading={submitting}>
                Lưu
              </Button>
            </div>
          }
          sections={[
            {
              key: 'top',
              title: 'Top Menu',
              content: (
                <Form.List name={[AppConfigKey.HEADER, 'topMenu']}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Row key={key} wrap={false} gutter={8}>
                          <Col flex={1}>
                            <Row gutter={24}>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  label={!index && 'Link'}
                                  name={[name, 'link']}
                                  rules={[{ required: true, message: 'Link là bắt buộc' }]}>
                                  <Input placeholder="Link" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  label={!index && 'Label'}
                                  name={[name, 'label']}
                                  rules={[{ required: true, message: 'Label là bắt buộc' }]}>
                                  <Input placeholder="Label" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Button type="link" danger shape="circle" onClick={() => remove(name)}>
                              <MinusCircleOutlined />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Thêm top menu
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ),
            },
          ]}
        />
      </Spin>
    </Form>
  );
};
