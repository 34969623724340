import { FILE_UPLOAD_TYPE } from '@/utils/helpers/validate';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload as AntUpload, UploadFile as AntUploadFile, UploadProps as AntUploadProps, Button, message } from 'antd';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import { FC, JSXElementConstructor, ReactElement, useCallback, useMemo } from 'react';
import styles from './styles.module.scss';
import { uploadPropsToState } from './Upload.helpers';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { uploadFile } from '@/api/file';

type UploadAutoProps = (
  | { value?: string; onChange?: (value: string) => void; multiple?: false }
  | { value?: string[]; onChange?: (value: string[]) => void; multiple: true }
) & {
  maxSize?: number; // kB
  disabled?: boolean;
} & Pick<AntUploadProps, 'accept' | 'itemRender' | 'listType'>;
export const UploadAuto: FC<UploadAutoProps> = ({
  maxSize,
  disabled,

  accept = FILE_UPLOAD_TYPE.join(','),
  listType = 'picture-card',
  ...props
}) => {
  const values = useMemo(() => {
    if (props.multiple) return props.value || [];
    if (props.value) return [props.value] as string[];
    return [];
  }, [props.multiple, props.value]);

  const onChange = useCallback(
    (values: string[]) => {
      if (props.multiple) props.onChange?.(values);
      else props.onChange?.(values[0] as any);
    },
    [props.multiple, props.onChange]
  );

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      onChange(arrayMove(values, active.id as number, over.id as number));
    }
  };

  return (
    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
      <SortableContext items={values.map((value, index) => index)}>
        <AntUpload
          disabled={disabled}
          listType={listType}
          accept={accept}
          multiple={props.multiple}
          beforeUpload={async (file) => {
            if (maxSize) {
              if (file.size > maxSize * 1024) {
                message.error(`Dung lượng không vượt quá ${Math.floor((maxSize * 100) / 1024) / 100}kb`);
                return Promise.reject();
              }
            }
            return file;
          }}
          className={clsx({ multiple: !!props.multiple, hasValue: !!values.length }, styles.upload)}
          fileList={uploadPropsToState(values)}
          onChange={(info) => {
            onChange(info.fileList.map((file) => file.url || file.response?.url || file));
          }}
          customRequest={(options) => {
            if (typeof options.file === 'string') options.onSuccess(options.file);
            else
              uploadFile(options.file, options.onProgress)
                .then((response) => {
                  options.onSuccess(response);
                })
                .catch((error) => {
                  options.onError(error);
                });
          }}
          itemRender={(originNode, file) => <DraggableUploadListItem originNode={originNode} file={file} />}>
          {listType === 'text' && (
            <Button disabled={disabled} icon={<UploadOutlined />}>
              Upload
            </Button>
          )}
          {listType !== 'text' && <PlusOutlined />}
        </AntUpload>
      </SortableContext>
    </DndContext>
  );
};

type DraggableUploadListItemProps = {
  originNode: ReactElement<any, string | JSXElementConstructor<any>>;
  file: AntUploadFile<any>;
};

const DraggableUploadListItem = ({ originNode, file }: DraggableUploadListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? 'is-dragging' : ''}
      {...attributes}
      {...listeners}>
      {/* hide error tooltip when dragging */}
      {originNode}
      {/* {file.status === 'error' && isDragging ? originNode.props.children : originNode} */}
    </div>
  );
};
