import { FC } from 'react';
import { SelectProps as SelectCoreProps, Select as SelectCore } from 'antd';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type SelectProps = SelectCoreProps & {
  readOnly?: boolean;
};

export const Select: FC<SelectProps> = ({ readOnly, className, disabled, ...props }) => {
  return (
    <SelectCore
      {...props}
      className={classNames(className, { [styles.selectReadOnly]: readOnly })}
      disabled={disabled || readOnly}
    />
  );
};
