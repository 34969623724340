import { useProductCategoryCreationModalStore, useProductCategoryStore } from '@/stores/productCategory';
import { ProductCategory, ProductCategoryQuery } from '@/types/productCategory';
import { serializeSorter } from '@/utils/helpers/serializeSorter';
import { blue } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useEffect } from 'react';
import format from 'date-fns/format';

export const ProductCategoryList = () => {
  const { loading, query, total, dataSource, fetchProductCategoryList, deleteProductCategory } =
    useProductCategoryStore();
  const { openModal } = useProductCategoryCreationModalStore();

  useEffect(() => {
    fetchProductCategoryList().catch(() => message.error('Lỗi không thể tải danh sách Nhóm thuốc', 3));
  }, []);

  const onDeleteProductCategory = async (manufacturerId: number) => {
    Modal.confirm({
      title: 'Xóa Nhóm thuốc',
      content: 'Bạn có muốn xóa Nhóm thuốc này không?',
      onOk: async () => {
        try {
          await deleteProductCategory(manufacturerId);
          message.success('Xóa Nhóm thuốc thành công');
        } catch (error: any) {
          message.error('Lỗi không thể xóa Nhóm thuốc này!');
        }
      },
    });
  };

  const columns: ColumnProps<ProductCategory>[] = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   width: 60,
    //   sorter: {
    //     multiple: 1,
    //   },
    // },
    {
      title: 'Nhóm thuốc',
      dataIndex: 'name',
      sorter: {
        multiple: 2,
      },
      render: (_, record) => (
        <>
          <div>{record.name}</div>
          <a
            href={`${process.env.REACT_APP_SITE_DOMAIN}/nhom-thuoc/${record.parent ? record.parent.slug + '/' : ''}${
              record.slug
            }`}
            target="_blank"
            rel="noreferrer">
            {record.slug}
          </a>
        </>
      ),
    },
    {
      title: 'Nhóm thuốc cha',
      dataIndex: 'parentId',
      render: (_, record) => record.parent?.name || '',
    },
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      width: 150,
      sorter: {
        multiple: 4,
      },
      render: (createdAt) => (createdAt ? format(new Date(createdAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      width: 150,
      sorter: {
        multiple: 5,
      },
      render: (updatedAt) => (updatedAt ? format(new Date(updatedAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Button type="link" shape="circle" title="Edit" onClick={() => openModal(record)}>
              <EditOutlined twoToneColor={blue.primary} />
            </Button>
            <Button
              danger
              type="link"
              shape="circle"
              title="Delete"
              onClick={() => onDeleteProductCategory(record.id)}>
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  const onChange = useCallback((pagination: any, _: any, sorter: any) => {
    const newParams: ProductCategoryQuery = {
      page: pagination.current || query.page,
      limit: pagination.pageSize || query.limit,
      sort: serializeSorter(sorter),
    };
    fetchProductCategoryList(newParams);
  }, []);

  return (
    <Table
      rowKey={(item) => item.id}
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      onChange={onChange}
      pagination={{
        current: query.page,
        pageSize: query.limit,
        total: total,
      }}
    />
  );
};
