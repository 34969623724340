import { useProductUsingCreationModalStore, useProductUsingStore } from '@/stores/productUsing';
import { ProductUsing, ProductUsingQuery } from '@/types/productUsing';
import { serializeSorter } from '@/utils/helpers/serializeSorter';
import { blue } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useEffect } from 'react';
import format from 'date-fns/format';

export const ProductUsingList = () => {
  const { loading, query, total, items, fetchProductUsingList, deleteProductUsing } = useProductUsingStore();
  const { openModal } = useProductUsingCreationModalStore();

  useEffect(() => {
    fetchProductUsingList().catch(() => message.error('Lỗi không thể tải danh sách đối tượng sử dụng', 3));
  }, []);

  const onDeleteProductUsing = async (manufacturerId: number) => {
    Modal.confirm({
      title: 'Xóa đối tượng sử dụng',
      content: 'Bạn có muốn xóa đối tượng sử dụng này không?',
      onOk: async () => {
        try {
          await deleteProductUsing(manufacturerId);
          message.success('Xóa đối tượng sử dụng thành công');
        } catch (error: any) {
          message.error('Lỗi không thể xóa đối tượng sử dụng này!');
        }
      },
    });
  };

  const columns: ColumnProps<ProductUsing>[] = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   width: 60,
    //   sorter: {
    //     multiple: 1,
    //   },
    // },
    {
      title: 'Tên',
      dataIndex: 'name',
      sorter: {
        multiple: 2,
      },
    },
    {
      title: 'Ngày khởi tạo',
      dataIndex: 'createdAt',
      width: 150,
      sorter: {
        multiple: 4,
      },
      render: (createdAt) => (createdAt ? format(new Date(createdAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      width: 150,
      sorter: {
        multiple: 5,
      },
      render: (updatedAt) => (updatedAt ? format(new Date(updatedAt), 'dd/MM/yyyy kk:mm') : ''),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Button type="link" shape="circle" title="Chỉnh sửa đối tượng sử dung" onClick={() => openModal(record)}>
              <EditOutlined twoToneColor={blue.primary} />
            </Button>
            <Button
              danger
              type="link"
              shape="circle"
              title="Xóa đối tượng sử dung"
              onClick={() => onDeleteProductUsing(record.id)}>
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  const onChange = useCallback((pagination: any, _: any, sorter: any) => {
    const newParams: ProductUsingQuery = {
      page: pagination.current || query.page,
      limit: pagination.pageSize || query.limit,
      sort: serializeSorter(sorter),
    };
    fetchProductUsingList(newParams);
  }, []);

  return (
    <Table
      rowKey={(item) => item.id}
      loading={loading}
      columns={columns}
      dataSource={items}
      onChange={onChange}
      pagination={{
        current: query.page,
        pageSize: query.limit,
        total: total,
      }}
    />
  );
};
