import { LayoutContent } from '@/components/Layout';
import { FC } from 'react';
import { Filter } from './components/Filter';
import { ProductIndicationList } from './components/ProductIndicationList';
import { Button } from 'antd';
import { useProductIndicationCreationModalStore } from '@/stores/productIndication';
import { ProductIndicationCreationModal } from './components/ProductIndicationCreationModal';

export const ProductIndicationsPage: FC = () => {
  const { openModal } = useProductIndicationCreationModalStore();
  return (
    <LayoutContent
      header={{
        leftElement: <Filter />,
        rightElement: (
          <Button type="primary" onClick={() => openModal()}>
            Thêm mới
          </Button>
        ),
      }}>
      <ProductIndicationList />
      <ProductIndicationCreationModal />
    </LayoutContent>
  );
};
