export enum FormDataName {
  FEEDBACK = 'feedback',
  MULTI_ORDER = 'multi_order',
}

export type FormDataItem = {
  key: string;
  value: string;
};

export type FormData = {
  id: number;
  formName: FormDataName;
  formDataItems: FormDataItem[];
  createdAt: Date;
  updatedAt: Date;
};

export type FormDataQuery = {
  search?: string;
  searchFields?: string[];
  formName?: FormDataName;
  page: number;
  limit: number;
};

export type FormDataResponse = {
  records: FormData[];
  metadata: {
    total: number;
  };
};

export type UpdateFormDataRequest = {
  formDataItems: FormDataItem[];
};
