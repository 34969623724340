import { LayoutContent } from '@/components/Layout';
import { AppConfigKey } from '@/types/appConfig';
import { Button, Col, Form, Row, Spin, Switch } from 'antd';
import { FC } from 'react';
import { AppConfigType, useThemeOption } from './useThemeOption';

const appConfigTypes: AppConfigType[] = [
  {
    key: AppConfigKey.VISIBLE_ACCOUNT_POINT,
    type: 'boolean',
    default: false,
  },
  {
    key: AppConfigKey.VISIBLE_REFER_ACCOUNT,
    type: 'boolean',
    default: false,
  },
  {
    key: AppConfigKey.VISIBLE_COUPON,
    type: 'boolean',
    default: false,
  },
  {
    key: AppConfigKey.VISIBLE_BOUNTY_PROGRAM,
    type: 'boolean',
    default: false,
  },
];

export const ThemeOptionsViewPage: FC = () => {
  const [form] = Form.useForm();
  const { loading, submitting, onFinish: onFinishThemeOption } = useThemeOption({ form, types: appConfigTypes });

  const onFinish = async (values: any) => {
    onFinishThemeOption(values);
  };

  return (
    <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
      <Spin spinning={loading}>
        <LayoutContent
          footer={
            <div style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={() => form.submit()}
                disabled={loading || submitting}
                loading={submitting}>
                Lưu
              </Button>
            </div>
          }
          sections={[
            {
              key: 'view',
              title: 'Hiển thị',
              content: (
                <Row>
                  <Col span={6}>
                    <Form.Item
                      label="Điểm tích lũy"
                      required
                      name={AppConfigKey.VISIBLE_ACCOUNT_POINT}
                      valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Giới thiệu bạn bè"
                      required
                      name={AppConfigKey.VISIBLE_REFER_ACCOUNT}
                      valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Mã giảm" required name={AppConfigKey.VISIBLE_COUPON} valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Chương trình trả thưởng"
                      required
                      name={AppConfigKey.VISIBLE_BOUNTY_PROGRAM}
                      valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </Spin>
    </Form>
  );
};
