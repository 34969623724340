import { DistrictSelect } from '@/components/Location/DistrictSelectComponent';
import { ProvinceSelect } from '@/components/Location/ProvinceSelectComponent';
import { WardSelect } from '@/components/Location/WardSelectComponent';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { FC } from 'react';

const MAIN_KEY = 'accountShippings';

type ShippingFormProps = {
  type: 'view' | 'new' | 'edit';
};
export const ShippingForm: FC<ShippingFormProps> = ({ type }) => {
  return (
    <Form.List name={MAIN_KEY}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Row key={key} gutter={24} wrap={false} style={{ marginBottom: 20 }}>
              <Col flex={1}>
                <Card>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'fullName']}
                        label="Tên người nhận"
                        rules={[{ required: true, message: 'Tên người nhận là bắt buộc' }]}>
                        <Input placeholder="Tên người nhận" readOnly={type === 'view'} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'phone']}
                        label="Số điện thoại"
                        rules={[{ required: true, message: 'Số điện thoại là bắt buộc' }]}>
                        <Input placeholder="Số điện thoại" readOnly={type === 'view'} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'address']}
                        label="Địa chỉ giao hàng"
                        rules={[{ required: true, message: 'Địa chỉ giao hàng là bắt buộc' }]}>
                        <Input placeholder="Địa chỉ giao hàng" readOnly={type === 'view'} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item noStyle dependencies={[[name, 'provinceCode']]}>
                        {(form) => {
                          return (
                            <Form.Item
                              {...restField}
                              label="Tỉnh / Thành Phố"
                              name={[name, 'provinceCode']}
                              rules={[{ required: true, message: 'Tỉnh / Thành Phố là bắt buộc' }]}>
                              <ProvinceSelect
                                placeholder="Tỉnh / Thành Phố"
                                allowClear
                                readOnly={type === 'view'}
                                onChange={() =>
                                  form.setFieldsValue({
                                    [MAIN_KEY]: { [name]: { districtCode: undefined, wardCode: undefined } },
                                  })
                                }
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        noStyle
                        dependencies={[
                          [MAIN_KEY, name, 'provinceCode'],
                          [MAIN_KEY, name, 'districtCode'],
                        ]}>
                        {(form) => {
                          const provinceCode = form.getFieldValue([MAIN_KEY, name, 'provinceCode']);
                          return (
                            <Form.Item
                              {...restField}
                              label="Quận / Huyện"
                              name={[name, 'districtCode']}
                              rules={[{ required: true, message: 'Quận / Huyện là bắt buộc' }]}>
                              <DistrictSelect
                                placeholder="Quận / Huyện"
                                provinceCode={provinceCode}
                                disabled={!provinceCode}
                                allowClear
                                readOnly={type === 'view'}
                                onChange={() => form.setFieldsValue({ [name]: { wardCode: undefined } })}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item noStyle dependencies={[[MAIN_KEY, name, 'districtCode']]}>
                        {(form) => {
                          const districtCode = form.getFieldValue([MAIN_KEY, name, 'districtCode']);
                          return (
                            <Form.Item
                              {...restField}
                              label="Phường / Xã"
                              name={[name, 'wardCode']}
                              rules={[{ required: true, message: 'Phường / Xã là bắt buộc' }]}>
                              <WardSelect
                                placeholder="Phường / Xã"
                                districtCode={districtCode}
                                disabled={!districtCode}
                                readOnly={type === 'view'}
                                allowClear
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {type !== 'view' && (
                <Col>
                  <Button type="link" danger shape="circle" onClick={() => remove(name)}>
                    <MinusCircleOutlined />
                  </Button>
                </Col>
              )}
            </Row>
          ))}
          {type !== 'view' && (
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Thêm mới
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );
};
