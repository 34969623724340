import { FileDetail } from '@/types/file';
import { baseApi } from './common/baseApi';
import { AxiosProgressEvent } from 'axios';

export const uploadFile = async (
  file: File | Blob,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<FileDetail> => {
  const form = new FormData();
  form.append('file', file);
  const { data } = await baseApi.post<FileDetail>('/file/upload', form, {
    onUploadProgress,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};
