import { routeItems } from '@/config/route';
import { useAuthStore } from '@/stores/auth';
import { useMemo } from 'react';

export const useRouteItems = () => {
  const { userDetail } = useAuthStore();
  return useMemo(
    () =>
      routeItems.filter((item) => {
        const isAuth =
          (item.authentication?.allowAuth && !!userDetail) || (item.authentication?.allowUnauth && !userDetail);
        const isRole = !item.authentication.role || item.authentication.role === userDetail?.role;
        return isAuth && isRole;
      }),
    [userDetail]
  );
};
