import { ProductCategorySelect } from '@/components/ProductCategorySelect';
import { useProductStore } from '@/stores/product';
import { Button, Col, Form, Input, Row } from 'antd';
import { FC } from 'react';

export const Filter: FC = () => {
  const { query, fetchProductList } = useProductStore();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    fetchProductList({ search: values.search, productCategoryId: values.productCategoryId, page: 1 });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Row gutter={[16, 0]}>
        <Col span={6}>
          <Form.Item name="productCategoryId" initialValue={query.productCategoryId}>
            <ProductCategorySelect placeholder="Chọn nhóm thuốc..." />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="search" initialValue={query.search}>
            <Input placeholder="Tìm kiếm thuốc..." />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Button htmlType="submit">Tìm kiếm</Button>
        </Col>
      </Row>
    </Form>
  );
};
