import { Col, Form, Input, Row } from 'antd';
import { FC } from 'react';

const MAIN_KEY = 'bankAccount';

type BankFormProps = {
  type: 'view' | 'new' | 'edit';
};
export const BankForm: FC<BankFormProps> = ({ type }) => {
  return (
    <Row gutter={24}>
      <Col span={8}>
        <Form.Item label="Chủ tài khoản (không dấu)" name={[MAIN_KEY, 'fullName']}>
          <Input placeholder="Chủ tài khoản" readOnly={type === 'view'} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Số tài khoản" name={[MAIN_KEY, 'bankNumber']}>
          <Input placeholder="Số tài khoản" readOnly={type === 'view'} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Ngân hàng" name={[MAIN_KEY, 'bankBrand']}>
          <Input placeholder="Ngân hàng" readOnly={type === 'view'} />
        </Form.Item>
      </Col>
    </Row>
  );
};
