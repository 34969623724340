import { InputNumber, InputNumberProps } from 'antd';
import { FC } from 'react';

export type InputPriceProps = InputNumberProps;
export const InputPrice: FC<InputPriceProps> = (props) => {
  return (
    <InputNumber
      formatter={(value) => `${value || ''}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
      parser={(value) => value?.replace(/(\.*)/g, '')}
      {...props}
    />
  );
};
