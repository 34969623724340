import { baseApi } from './common/baseApi';
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  CreateUserRequest,
  CreateUserResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  UserDetail,
} from '@/types/user';

export const fetchAllUsers = async (): Promise<UserDetail[]> => {
  const { data } = await baseApi.get<UserDetail[]>('/admin/user');
  return data;
};

export const createUser = async (input: CreateUserRequest): Promise<CreateUserResponse> => {
  const { data: user } = await baseApi.post<CreateUserResponse>('/admin/user', input);
  return user;
};

export const updateUser = async (id: number, input: UpdateUserRequest): Promise<UpdateUserResponse> => {
  const { data: user } = await baseApi.put<CreateUserResponse>(`/admin/user/${id}`, input);
  return user;
};

export const deleteUser = async (userId: number): Promise<void> => {
  await baseApi.delete<CreateUserResponse>(`/admin/user/${userId}`);
};

export const changePassword = async (userId: number, input: ChangePasswordRequest): Promise<ChangePasswordResponse> => {
  const { data } = await baseApi.put<ChangePasswordResponse>(`/admin/user/${userId}/password`, {
    password: input.password,
  });
  return data;
};
