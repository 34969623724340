import { useUserStore } from '@/stores/user';
import { Button, Form, Input, Modal, message } from 'antd';
import { FC } from 'react';

export type ModalChangePasswordProps = {
  userId?: number;
  onCancel?: () => void;
};
export const ModalChangePassword: FC<ModalChangePasswordProps> = ({ userId, onCancel }) => {
  const [form] = Form.useForm();
  const { loading, changePassword } = useUserStore();

  const onFinish = async (values: any) => {
    if (!userId) return;
    const password: string = values.password;
    try {
      await changePassword(userId, password);
      onCancel?.();
      message.success('Thay đổi mật khẩu thành công');
    } catch (error) {
      message.error('Không thể thay đổi mật khẩu');
    }
  };

  return (
    <Modal
      title="Thay đổi mật khẩu"
      open={!!userId}
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" onClick={form.submit} loading={loading}>
            Change
          </Button>
        </>
      }>
      <Form form={form} labelCol={{ span: 24 }} onFinish={onFinish}>
        <Form.Item
          name="password"
          label="Mật khẩu mới"
          rules={[
            { required: true, message: 'Mật khẩu mới bắt buộc' },
            { min: 6, message: 'Mật khẩu quá ngắn' },
          ]}>
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          label="Mật khẩu xác thực"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Mật khẩu xác thực bắt buộc' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Mật khẩu xác thực không trùng khớp với Mật khẩu mới!'));
              },
            }),
          ]}>
          <Input.Password />
        </Form.Item>
        <div style={{ display: 'none' }}>
          <Button htmlType="submit" hidden></Button>
        </div>
      </Form>
    </Modal>
  );
};
