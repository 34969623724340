import { FormData } from '@/components/FormData/FormData';
import { ProvinceSelect } from '@/components/Location/ProvinceSelectComponent';
import { AccountType, accountTypenameMapping } from '@/types/account';
import { Col, Form, Input, Row, Select } from 'antd';
import { FC } from 'react';

export const DataMultiOrderPage: FC = () => {
  return (
    <FormData>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="fullName" label="Họ và Tên">
            <Input placeholder="Họ và Tên" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="phone" label="Số điện thoại">
            <Input placeholder="Số điện thoại" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="product" label="Sản phẩm quan tâm">
            <Input placeholder="Sản phẩm quan tâm" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Loại nhà thuốc" name="accountType" initialValue={AccountType.Pharmacy}>
            <Select
              placeholder="Loại nhà thuốc"
              options={Object.values(AccountType).map((accountType) => ({
                value: accountType,
                label: accountTypenameMapping[accountType],
              }))}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Tỉnh / Thành Phố" name="provinceCode">
            <ProvinceSelect placeholder="Tỉnh / Thành Phố" allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="address" label="Địa chỉ">
            <Input placeholder="Địa chỉ" />
          </Form.Item>
        </Col>
      </Row>
    </FormData>
  );
};
