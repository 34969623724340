import { SortOrder } from 'antd/es/table/interface';

type Sorter = {
  order: SortOrder;
  field: string | number;
};
export const serializeSorter = (sorter: Sorter | Sorter[]) => {
  if (Array.isArray(sorter)) {
    return sorter
      .map(({ field, order }: any) => {
        const sortOrder = order === 'ascend' ? 'asc' : 'desc';
        return `${field}:${sortOrder}`;
      })
      .join('|');
  }

  if (sorter?.field && sorter?.order) {
    const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
    return `${sorter.field}:${sortOrder}`;
  }

  return '';
};
